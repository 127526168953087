<template>
    <Carousel
        class="slider"
        :per-page="1"
        :mouse-drag="false"
        paginationActiveColor="#ffffff"
        paginationColor="rgba(255, 255, 255, 0.3)"
        paginationPosition="bottom-overlay"
        :paginationPadding="5"
        :paginationSize="10"
        :centerMode="false"
        :minSwipeDistance="10"
        :mouseDrag="true"
        :touchDrag="true"
        ref="slider"
    >
        <Slide
            v-for="(slide, index) in carouselList"
            :key="index"
            class="slider__item"
            data-index="0"
            data-name="MySlideName 1"
        >
            <div class="slider__wrapper">
                <div class="slider__image">
                    <img
                        :src="slide.src"
                        alt="Картинка для страницы логина"
                    >
                    <span></span>
                </div>
                <div class="slider__title">{{ slide.title }}</div>
                <div class="slider__description">{{ slide.description }}</div>
            </div>
        </Slide>
    </Carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: "BaseCarousel",
    components: {
        Carousel,
        Slide,
    },
    props: {
        carouselList: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {
        this.changeDefaultStylePagination();
    },
    methods: {
        changeDefaultStylePagination() {
            const dotContainer = this.$refs.slider.$children[3].$el.firstChild;
            dotContainer.classList.add('my-custom-active');
        },
    },
}
</script>

<style lang="scss">

$color-primary: #fff;

    .slider {
        margin: 0;
        padding: 0;
        margin-bottom: 45px;
        box-sizing: border-box;
        list-style: none;

        @media (max-width: 576px) {
            width: 100%;
        }

        &__item {
            height: inherit;
            justify-content: center;
            align-items: center;

            &--active {
                display: flex;
            }
        }

        &__wrapper {
            padding: 30px;
            box-sizing: border-box;

            @media (max-width: 576px) {
                padding: 30px;
            }
        }

        &__image {
            max-height: 200px;
            margin: 0 auto;
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            img {
                max-width: 100%;
                height: auto;
                z-index: 1;
                object-fit: scale-down;
            }

            span {
                position: absolute;
                bottom: 0;
                z-index: 0;
                display: block;
                width: 80%;
                box-shadow: 0 -2px 10px 2px #bf002a;
            }

        }

        &__title {
            text-align: center;
            color: $color-primary;
            font-size: 24px;
            margin-bottom: 25px;
        }

        &__description {
            width: 60%;
            text-align: center;
            color: $color-primary;
            font-size: 14px;
            margin: 0 auto;
            margin-bottom: 55px;
        }
    }

.my-custom-active {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .VueCarousel-dot.VueCarousel-dot--active {
        height: 16px !important;
        width: 16px !important;
    }
}

</style>
