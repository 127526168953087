<template>
	<div class="menu">
		<div
			v-for="item of items"
			:key="item.id"
			class="menu-item"
			:class="getItemClasses(item.id)"
			@click="onClick(item.id)"
		>
			<p class="menu-item__title">{{ item.title }}</p>
			<span class="menu-item__counter"> {{item.postscript}} </span>
		</div>
	</div>
</template>

<script>
export default {
	name: "NavigationPanel",
	props: {
		items: {
			type: Array,
			default: () => []
		},
		selected: {
			type: [String, Array],
			default: ''
		}
	},
	methods: {
		onClick(id) {
			this.$emit('choose', id)
		},
		getItemClasses(id) {
			return {
				'menu-item__active': id === this.selected
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.menu {
	position: relative;
	display: inline-flex;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		
		border-bottom: 1px solid #E7E7EB;
	}

	&-item {
		position: relative;
		padding-bottom: 20px;
		z-index: 1;
		cursor: pointer;
		color: #BEC0C7;
		transition: .2s;

		&:hover {
			color: #000;
		}

		&__active {
			border-bottom: 2px solid #000;
			color: #000;
		}

		&:not(:first-child) {
			margin-left: 30px;
		}

		&__counter {
			position: absolute;
			top: -10px;
			right: 0px;
			transform: translateX(80%);
			font-size: 10px;
		}
	}
}
</style>