<template>
    <!--suppress HtmlUnknownAttribute -->
    <button
        v-if="!isModule"
        class="control-panel__item-btn"
        :class="{
            'control-panel__item-btn--active': isActive && !isModule,
        }"
        v-tooltip="panelItemDescription"
        @click="onClickPanelItem"
    >
        <span class="control-panel__wrapper">
            <span :class="`${panelItemIcon}`"></span>
        </span>
    </button>

    <!--suppress HtmlUnknownAttribute -->
    <button
        v-else
        class="control-panel__item-btn"
        :class="{
            'control-panel__item-btn--active-module': isActive && isModule,
            'control-panel__item-btn--active-checkbox': isModule && isActiveCheckbox,
        }"
        v-tooltip.right="panelItemDescription"
        @click="onClickPanelItem"
    >
        <span class="control-panel__wrapper">
            <span :class="`${panelItemIcon}`"></span>
        </span>
    </button>
</template>

<script>
export default {
    name: 'ControlPanelItem',
    /**
     * Входные данные компонента
     * @property {Object} panelItem - элемент панели
     * @property {Boolean} isActive - активность элемента панели
     * @property {Boolean} isModule - компонент для списка модулей
     */
    props: {
        panelItem: {
            type: Object,
            default: () => ({}),
            validator: (panelItem) => {
                return Object.keys(panelItem).length > 0;
            }
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isModule: {
            type: Boolean,
            default: false,
        },
        isActiveCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        /**
         * Идентификатор элемента панели
         * @returns {String}
         */
        panelItemId() {
            let {id = ''} = this.panelItem;
            return id;
        },
        /**
         * Класс для иконки элемента панели
         * @returns {String}
         */
        panelItemIcon() {
            let {icon = ''} = this.panelItem;
            return icon;
        },
        /**
         * Описание элемента панели
         * @returns {String}
         */
        panelItemDescription() {
            let {description = ''} = this.panelItem;
            return description;
        }
    },
    methods: {
        /**
         * Событие клика по элементу панели
         */
        onClickPanelItem() {
            this.$emit('onClickPanelItem', this.panelItemId);
        }
    }
}
</script>

<style lang="scss" scoped>
    $primary-color: #F0F3F8 !default;

    .control-panel__item-btn {
        width: 34px;
        height: 34px;
        background: #F5F5F5;
        box-shadow: none;
        border: 1px solid #fff;
        display: inline-block;
        font-weight: bold;
        position: relative;
        outline: 0;
        padding: 0;
        margin: 1px;
        cursor: pointer;
        line-height: 34px;
        text-decoration: none;
        vertical-align: middle;
        white-space: nowrap;
        color: #222;
        text-align: center;
        transition: all .25s ease-out;
        font-family: SegoeUISemiBold sans-serif;
        box-sizing: border-box;

        &--active {
            .control-panel__wrapper {
                background: #00c396;
                color: #fff;
            }
        }

        &--active-module {
            border: 1px solid #00c396;
            background: #fff;
        }

        &--active-checkbox {
            &:before {
                display: block;
                content: '';
                position: absolute;
                top: -5px;
                right: -6px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #0b76ef;
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                top: -4px;
                right: -3px;
                width: 4px;
                height: 6px;
                border: solid #fff;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
            }
        }

        &:not(&--active):hover {
            .control-panel__wrapper {
                background: $primary-color;
            }
        }
    }

    .control-panel__wrapper {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        font-size: 14px;
    }

</style>
