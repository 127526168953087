<template>
	<div class="footer">
		<div class="footer__left">
			Обновлено {{ lastUpdate.day }} {{ months[lastUpdate.month] }} {{ lastUpdate.year }}
		</div>
		<div class="footer__right">
			<div
				v-for="(item, index) of colorBoxData"
				:key="index"
				class="color-box"
			>
				<div
					class="color-box__color"
					:class="item.class"
				/>
				<div class="color-box__title">{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import {months} from "../../Scheme"

export default {
	name: "Footer",
	props: {
		lastUpdate: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			months: months,
			colorBoxData: [
				{ class: 'free', title: "Свободно" },
				{ class: 'reserved', title: "Резерв" },
				{ class: 'booked', title: "Бронь" },
			]
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.footer {
	color: $assistant-color;
	font-size: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__right {
		display: flex;
		align-items: center;

		.color-box:not(:last-child) {
			margin-right: 16px;
		}
	}

	.color-box {
		display: flex;
		align-items: center;

		&__color {
			height: 9px;
			width: 9px;
			border-radius: 50%;

			&.free {
				border: 1px solid $success-color;
			}
			&.reserved {
				border: 1px solid $reserved-color;
			}
			&.booked {
				border: 1px solid $danger-color;
			}
		}
		&__title {
			margin-left: 6px;
		}
	}
}
</style>