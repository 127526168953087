<template>
	<div class="draggble-window">
		<div class="draggble-window__top-resizer" @mousedown="onResize($event, 'top')" />
		<div class="draggble-window__right-resizer" @mousedown="onResize($event, 'right')" />
        <div class="draggble-window__right-resizer_se" @mousedown="onResizeSe($event)"/>
		<div class="draggble-window__bottom-resizer" @mousedown="onResize($event, 'bottom')" />
        <div class="draggble-window__bottom-resizer_se" @mousedown="onResizeSe($event)"/>
		<div class="draggble-window__left-resizer" @mousedown="onResize($event, 'left')" />

		<div class="draggble-window-panel" @mousedown="onMove($event, $el)">
			<!-- <div class="draggble-window-panel__drag" /> -->
            <div :style="{'background-color': color}" class="draggble-window-panel__item_color"/>
			<div class="draggble-window-panel__title-area">
				<base-text-input 
					v-if="title && renameMode"
					ref="BaseTextInputWindow"
					:value="title"
					@input="onUpdateName"
					@onBlur="onBlurInputWindow"
					class="draggble-window-panel__input"
				/>
				<p
					v-if="title && !renameMode"
					v-text="title"
					class="draggble-window-panel__title"
					@click="onClickNameWindow"
				/>
			</div>

			<div class="custom-panel">
				<slot name="panel" />
			</div>

			<div class="draggble-window-panel-controls" v-if="hasPanel">
				<div v-if="isMinimize" class="draggble-window-panel-controls__el" @click="$emit('onMinimized')">
					<BaseIcon icon="icon-minus" color="#000" />
				</div>
				<div
					class="draggble-window-panel-controls__el close"
					@click="$emit('onClose')"
				>
					<BaseIcon icon="icon-close" size="md" class="close__icon" />
				</div>
			</div>
		</div>

		<div class="draggble-window-summary">
			<slot name="summary" />
		</div>

        <!-- <slot name="panel"></slot> -->

		<div class="draggble-window__content">
			<slot name="content" class="dd"></slot>
		</div>
	</div>
</template>

<script>
import BaseIcon from "./Base/BaseIcon"
import BaseTextInput from '@/components/Base/BaseTextInput';
import MixinRename from '@/mixins/MixinRename';
export default {
	name: "DraggbleWindow",
    mixins: [MixinRename],
	props: {
		hasPanel: {
			type: Boolean,
			default: false
		},
		title: {
			type: [Number, String],
			default: ''
		},
        isMinimize: {
            type: Boolean,
            default: true
        },
        isRename: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        }
	},
	components: {
		BaseIcon,
        BaseTextInput
	},
	data() {
		return {
			startPoint: 0,
            finishPoint: 0,

			startPointX: 0,
			startPointY: 0,
			finishPointX: 0,
			finishPointY: 0,

            resizeSeStartX: 0,
            resizeSeStartY: 0,
            resizeSeFinishX: 0,
            resizeSeFinishY: 0
		}
	},
	methods: {
        /**
         * Событие клика по названию окна
         */
        onClickNameWindow() {
            if (this.isRename) {
                this.refInput = 'BaseTextInputWindow';
                this.onClickName();
            }
        },
        /**
         * Событие снятия фокуса с окна
         */
        onBlurInputWindow() {
            this.onBlurInput();
            this.onRenameWindow();
        },
        /**
         * Событие переименования окна
         */
        onRenameWindow() {
            if (this.newName !== '' && this.newName !== this.title)
                this.$emit('onRenameWindow', this.newName);
        },
		onMove(event, target) {
            document.body.style.userSelect = 'none'
			this.startPointX = event.clientX
			this.startPointY = event.clientY

            const move = (event) => {
				this.finishPointX = event.clientX
				const differenceX = this.finishPointX - this.startPointX
				const left  = parseInt(getComputedStyle(target).left)
				target.style.left = left + differenceX + 'px'
				this.startPointX = this.finishPointX

				this.finishPointY = event.clientY
				const differenceY = this.finishPointY - this.startPointY
				const top  = parseInt(getComputedStyle(target).top)
				target.style.top = top + differenceY + 'px'
				this.startPointY = this.finishPointY
            }
			
            // Удаляю слушатели
            document.addEventListener('mousemove', move)
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', move)
                document.body.style.userSelect = 'initial'
            }, {once: true})
        },
        onResizeSe(event) {
            this.resizeSeStartX = event.clientX;
            this.resizeSeStartY = event.clientY;
            const target = this.$el;
            document.body.style.userSelect = 'none'
            const onResizeSeEnd = (event) => {
                this.resizeSeFinishX = event.clientX;
                this.resizeSeFinishY = event.clientY;
                const differenceX = this.resizeSeFinishX - this.resizeSeStartX;
                const differenceY = this.resizeSeFinishY - this.resizeSeStartY;
                const sumX = differenceX + target.offsetWidth;
                const sumY = differenceY + target.offsetHeight;
                this.resizeSeStartX = this.resizeSeFinishX;
                this.resizeSeStartY = this.resizeSeFinishY;
                target.style.width = sumX + 'px';
                target.style.height = sumY + 'px';
            } 
            document.addEventListener('mousemove', onResizeSeEnd);
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeSeEnd)
                document.body.style.userSelect = 'initial';
				this.resizeSeStartX = 0;
				this.resizeSeStartY = 0;
                this.resizeSeFinishX = 0;
                this.resizeSeFinishY = 0;
            }, {once: true});
        },
		onResize(event, direction) {
			const target = this.$el
            document.body.style.userSelect = 'none'
            if (direction == 'right' || direction == 'left') {
				this.startPoint = event.clientX
			} else if (direction == 'top' || direction == 'bottom') {
				this.startPoint = event.clientY
			}

            const onResizeCeilEnd = (event) => {
				if (direction === 'right') {
					this.finishPoint = event.clientX
					const difference = this.finishPoint - this.startPoint
					const sum = difference + target.offsetWidth
					this.startPoint = this.finishPoint
					target.style.width = sum+'px'
				}
				if (direction === 'bottom') {
					this.finishPoint = event.clientY
					const difference = this.finishPoint - this.startPoint
					const sum = difference + target.offsetHeight
					this.startPoint = this.finishPoint
					target.style.height = sum + 'px'
				}
				if (direction === 'top') {
					this.finishPoint = event.clientY
					const difference = this.finishPoint - this.startPoint
					const sum = target.offsetHeight - difference
					const top  = parseInt(getComputedStyle(target).top)
					target.style.top = top + difference + 'px'
					this.startPoint = this.finishPoint
					target.style.height = sum+'px'
				}
				if (direction === 'left') {
					this.finishPoint = event.clientX
					const difference = this.finishPoint - this.startPoint
					const sum = target.offsetWidth - difference
					const left  = parseInt(getComputedStyle(target).left)
					target.style.left = left + difference + 'px'
					this.startPoint = this.finishPoint
					target.style.width = sum+'px'
				}
            }
			
            // Удаляю слушатели
            document.addEventListener('mousemove', onResizeCeilEnd)
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeCeilEnd)
                document.body.style.userSelect = 'initial'
				this.finishPoint = 0
				this.startPoint = 0
            }, {once: true})
        }
	}
}
</script>

<style lang="scss" scoped>
$resizerSize: 5px;
%resizer {
	background: transparent !important;
	position: absolute;
	z-index: 2;
}

.draggble-window {
	overflow: hidden;
	position: absolute;
	background: #fff;
	min-width: 140px;
    min-height: 140px;
	display: flex;
	flex-direction: column;

	&__content {
		flex-grow: 1;
		overflow: hidden;
        background: #fff;
        height: 100%;
	}

	&-panel {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #F9FAFB;
		flex-shrink: 0;
		padding: 15px 0 0 15px;
		cursor: grab;
        z-index: 1000;
        &__item_color {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            // margin-left: 10px;
        }

        &__input {
            width: 30%;
            margin-left: 5px;
        }

		&__title-area {
			padding: 0 10px;
			display: flex;
			align-items: center;
			height: 100%;
			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}

		&__title {
			font-size: 12px;
			font-weight: 700;
		}

		.custom-panel {
			flex-grow: 1;
			margin: 0 15px;
		}

		&-controls {
			display: flex;
			z-index: 1;

			&__el {
				width: 24px;
				height: 24px;
				text-align: center;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.close {
				margin-right: 12px;
				color: #B9BBC2;
				font-size: 12px;
			}
		}
	}

	&-summary {
		background: #F9FAFB;
		// padding: 0px 15px;
		padding: 15px;
        z-index: 1000;
	}

	&__right-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
        height: 100%;
		right: 0px;
		top: 0px;
	}
    &__right-resizer_se {
		@extend %resizer;
		cursor: se-resize;
        width: 5px;
        height: 40px;
        right: 0px;
        bottom: 0px;
	}
	&__top-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		right: 0px;
		top: 0px;
	}
	&__bottom-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		// right: 0px;
        left: 0px;
		bottom: 0px;
	}
    &__bottom-resizer_se {
		@extend %resizer;
		cursor: se-resize;
		width: 40px;
		height: $resizerSize;
        right: 0px;
		bottom: 0px;
	}
	&__left-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
		height: 100%;
		left: 0px;
		top: 0px;
	}
}
</style>