import toRuble from "@/filters/toRuble";
export default {
    /**
     * @property {Array} summaryData - сводные данные
     */
    data: () => ({
        summaryData: [],
    }),
    methods: {
        AdapterSummaryData(summaryData) {
            const totalValue = summaryData?.total?.price
            const sideCount = summaryData?.total?.side_count
            const totalOfferValue = summaryData?.total?.offer_price
            let sides = summaryData?.sides || []
            sides = sides.map(item => ({title: item.name, value: `${item.side_count} шт.`}))

            if (sides.length > 0) {
                return [
                    { title: "Сторон", value: `${sideCount} шт.` },
                    { title: "Прайс", value: toRuble(totalValue) },
                    { title: "Цена агентства", value: toRuble(totalOfferValue)},
                    ...sides
                ]
            } else {
                return []
            }

        }
    }
};
