<template>
    <div
        :style="kitPanelStyle"
        class="kit-panel"
    >
        <div class="kit-panel__left">
            <kit-panel-item
                v-if="kitFoundActive"
                :kit="kitFound"
                :kitSelected="true"
                :kitTableActive="getTableActiveKit(kitFound)"
                :kitMarkersActive="getMarkersActiveKit(kitFound)"
                :kitIsRename="false"
                :kitIsButtonContainer="kitIsButtonContainer"
                @onSelectKit="onSelectKit"
                @onRenameKit="onRenameKit"
                @onToggleKit="onToggleKit"
            />
            <RouterLink
                to="/buyerdesk"
                class="item"
            >
                <BaseButton
                    class="kit-panel__btn"
                    form="oval"
                    v-if="!kitFoundActive"
                >
                    <BaseIcon name="arrow-right" class="kit-panel__btn-icon" />
                    <span class="kit-panel__btn-title">К планированию</span>
                </BaseButton>
            </RouterLink>
        </div>
        <div class="kit-panel__right">
            <div
                v-if="kitCanAdd"
                class="kit-panel__add icon-plus"
                @click="onCreateKit"
            ></div>
            <kit-panel-item
                v-for="(kit, index) in kitsCustomVisible"
                :key="index"
                :kit="kit"
                :kitSelected="getKitSelected(kit)"
                :kitTableActive="getTableActiveKit(kit)"
                :kitMarkersActive="getMarkersActiveKit(kit)"
                :kitIsRename="true"
                :hasPanel="kitIsButtonContainer"
                class="kit-panel__kits-custom"
                @onSelectKit="onSelectKit"
                @onRenameKit="onRenameKit"
                @onToggleKit="onToggleKit"
            />
            <base-dropdown v-if="kitsCustomUnvisible.length !== 0" class="kit-panel__more">
                <template #preview>
                    <div class="more-btn">Ещё наборы</div>
                </template>
                <template #content>
                    <kit-panel-item
                        v-for="(kit, index) in kitsCustomUnvisible"
                        :key="index"
                        :kit="kit"
                        :kitSelected="getKitSelected(kit)"
                        :kitTableActive="getTableActiveKit(kit)"
                        :kitMarkersActive="getMarkersActiveKit(kit)"
                        :kitIsRename="true"
                        class="kit-panel__kits-custom_unvisible"
                        @onSelectKit="onSelectKit"
                        @onRenameKit="onRenameKit"
                        @onToggleKit="onToggleKit"
                    />
                </template>
            </base-dropdown>
        </div>
    </div>
</template>

<script>
import KitPanelItem from './KitPanelItem';
import BaseDropdown from '@/components/Base/BaseDropdown'
import BaseButton from "@/components/Base/BaseButton";
import BaseIcon from "@/components/Base/BaseSvgIcon";
export default {
    name: 'KitPanel',
    components: {
        KitPanelItem,
        BaseDropdown,
        BaseButton,
        BaseIcon
    },
    /**
     * Входные данные компонента
     * @property {Array} kits - элементы панели набора
     * @property {Array} kitCustomIdSelected - идентификатор пользовательского выделенного набора
     * @property {Array} kitsTableActive - элементы набора с активированной таблицей
     * @property {Array} kitsMarkersActive - элементы набора с активированными маркерами
     * @property {Array} kitFoundActive - активен ли таб найденного
     */
    props: {
        kits: {
            type: Array,
            default: () => ([])
        },
        kitCustomIdSelected: {
            type: String,
            default: '-1'
        },
        kitsTableActive: {
            type: Array,
            default: () => ([])
        },
        kitsMarkersActive: {
            type: Array,
            default: () => ([])
        },
        kitFoundActive: {
            type: Boolean,
            default: true
        },
        kitIsButtonContainer: {
            type: Boolean,
            default: true
        },
        kitCanAdd: {
            type: Boolean,
            default: true
        },
        isMarginLeft: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Данные компонента
     * @property {Number} widthWindow - ширина окна
     * @property {Number} widthFound - ширина для блока найдено
     * @property {Number} widthCustomItem - ширина для элемента пользовательского набора
     */
    data: () => ({
        widthWindow: 340,
        widthFound: 340,
        widthCustomItem: 165
    }),
    computed: {
        kitPanelStyle() {
            const kitPanelStyle = this.isMarginLeft ? {'padding-left': '20px'} : {};
            return kitPanelStyle;
        },
        /**
         * Ширина для блока пользовательских наборов
         * @returns {Number}
         */
        widthCustom() {
            const currentWidth = this.widthWindow - this.widthFound - 88;
            return currentWidth >= 0 ? currentWidth : 0;
        },
        /**
         * Элемент набора найденное
         * @returns {Object}
         */
        kitFound() {
            const kitFound = this.kits.find(kit => {
                const {type = ''} = kit;
                return type === 'found' ? true : false;
            });
            return typeof kitFound !== 'undefined' ? kitFound : {};
        },
        /**
         * Элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustom() {
            const kitsCustom = this.kits.filter(kit => {
                const {type = ''} = kit;
                return type === 'custom' ? true : false;
            });
            const kitsCustomSorted = kitsCustom.sort((kitA, kitB) => {
                const {timestamp: timestampA = -1} = kitA;
                const {timestamp: timestampB = -1} = kitB;
                return parseInt(timestampB) - parseInt(timestampA);
            });
            return kitsCustomSorted;
        },
        /**
         * Колличество видимых элементов пользовательского набора
         * @returns {Number}
         */
        kitsCustomVisibleLength() {
            return Math.floor(this.widthCustom / this.widthCustomItem);
        },
        /**
         * Видимые элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustomVisible() {
            return this.kitsCustom.slice(0, this.kitsCustomVisibleLength);
        },
        /**
         * Скрытые элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustomUnvisible() {
            return this.kitsCustom.slice(this.kitsCustomVisibleLength, this.kitsCustom.length);
        }
    },
    created() {
        window.addEventListener('resize', this.updateWidthWindow);
        this.updateWidthWindow();
    },
    destroyed() {
        window.removeEventListener('resize', this.updateWidthWindow);
    },
    methods: {
        updateWidthWindow() {
            this.widthWindow = window.innerWidth;
        },
        /**
         * Событие создания элемента набора
         */
        onCreateKit() {
            const itemIndexNext = this.kitsCustom.length;
            this.$emit('onCreateKit', itemIndexNext);
        },
        /**
         * Событие выделения набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onSelectKit(id = '-1', type = '') {
            this.$emit('onSelectKit', id, type);
        },
        /**
         * Событие переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое название набора
         */
        onRenameKit(id = '-1', newName = '') {
            this.$emit('onRenameKit', id, newName);
        },
        /**
         * Событие переключения активности показа таблицы или маркеров набора
         * @param {String} typeEmit - тип эмита
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onToggleKit(typeEmit = '', id = '-1', type = '') {
            this.$emit(`onToggle${typeEmit}Kit`, id, type);
        },
        /**
         * Получить выделенность элемента набора
         * @property {Object} kit
         */
        getKitSelected(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitCustomIdSelected === String(id);
        },
        /**
         * Получить активность показа таблицы элемента набора
         * @property {Object} kit - элемент набора
         */
        getTableActiveKit(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitsTableActive.includes(String(id));
        },
        /**
         * Получить активность показа маркеров элемента набора
         * @property {Object} kit - элемент набора
         */
        getMarkersActiveKit(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitsMarkersActive.includes(String(id));
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.kit-panel {
    background: #FAFAFA;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;
    &__btn {
        display: flex;
        align-items: center;

        &-icon {
            width: 15px;
            flex-shrink: 0;
            transform: scale(-1, 1);
        }

        &-title {
            margin-left: 8px;
            white-space: nowrap;
        }
    }
    &__more {
        margin-left: 10px;
        cursor: pointer;
    }
    .more-btn {
        background: $primary-color;
        color: #B9BBC2;
        cursor: pointer;
        padding: 1px 7px;
        border-radius: 100px;
        white-space: nowrap;
    }
    &__right {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
    &__add {
        background: $active-color;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        transition: .2s;

        &:hover {
            background: $active-darker-color;
        }

        &-icon {
            width: 60%;
        }
    }
    &__kits-custom {
        margin-left: 10px;
    }
    &__kits-custom_unvisible {
        margin: 5px;
    }
}
</style>
