<template>
	<div class="chips">
		<div
			v-for="(chipsItem, index) of chipsToShow"
			:key="index"
			class="chips-item"
		>
			{{ chipsItem.value }}
			<span
				class="chips-item__close"
				@click="onRemoveChips(chipsItem)"
			>
				<BaseIcon icon="icon-close" class="chips-item__close-icon" />
			</span>
		</div>

		<span
			v-if="hiddenChipsCounter > 0"
			class="chips-btn"
			@click="onMore"
		>Еще {{ hiddenChipsCounter }}</span>
	</div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
	name: "Chips",
	components: {
        BaseIcon
	},
	props: {
        chips: {
            type: Array,
            default: () => []
        }
	},
    data() {
        return {
            numberDisplayedСhips: 2
        }
    },
	computed: {
        /**
         * Массив чипсов которые будут выведенны в filterPanel
         */
        chipsToShow() {
            return this.chips.slice(0, this.numberDisplayedСhips)
        },
        /**
         * @returns {Number} Количество чипсов которые не показанны в панели фильтров
         */
        hiddenChipsCounter() {
            return this.chips.length - this.numberDisplayedСhips
        }
	},
	methods: {
		onRemoveChips(chipsItem) {
			this.$emit('remove', chipsItem)
		},
		onMore() {
			this.$emit('more')
		},
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;
$primary-color: #F0F3F8 !default;

.chips {
	$marginLeft: 5px;
	%child {
		white-space: nowrap;
		display: inline-block;
		font-size: 12px;
		padding: 5px 10px;
		border-radius: 1000px;
		cursor: pointer;
	}

	display: flex;

	&-item {
		@extend %child;
		background: $active-color;
		color: #fff;
		position: relative;

		&:not(:first-child) {
			margin-left: $marginLeft;
		}

		&:hover .chips-item__close {
			z-index: 0;
			opacity: 1;
		}

		&__close {
			width: 25px;
			height: 25px;
			background: #fff;
			border-radius: 50%;
			position: absolute;
			z-index: -1;
			opacity: 0;
			right: 0px;
			top: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: .2s;

			&-icon {
				font-size: 12px;
				color: $active-color !important;
			}
		}
	}

	&-btn {
		@extend %child;
		margin-left: $marginLeft;
		background: $primary-color;
		color: #B9BBC2;
		cursor: pointer;
	}
}
</style>