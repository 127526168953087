<template>
	<div id="app">
		<component :is="layout">
			<RouterView />
		</component>
	</div>
</template>

<script>
import DefaultLayout from "./layouts/Default"
import WithFilterLayout from "./layouts/WithFilter"
import WithFilterMiniLayout from "./layouts/WithFilterMini"
import WithoutFilterLayout from "./layouts/WithoutFilter"
import LayoutLoginV2 from "./layouts/LayoutLoginV2"

export default {
	name: 'App',
	components: {
		DefaultLayout,
		WithFilterLayout,
		WithoutFilterLayout,
    WithFilterMiniLayout,
    LayoutLoginV2,
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || 'DefaultLayout')
		}
	},
	created() {
        document.title = this.$route.meta.title || 'oohdesk'
    }
}
</script>

<style lang="scss" scoped>
#app {

}
</style>
