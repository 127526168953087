<template>
	<BaseDropdown
		class="datepicker-wrapper"
		position="left"
		:padding="0"
		@changeVisible="onChangeVisibleDatepicker"
	>   
		<template #preview>
			<BaseButton
				:active="isDatepickerOpen"
				class="button"
			>
				<BaseSvgIcon
					name="calendar"
					class="button__icon"
				/>
				<span class="button__title">
					{{dateTitle}}
				</span>
			</BaseButton>
		</template>

		<template #content>
			<DatePicker
				:isMultiple="isMultiple"
				:accessDates="accessDates"
				:checkedDates="checkedDates"
				@cancel="$emit('$closeDropdown')"
				@approve="onApproveDatepicker"
			/>
		</template>
	</BaseDropdown>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"
import BaseDropdown from "@/components/Base/BaseDropdown"
import DatePicker from "@/components/Base/DatePicker"
import BaseSvgIcon from "@/components/Base/BaseSvgIcon"

export default {
	name: "DatePickerDropdown",
	components: {
		BaseButton,
		BaseDropdown,
		DatePicker,
		BaseSvgIcon
	},
	props: {
        accessDates: {
            type: Array,
            default: () => []
        },
		checkedDates: {
            type: Array,
            default: () => []
        },
        isMultiple: {
            type: Boolean,
            default: true
        }
	},
    data() {
        return {
            months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
            monthsFull: new Map(Object.entries({
                'Янв': 'Январь', 
                'Фев': 'Февраль', 
                'Мар': 'Март', 
                'Апр': 'Апррель', 
                'Май': 'Май', 
                'Июн': 'Июнь', 
				'Июл': 'Июль', 
                'Авг': 'Август', 
                'Сен': 'Сентябрь', 
                'Окт': 'Октябрь', 
                'Ноя': 'Ноябрь', 
                'Дек': 'Декабрь',
            })),
            isDatepickerOpen: false,
        }
    },
	computed: {
		dateTitle() {
            if (this.checkedDates.length === 0) {
                return 'Выберите дату'
            } else{
                let uniqueMonths = []

                this.checkedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')

                return this.checkedDates.length === 1 ? this.monthsFull.get(String(result)): `${ result }`;
            }
        },
	},
	methods: {
        onChangeVisibleDatepicker(boolean) {
            this.isDatepickerOpen = boolean
        },
		onApproveDatepicker(event) {
            this.$emit('$closeDropdown')
			console.log(event);

            this.$emit('onApprove', event)
        }
	}
}
</script>

<style lang="scss" scoped>
.datepicker-wrapper {
	.button {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 180px;
        border-radius: 100px;
        padding: 12px 15px;
        text-align: left;

        &__icon {
            flex-shrink: 0;
        }

        &__title {
            display: block;
            margin-left: 10px;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
</style>