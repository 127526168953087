<template>
    <div class="layout">
        <AppSidebar
            class="layout__sidebar"
            :isHide="isHideSidebar"
            :sidebarItems="sidebarItems"
            @onHide="onHideSidebar"
        />
        <div class="layout__main">
            <AppCap
                class="layout__cap"
                :isHumburgerActive="isMenuOpen"
                :title="$route.meta.title || 'Oohdesk'"
                :hasLogo="false"
                view="transparent"
                @onHumburger="onHumburger"
                @onFilter="onFilter"
            >
                <FilterPanelMini
                    :dates="dates"
                    :cities="cities"
                    :clients="clients"
                    :selectedCities="selectedCities"
                    :selectedClients="selectedClients"
                    :selectedDates="selectedDates"
                    :isDate="filterSettings[$route.name].isDate"
                    :isCity="filterSettings[$route.name].isCity"
                    :isClient="filterSettings[$route.name].isClient"
                    @toggle="onFilter"
                    @clear="onClear"
                    @approveFilterParam="onApprove"
                />
            </AppCap>
            <div class="layout__content">
                <AppMenu
                    v-show="isMenuOpen"
                    class="layout__menu"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppCap from './components/Cap'
import AppMenu from './components/Menu'
import AppSidebar from './components/Sidebar'
import ServiceApi from '../services/ServiceApi';
import FilterPanelMini from './components/FilterPanelMini'

export default {
    name: 'DefaultLayout',
    components: {
        AppCap,
        AppSidebar,
        AppMenu,
        FilterPanelMini
    },
    data() {
        return {
            /**
             * @property {Boolean} isHideSidebar - флаг скрытия боковой панели
             * @property {Boolean} isMenuOpen - флаг открытия меню
             * @property {Array} sidebarItems - элементы боковой панели
             * @property {Object} filterDataSelected - выбранные параметры фильтра
             * @property {Array} selectedDates - массив выбранных дат
             * @property {Array} selectedClients - массив выбранных клиентов
             * @property {Array} selectedCities - массив выбранных городов
             * @property {Array} dates - доступные даты
             * @property {Array} clients - доступные клиенты
             * @property {Array} cities - доступные города
             */
            isHideSidebar: true,
            isMenuOpen: false,
            sidebarItems: [
                {
                    label: 'Загрузка сверок',
                    icon: 'icon-document-2',
                    path: '/reconciliation-start'
                },
                {
                    label: 'Сверка факт',
                    icon: 'icon-document-2',
                    path: '/reconciliation-edit'
                },
                {
                    label: 'Сводные данные',
                    icon: 'icon-document-2',
                    path: '/reconciliation-budget'
                },
            ],
            filterDataSelected: {},
            selectedDates: [],
            isFilterOpen: false,
            dates: [],
            clients: [],
            cities: [],
            selectedClients: [],
            selectedCities: [],
            filterSettings: {
                reconciliationBudget: {
                    isDate: true,
                    isCity: false,
                    isClient: false
                },
                reconciliationEdit:{
                    isDate: true,
                    isCity: false,
                    isClient: false
                }
            }
        }
    },
    computed: {

    },
    watch: {
        filterDataSelected: function (filterDataSelected) {
            this.setFilterSelectedData(filterDataSelected);
        }
    },
    async created() {
        await this.fetchFilterData();
    },
    methods: {
        ...mapMutations('filter', ['setFilterSelectedData', 'setIsFilterLoading']),
        /**
         * Обновление фильтра
         */
        async fetchFilterData(body = {}) {
            try {
                this.isFilterLoading = true
                this.setIsFilterLoading(true);
                let response = await ServiceApi.post('reconciliation','/filter', {filter_params: body})
                response = response.data.data
                let {selected, filters} = response;
                this.filterDataSelected = selected;
                this.prepareFilters(filters);
                this.prepareSelected(selected);
            } catch(err) {
                console.log(err);
            }
            finally {
                // this.isFilterLoading = false
                this.setIsFilterLoading(false);
            }
        },
        /**
         * Очистка фильтра
         */
        onClear(){
            this.fetchFilterData({reset : 'Y'});
        },
        onHumburger() {
            this.isMenuOpen = !this.isMenuOpen
            this.isFilterOpen = false
        },
        onHideSidebar(event) {
            this.isHideSidebar = event
        },
        /**
         * Выбор даты
         * @param {String} key - ключ
         * @param {Array|String} value
         */
        onApprove(key, value) {
            let newSelected = this.filterDataSelected;
            newSelected[key] = value;
            this.fetchFilterData(newSelected);
        },
        onFilter() {
            this.isFilterOpen = !this.isFilterOpen
            this.isMenuOpen = false
        },
        /**
         * Адаптер для фильтров. Пашка прости что не перенес в mixins
         * @property {Array} filters - массив с данными для фильтра
         */
        prepareFilters(filters){
            this.clients = [];
            filters.user_id.map(item => {
                this.clients.push({
                    id: item.id,
                    value: item.name
                });
            });
            this.cities = [];
            filters.city_id.map(item => {
                this.cities.push({
                    id: item.id,
                    value: item.name
                });
            });
            this.dates = [];
            filters.date.map(item => {
                var date = new Date(item);
                var curYear = date.getFullYear();
                var curMonth = date.getMonth() + 1;
                this.dates.push({year: curYear, month: curMonth});
            });
        },
        /**
         * Адаптер для фильтра выбранных. Пашка прости что не перенес в mixins
         * @property {Array} selected - массив с выбранными данными для фильтра
         */
        prepareSelected(selected){
            var date = new Date(selected.date);
            var curYear = date.getFullYear();
            var curMonth = date.getMonth() + 1;
            this.selectedDates = [{year: curYear, month: curMonth}];
            this.selectedCities = typeof selected.city_id === 'undefined' ? [] : selected.city_id;
            this.selectedClients = selected.user_id;
        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__cap {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        // z-index: 200;
    }

    &__content {
        position: relative;
        // z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: hidden;
        padding: 0px 40px 40px 40px;
    }

    &__menu {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 9999;
    }

    &__filter {
        padding: 40px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 9999;
        border-top: 1px solid #eee;
    }
}
</style>
