<template>
    <div class="layout">
        <AppSidebar
            class="layout__sidebar"
            :isHide="isHideSidebar"
            :sidebarItems="sidebarItems"
            @onHide="onHideSidebar"
        />
        <div class="layout__main">
            <AppCap
                class="layout__cap"
                :isHumburgerActive="isMenuOpen"
                :title="$route.meta.title || 'Oohdesk'"
                view="transparent"
                @onHumburger="onHumburger"
            />
            <div class="layout__content">
                <AppMenu
                    v-show="isMenuOpen"
                    class="layout__menu"
                />

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import AppCap from './components/Cap'
import AppSidebar from './components/Sidebar'
import AppMenu from './components/Menu'

export default {
    name: 'DefaultLayout',
    components: {
        AppCap,
        AppSidebar,
        AppMenu,
    },
    data() {
        return {
            isHideSidebar: true,
            isMenuOpen: false,
            sidebarItems: [
                {
                    label: 'Загрузка сверок',
                    icon: 'icon-document-2',
                    path: '/reconciliation-start'
                },
                {
                    label: 'Сверка факт',
                    icon: 'icon-document-2',
                    path: '/reconciliation-edit'
                },
                {
                    label: 'Сводные данные',
                    icon: 'icon-document-2',
                    path: '/reconciliation-budget'
                },
            ]
        }
    },
    methods: {
        onHideSidebar(event) {
            this.isHideSidebar = event
        },
        onHumburger() {
            this.isMenuOpen = !this.isMenuOpen
        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    &__cap {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__main {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__sidebar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: hidden;
        padding: 20px 40px 20px 40px;
    }

    &__menu {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 2000;
    }
}
</style>
