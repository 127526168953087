<template>
    <second-panel
        title="POI"
        v-show="isShowSecondPanel"
        :data-group="dataGroup"
        :data-poi="dataPoi"
        :checked-parent-list="checkedParentList"
        :checked-child-list="checkedChildList"
        :groups-for-add="groupsForAdd"
        @onCheckedGroup="checkedGroup"
        @onCheckedPoi="checkedPoi"
        @onToggleShowPoiList="toggleShowPoiList"
        @selectedAllCheckbox="selectedAllCheckbox"
        @unselectedAllCheckbox="unselectedAllCheckbox"
        @addNewGroupPoi="addNewGroupPoi"
        @newGroupPoi="newGroupPoi"
        @removePoi="removePoi"
        @removeGroupPoi="removeGroupPoi"
    >
        <template v-if="isLoading" v-slot:preloader>
            <base-preloader :size="40"/>
        </template>
    </second-panel>
</template>

<script>
import {mapState} from "vuex";
import SecondPanel from "../SecondPanel/SecondPanel.vue";
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';
import BasePreloader from "@/components/Base/BasePreloader";

export default {
    name: "LayoutModule",
    components: {
        SecondPanel,
        BasePreloader,
    },
    mixins: [
        MixinApi,
    ],
    props: {
        isShowSecondPanel: {
            type: Boolean,
            default: false,
        },
    },
    /**
     * Входные данные компонента
     * @property {Array} dataGroup - Список групп с бекенда
     * @property {Array} dataPoi - Список точек poi с бекенда
     * @property {Array} checkedParentList - Список включенных групп
     * @property {Array} checkedChildList - Список включенных точек poi
     * @property {Boolean} isLoading - Показать/скрыть прелоадер
     */
    data: () => ({
        dataGroup: [],
        groupsForAdd: [],
        dataPoi: [],
        checkedParentList: [],
        checkedChildList: [],
        isLoading: false,
    }),
    watch: {
        /**
         * Следить за изменениями фильтра
         */
        async filterSelectedData() {
            this.unselectedAllCheckbox();
            this.dataPoi = [];
            this.dataGroup = await this.fetchGroup();
        },
        /**
         * Следить за количеством выбранных групп
         */
        checkedParentList() {
          if (this.checkedParentList.length > 0) {
              this.$emit('setActiveLabel', true);
          } else {
              this.$emit('setActiveLabel', false);
          }
        },
    },
    computed: {
        /**
         * Получить фильтр из стора
         */
        ...mapState('filter', [
            'filterSelectedData',
        ]),
        /**
         * Получить длинну списка групп
         */
        dataGroupLength() {
            return this.dataGroup.length;
        },
    },
    methods: {
        /**
         * Обработка клика по чекбоксу группы
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} groupId - key группы
         */
        async checkedGroup(isChecked, groupId) {
            if (isChecked) {
                // включили группу
                if (!this.checkedParentList.includes(groupId)) {
                    this.checkedParentList.push(groupId);
                }

                const dublicateIndex = this.dataPoi.findIndex((el) => el.key === groupId);
                if (dublicateIndex === -1) {
                    const points = await this.fetchPoi(groupId);
                    this.dataPoi.push(...points);
                    this.$emit('onToggleGroupPoi', points, groupId, true);
                } else {
                    const filteredByGroupId = this.dataPoi.filter((el) => el.key === groupId);
                    this.$emit('onToggleGroupPoi', filteredByGroupId, groupId, true);
                }

                this.changeChildrenAll(true, groupId);

            } else {
                // отключили группу
                const indexCheckbox = this.checkedParentList.findIndex((el) => el === groupId);
                if (indexCheckbox !== -1) {
                    this.checkedParentList.splice(indexCheckbox, 1);
                }

                this.changeChildrenAll(false, groupId);
                this.$emit('onToggleGroupPoi', [], groupId, false);
            }
        },
        /**
         * Обработка клика по чекбоксу poi
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} poiId - id poi
         * @param {String} groupId - key группы
         */
        checkedPoi(isChecked, poiId, groupId) {
            if (isChecked && !this.checkedChildList.some((el) => el.id === poiId)) {
                this.checkedChildList.push({id: poiId, key: groupId});
            } else {
                const indexItem = this.checkedChildList.findIndex((el) => el.id === poiId);
                if (indexItem !== -1) {
                    this.checkedChildList.splice(indexItem, 1);
                }
            }

            const filtredCheckedChildByGroupId = this.checkedChildList.filter((el) => el.key === groupId);
            const indexGroup = this.checkedParentList.findIndex((el) => el === groupId);
            if (isChecked && filtredCheckedChildByGroupId.length > 0 && indexGroup === -1) {
                this.checkedParentList.push(groupId);
            }
            if (!isChecked && filtredCheckedChildByGroupId.length === 0 && indexGroup !== -1) {
                this.checkedParentList.splice(indexGroup, 1);
            }

            this.$emit('onToggleOnePoi', poiId, isChecked);
        },
        /**
         * Обработка клика по кнопке выбора всех чекбоксов
         */
        selectedAllCheckbox: async function () {
            this.dataGroup.forEach((el) => {
                const isDublicate = this.checkedParentList.includes(el.key);
                if (!isDublicate) {
                    this.checkedParentList.push(el.key);
                }
            });

            const points = await this.fetchPoi('all');
            this.dataPoi = points;
            this.$emit('onTogglePoiAll', points, true);

            this.checkedChildList = this.dataPoi.map((el) => {
                return {
                    id: el.id,
                    key: el.key,
                };
            });
        },
        /**
         * Обработка клика по кнопке снятия выбора всех чекбоксов
         */
        unselectedAllCheckbox() {
            this.checkedParentList = [];
            this.checkedChildList = [];
            this.$emit('onTogglePoiAll', [], false);
        },
        /**
         * Переключить активный модуль, получить список групп
         */
        async toggleModulePoi() {
            this.isShowSecondPanel = !this.isShowSecondPanel;

            if (this.dataGroupLength === 0) {
                this.isLoading = true;

                this.dataGroup = await this.fetchGroup();
            }

            this.isLoading = false;
        },
        /**
         * получить список групп
         */
        async fetchGroup() {
            let groups = [];
            const filter = {filter_params: this.filterSelectedData};
            await this.postGroups(filter, (groupslist) => {
                groups = groupslist;
            });
            return groups;
        },
        /**
         * получить список POI
         * @param {String} groupId - key группы
         */
        async fetchPoi(groupId) {
            let poiList = [];
            const params = {
                groups: groupId !== 'all' ? [Number(groupId)] : [...this.checkedParentList.map(Number)],
                filter_params: this.filterSelectedData,
            };
            await this.postPoints(params, (points) => {
                poiList = points;
            });

            return poiList;
        },
        /**
         * Обработка клика по названию группы. Получение точек группы с бека
         * @param {String} groupId - key группы
         */
        async toggleShowPoiList(groupId) {
            const poiByGroupId = await this.fetchPoi(groupId);
            this.dataPoi.push(...poiByGroupId);
        },
        /**
         * По нажатию на чекбокс группы нужно включить/выключить все poi данной группы
         * @param {boolean} isChecked - Включен/выключен чекбокс
         * @param {String} groupId - key группы
         */
        changeChildrenAll(isChecked, groupId) {
            if (isChecked) {
                const newCheckedList = this.dataPoi.filter((el) => el.key === groupId).map((el) => {
                    return {
                        id: el.id,
                        key: groupId,
                    };
                });
                this.checkedChildList.push(...newCheckedList);
            } else {
                this.checkedChildList = this.checkedChildList.filter((el) => el.key !== groupId);
            }
        },
        /**
         * Получить список групп доступных для добавления
         */
        async addNewGroupPoi() {
            const params = {
                filter_params: this.filterSelectedData,
            };
            await this.postAddGroupList(params, (groupList) => {
                this.groupsForAdd = groupList;
            });
        },
        /**
         * Получение списка выбранных групп для добавления
         * @param {Array} newGroupList - список групп
         */
        async newGroupPoi(newGroupList) {
            console.log('newGroupList', newGroupList)
            const params = { groups: newGroupList };
            await this.postAddGroup(params, (newGroups) => {
                console.log('newGroups', newGroups);
            });
            // this.dataGroup.unshift(newGroupList);
        },

        /**
         * Клик удалить метку
         * @param {string} poiId - id метки poi
         */
        async removePoi(poiId) {
            const isRemove = confirm('Удалить точку?');

            if (isRemove) {
                const params = { id: Number(poiId) };
                await this.postRemovePoi(params, (deletePoi) => {
                    console.log('deletePoi', deletePoi);
                });

                const findPoi = this.dataPoi.findIndex((el) => el.id === poiId);
                if (findPoi !== undefined) {
                    this.dataPoi.splice(findPoi, 1);
                }

                this.$emit('onToggleOnePoi', poiId, false);
            }

        },
        /**
         * Клик удалить группу
         * @param {string} groupId - key группы
         */
        async removeGroupPoi(groupId) {
            const isRemove = confirm('Удалить группу?');
            if (isRemove) {
                const params = { group: Number(groupId) };
                await this.postRemoveGroups(params, (removeGroup) => {
                    console.log('removeGroup', removeGroup)
                });

                const findGroup = this.dataGroup.findIndex((el) => el.key === groupId);
                if (findGroup !== undefined) {
                    this.dataGroup.splice(findGroup, 1);
                }

                this.$emit('onToggleGroupPoi', [], groupId, false);
            }

        },
    },
}
</script>

