import Vue from 'vue'
import axios from 'axios'

export default {
	namespaced: true,
	state: {
		tableData: [],
		changeHistory: [],

		requestData: {
			perPage: 30,
			pageNumber: 0,

			sortKey: '',
			sortType: '',

			filterData: {}
		},
	},
	getters: {
		tableData: state => state.tableData,
		changeHistory: state => state.changeHistory
	},
	mutations: {
		/**
		 *
		 * @param {Object} state
		 * @param {Array} data
		 */
		setTableData(state, data) {
			/* Формирую структуру объекта для дальнейшей работы */
			data.forEach(item => {
				Vue.set(item, 'selectetModification', {})

				let modificationsKeys = new Set()

				item.modifications.forEach(modificate => {
					modificationsKeys.add(modificate.entity_attribute)
				})

				modificationsKeys = Array.from(modificationsKeys)

				modificationsKeys.forEach(key => {
					Vue.set(item.selectetModification, key, null)
				})
			})

			state.tableData.push(...data)
		},
		/**
		 * Сохряняет тела запросов в массив changeHistory
		 * в дальнейшем при нажатии на кнопку сохранить все запросы будут выполненны в цикле
		 * @param {Object} state
		 * @param {Object} data
		 */
		setChangeHistory(state, data) {
			const {rowId, propName, optionId, action} = data

			if (action === 'access_item') {
				/* Передает в селект актуальное значение */
				const currentRow = state.tableData.find(item => item.id === rowId)
				currentRow.selectetModification[propName] = optionId
			}

			state.changeHistory.push(data)
		},
		/**
		 * Очищаю историю
		 */
		clearChangeHistory(state) {
			state.tableData.forEach(item => {
				for (let prop in item.selectetModification) {
					item.selectetModification[prop] = null
				}
			})

			state.changeHistory = []
		},
		/**
		 * Реактивно отрисовываю изменения
		 */
		applyСhanges(state) {
			state.changeHistory.forEach(history => {
				/* Находим редактируемую строку */
				const rowData = state.tableData.find(item => item.id === history.rowId)
				/* находим выбранную модификацию */
				const modifucationData = rowData.modifications.find(item => item.id === history.optionId)
				/* Новое выбранное значение */
				const newTitle = modifucationData.content
				/* Обновляем значение */
				rowData[history.propName] = newTitle

				const filteredModifications = rowData.modifications.filter(item => item.entity_attribute != history.propName)
				/* После применения удалить все опции выбора */
				rowData.modifications = filteredModifications
			})
		}
	},
	actions: {
		sortData(context, data) {
			const {dispatch, commit} = context
			commit('setSortKeys', data)
			dispatch('getDataFromApi')
		},
		filterData(context, data) {
			const {dispatch, commit} = context
			commit('setFilterData', data)
			dispatch('getDataFromApi')
		},
		/**
		 * Запрашивает данные с backend
		 * @param {Object} context
		 * @param {Object} params - объект конфигурации для запроса на backend содержит 2 поля. количесво записей на странице и номер страницы.
		 */
		getDataFromApi(context) {
			const {commit, getters} = context
			axios
				.post('https://catalog.oohdesk.ru/catalog/modify/list', getters.requestBody)
				.then(response => { commit('setTableData', response.data.data) });
		},
		/**
		 * Отправляю изменения на сервер
		 * @param {Object} context
		 */
		submitChangeRequest(context) {
			const {state, commit} = context

			/* TODO */
			for (let history of state.changeHistory) {
				const body = {
					action: history.action,
					id: history.optionId,
				}

				axios
					.post('https://catalog.oohdesk.ru/catalog/modify/add', body)
					/* TODO */
					.then((res) => {
						console.log(res);
					})
					.catch((error) => {
						alert("Изменения не были сохранены")
						console.log(error);
					})
			}

			// commit('applyСhanges')
			commit('clearChangeHistory')
		}
	}
}
