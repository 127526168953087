<template>
    <div class="login-page">
        <div class="login-page__flex-container">
            <section class="login-page__slider">
                <base-carousel :carousel-list="carouselList" />
            </section>
            <section class="login-page__content">
                <div class="login-page__content-wrapper">
                    <div class="login-page__content-logo">
                        <img
                            class="login-page__content-logo-img"
                            width="207"
                            height="50"
                            :src="require('@/assets/images/logo-sv.svg')"
                            alt="Логотип Oohdesk"
                        >
                    </div>
                    <div class="login-page__content-info">
                        <div class="login-page__content-title">Рады видеть вас!</div>
                        <div class="login-page__content-registration">
                            Впервые у нас?
                            <span>
                                <a href="#" @click="onClickRegistration">Зарегистрироваться</a>
                            </span>
                        </div>
                    </div>
                    <div class="login-page__form">
                        <label class="login-page__form-label">
                            Ваш e-mail
                            <base-text-input
                                v-model="login"
                                modificator="login"
                                view="primary"
                            />
                        </label>
                        <div class="login-page__form-password">
                            <label class="login-page__form-label">
                                Пароль
                                <base-password-input
                                    :is-show-pass-icon="true"
                                    v-model="password"
                                    modificator="login"
                                    view="primary"
                                />
                            </label>
                            <div class="login-page__repeat-password">
                                <a href="#" @click="onClickPass">Забыли пароль?</a>
                            </div>
                        </div>
                        <base-button
                            class="login-page__form-submit"
                            view="secondary"
                            @click="onLogin"
                        >Войти</base-button>
                    </div>

                    <div class="login-page__personal-data">
                        Нажимая кнопку «Войти», вы подтверждаете своё согласие на обработку
                        <a href="#">персональных данных</a>
                    </div>
                    <div class="login-page__copyright">© OOHDESK, 2018-2021</div>


                </div>
            </section>
        </div>
        <modal
            :is-animation="true"
            :is-close-button="true"
            v-show="isModalOpened"
            @close="onCloseModal"
        >
            <template v-slot:modalBody>
                <div class="login-page__modal-body" v-if="isOpenRegistrationModal">
                    Регистрация на данный момент недоступна
                </div>
                <div class="login-page__modal-body" v-if="isOpenPassModal">
                    Обратитесь к администратору за восстановлением пароля
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import BaseTextInput from "@/components/Base/BaseTextInput";
import BasePasswordInput from "@/components/Base/BasePasswordInput";
import BaseButton from "@/components/Base/BaseButton";
import BaseCarousel from "@/components/Base/BaseCarousel";
import Modal from '@/components/Base/Modal';

export default {
    name: "AppLoginV2",
    components: {
        BaseTextInput,
        BasePasswordInput,
        BaseButton,
        BaseCarousel,
        Modal,
    },
    data: () => ({
        isShowPass: false,
        carouselList: [
            {
                title: 'Выиграй или проиграй',
                description: 'Вы уверены в себе и поспорили на функционал в 12 часов ночи? ' +
                    'Мы уравняем ваши шансы с шансами ваших оппонентов. Теперь шансы 50 на 50! ' +
                    'Как обстоят дела - не знают даже разработчики',
                src: require('@/assets/images/sticker.png'),
            },
            {
                title: 'Сон - для слабаков',
                description: 'Кому нужен сон в 21 веке? На сон времени нет, новый функционал каждый час, бизнес-задачи просто фантастика',
                src: require('@/assets/images/sticker2.png'),
            },
            {
                title: 'Забудьте про свои нервы',
                description: 'Для чего нужны нервы? Да кто его знает, у нас их нет и вам не нужны',
                src: require('@/assets/images/sticker3.png'),
            }
        ],
        login: '',
        password: '',
        isModalOpened: false,
        isOpenRegistrationModal: false,
        isOpenPassModal: false,
    }),
    methods: {
        onLogin() {
            this.$emit('onLogin', this.login, this.password);
        },
        onCloseModal() {
            this.isModalOpened = false;
            this.isOpenRegistrationModal = false;
            this.isOpenPassModal = false;
        },
        onClickRegistration() {
            this.isModalOpened = true;
            this.isOpenRegistrationModal = true;
        },
        onClickPass() {
            this.isModalOpened = true;
            this.isOpenPassModal = true;
        },
    }
}
</script>

<style lang="scss" scoped>

$color-primary: #E10032;
$color-black: #000;
$color-title: #F5F5F5;
$color-link-hover: #fd7f9b;
$color-gray: #787A7E;
$color-bg-input: #F5F5F5;
$color-bg-slider: #4A92F6;

    .login-page {
        width: 100%;
        box-sizing: border-box;

        &__flex-container {
            display: flex;

            @media (max-width: 1000px) {
                flex-direction: column;
            }
        }

        &__slider {
            display: flex;
            justify-content: center;
            align-items: center;
            order: 0;
            width: 50%;
            box-sizing: border-box;
            background: $color-primary;

            @media (max-width: 1000px) {
                order: 1;
                width: 100%;
                min-width: auto;
            }
        }

        &__content {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            min-width: 340px;
            height: 100vh;
            padding: 80px 105px 20px;
            box-sizing: border-box;
            background: $color-black;

            @media (max-width: 1000px) {
                padding: 45px 65px 20px;
                width: 100%;
                height: auto;
            }

            @media (max-width: 576px) {
                padding: 30px 30px 20px;
            }
        }
        &__content-wrapper {
            width: 100%;
        }

        &__content-logo {
            display: flex;
            align-items: center;
            margin-bottom: 80px;
        }

        &__content-title {
            font-size: 32px;
            color: $color-title;
            font-weight: 400;
            margin: 0;
            padding: 0;
            margin-bottom: 5px;
        }

        &__content-info {
            margin-bottom: 30px;
        }

        &__content-registration {
            display: flex;
            font-size: 14px;
            color: $color-title;

            span {
                color: $color-primary;
                margin-left: 6px;
                transition: color .3s ease-in;

                &:hover {
                    color: $color-link-hover;
                }
            }
        }

        &__form-label {
            font-size: 14px;
            color: $color-gray;

            .wrapper--login {
                margin-top: 5px;
                margin-bottom: 14px;

                &__form-input {
                    height: 44px;
                    box-sizing: border-box;
                }
            }
        }

        &__repeat-password {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;

            a {
                font-size: 14px;
                color: $color-primary;
                transition: color .3s ease-in;
            }

            a:hover {
                color: $color-link-hover;
            }
        }

        &__form-submit {
            padding: 14px 51px;
            margin-bottom: 30px;
            font-size: 14px;
            font-weight: 500;
            background: $color-primary;
            transition: background .4s ease-in;

            &:hover {
                background: $color-link-hover;
            }
        }

        &__personal-data {
            font-size: 12px;
            color: $color-gray;
            margin-bottom: 80px;

            a {
                display: inline-block;
                color: $color-primary;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__copyright {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            font-size: 12px;
            color: $color-gray;
        }

        &__modal-body {
            padding: 100px;
            font-size: 20px;
            font-weight: 400;

            @media (max-width: 1000px) {
                padding: 50px 30px;
                font-size: 18px;
            }

            @media (max-width: 576px) {
                padding: 50px 20px;
                font-size: 14px;
            }
        }
    }
</style>
