<template>
	<div class="panel">
		<!-- <div
            @click="onSend"
            class="panel__item"
        >Создать предложение</div> -->
        <div
            class="panel__item"
            v-if="isExport"
            @click="onMoveToBudget"
        >
            Редактирование цен
        </div>
        <div
            class="panel__item"
            v-if="isExport"
            @click="onExportExcel"
        >
            Скачать Excel
        </div>
		<div
			class="panel__item"
			@click="onRemove"
		>
			<BaseIcon icon="icon-delete" style="font-size: 14px;" />
		</div>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon"
//isExpot
export default {
	name: "FolderControlPanel",
	components: {
		BaseIcon
	},
    props: {
        isExport: {
            type: Boolean,
            default: false
        }
    },
	methods: {
		onSend() {
			this.$emit('send')
		},
        onExportExcel(){
            this.$emit('exportExcel')
        },
		onRemove() {
			if (confirm("Вы уверены?"))
				this.$emit('remove')
		},
        onMoveToBudget(){
            this.$emit('moveToBudget')
        }
	}
}
</script>

<style lang="scss" scoped>
.panel {
	&__item {
		color: #B9BBC2;
		font-size: 12px;
		cursor: pointer;
		display: inline-block;
		margin-right: 15px;
		transition: all .25s;

		&:hover {
			color: #000;
		}
	}
}
</style>
