<template>
	<div class="cart">
		<AppTable
			primaryKey="id"
			:columns="columns"
			:rows="tableData"
			:isProgressBar="isProgressBarLoading"
			:canCheck="true"
			class="cart-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
			@check="onCheck"
		>
			<template
				v-for="(col,x) of modifieredCols"
				v-slot:[col]="scopeData"
			>
				<AdminCell
					:key="x"
					:cellValue="scopeData.row[col]"
					:cellInfo="{rowId: scopeData.row.id, propName: col}"
					:options="getRowOptions(scopeData.row, col)"
					:selected="scopeData.row.selectetModification[col]"
					:modifications="getModifications(scopeData.row, col)"
					@choose="onChoose"
				/>
			</template>

            <template v-slot:entity_info="scopeData">
                <BaseButton
                    :view="'secondary'"
                    @click="getEntityInfo(scopeData)"
                >Инфо</BaseButton>
            </template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
				/>
			</template>

			<template v-slot:light="scopeData">
				<AppLight :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.row.image_link" />
			</template>

            <template v-slot:direction="scopeData">
                <MapDirection :item="scopeData.row" :isEditable="false" />
            </template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>

            <template v-slot:materials="scopeData">
                <div style="display: block">
                    <div
                        v-for="(value,index) of scopeData.row.materials"
                        :key="index"
                    >
                        {{value}}
                    </div>
                </div>
            </template>
		</AppTable>

		<!-- @saveSome="onSaveSomeRows"
		@resetSome="onresetSomeRows" -->
		<ActionPanel
			class="cart__panel"
			@save="onAllSave"
			@reset="onAllReset"
		/>
	</div>
</template>

<script>
import AppTable from "@/components/Table2/Table"
import AdminCell from "@/components/CellComponents/AdminCell2"
import AppPhoto from '@/components/CellComponents/Photo.vue'
import CountryFlag from '@/components/CellComponents/CountryFlag.vue'
import AppLight from '@/components/CellComponents/Light.vue'
import ActionPanel from '@/components/HandbookComponents/ActionPanel'
import AppIdentifier from '@/components/CellComponents/Identifier.vue'
import AppLink from '@/components/CellComponents/Link.vue'
import MapImage from '@/components/CellComponents/MapImage.vue'
import BaseButton from "@/components/Base/BaseButton";
import MapDirection from "@/components/CellComponents/MapDirection";

export default {
	name: "CartTab",
	components: {
		AppTable,
		AdminCell,
		AppPhoto,
		CountryFlag,
		AppLight,
		ActionPanel,
		AppIdentifier,
		AppLink,
		MapImage,
        BaseButton,
        MapDirection
	},
	props: {
		columns: {
			type: Array,
			required: true
		},
		tableData: {
			type: Array,
			required: true
		},
		isProgressBarLoading: {
			type: Boolean,
			required: true
		},
		modifieredCols: {
			type: Array,
			requried: true
		}
	},
	created() {
		this.$emit('load', {
            urlName: 'cartList'
			// url: 'https://catalog.oohdesk.ru/catalog/modify-cart'
		})
	},
	methods: {
		/**
		 * Срабатывает при выделении строки
		 * @param {Array} identifiers - массив содержащий id строк
		 */
		onCheck(identifiers) {
			this.$emit('selectRow', identifiers)
		},
		/**
		 * записывает изменения в store
		 * не отправляет на backend
		 * @param {Object} $event
		 */
		onChoose($event) {
			this.$emit('edit', $event);
		},
		/**
		 * сушатель компонента AppTable
		 * @param {Object} $event - название колонки по которой был произведен клик в шапке таблицы
		 */
		onSort($event) {
			this.$emit('sort', $event)
		},
		/**
		 * @param {Object} $event - объект содержащий значения для фильрации
		 */
		onFilter($event) {
			this.$emit('filter', $event)
		},
		/**
		 * сохраняет все изменения в корзине
		 */
		onAllSave() {
			this.$emit('approveCartChanges')
		},
		/**
		 * удаляет все изменения из корзины
		 */
		onAllReset() {
			this.$emit('clearCart')
		},
		onLazyload() {
			this.$emit('lazyload')
		},
		getRowOptions(rowData, propName) {
			const result = []
			const modifications = rowData.modifications || []

			modifications.forEach(element => {
				if (element.entity_attribute === propName) {
					result.push({id: element.id, value: element.content})
				}
			});

			return result
		},
		getModifications(rowData, propName) {
			const result = []

			rowData.modifications.forEach(item => {
				if (item.entity_attribute === propName) {
					result.push(item)
				}
			})

			return result
		},
        getEntityInfo(scopeData){
            this.$emit('entityInfo', scopeData.row.id);
        }
	}
}
</script>

<style lang="scss" scoped>
.cart {
	&__panel {
		flex-grow: 1;
	}
}
</style>
