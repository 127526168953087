<template>
	<div
        ref="table"
        class="vertical-table"
    >
        <div class="body">
            <div
                v-for="(column, posY) of columns"
                :key="posY"
                :data-rowid="posY"
                class="row"
            >
                <div class="cell cell-header">
                    {{column.label}}
                </div>
                <div class="cell">
                    <slot
                        v-if="column.isSlot"
                        :name="column.prop"
                        :cell="row[column.prop]"
                        :row="row"
                        :column="column"
                    />
                    <div
                        v-else
                        class="cell__content"
                    >{{row[column.prop]}}</div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>

export default {
    name: "VerticalTable",
    props: {
        columns: {
            type: Array,
            required: true
        },
        row: {
            type: Object,
            // required: true
            default: () => ({})
        },
    },
    components: {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>

.vertical-table {
    font-size: 12px;
    position: relative;
    max-width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

	.body {
        .row {
            margin-bottom: 4px;
            transition: .25s;

            .cell {
                background: #F9FAFB;
                transition: .25s;
            }

            &:hover {
                .cell {
                    background: #FFF !important;
                }
            }
            &__no-margin {
                margin-bottom: 0px;
            }
        }
    }

    .row {
        display: flex;
    }

    .cell-header{
        font-weight: bold;
    }

	.cell {
        position: relative;
        background: #fff;
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        min-width: 50px;
        padding: 7px 15px;
        display: flex;
        align-items: center;

        &-selected {
            background: #FFF !important;
        }

        &-checkbox {
            width: 54px;

            label {
                cursor: pointer;

                input {
                    display: none;

                    &:checked + span {
                        border-color: $active-color;
                        background-color: $active-color;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        background-size: 60%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                span {
                    width: 24px;
                    height: 24px;
                    border: 2px solid;
                    border-color: #ccc;
                    display: block;
                    border-radius: 4px;
                    transition: border-color 250ms;

                    &:hover {
                        border-color: $active-color;
                    }
                }
            }
        }
    }
}
</style>
