<template>
	<div
		class="select"
		:class="getSelectClasses"
		:tabindex="0"
		@blur="onClose"
		@click="onClickSelect"
	>
		<div class="select-preview">
			<span>{{title}}</span>
			<img src="@/assets/images/icons/arrow.svg">
		</div>

		<div
			v-if="isOpen"
			class="select-options"
		>
			<div
				v-for="option of options"
				:key="option.id"
				class="select-options-item"
				:class="getOptionClasses(option)"
				@click="onCheck(option)"
			>
				<div class="select-options-item__top">
					<p class="select-options-item__value">{{ option.value }}</p>
                    <p class="select-options-item__mail">{{ getEvent(option.id) }}</p>
                    <p class="select-options-item__mail">{{ getSubevent(option.id) }}</p>
					<p class="select-options-item__mail">{{ getEmail(option.id) }}</p>
				</div>
				<div class="select-options-item__bottom">
					<p class="select-options-item__date">{{ getDate(option.id) }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		selected: {
			required: true,
		},
		modifications: {}
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		/**
		 * Возвращаю заголовок селекта
		 */
		title() {
			if (this.options.length === 1) {
				return this.options[0].value
			}

			let result
			const selectedOption = this.options.find(item => item.id === this.selected)

			if (selectedOption != undefined) {
				result = selectedOption.value
			} else {
				result = `Выберите из ${this.options.length}`
			}

			return result
		},
		getSelectClasses() {
			const selectedOption = this.options.find(item => item.id === this.selected) != undefined
			return {
				'select_active': selectedOption,
				'select_one': this.options.length === 1,
				'select_canceled': this.selected === '$canceled'
			}
		}
	},
	methods: {
		getEmail(id) {
			return this.modifications.find(item => item.id === id).contentFormed.user
		},
        getEvent(id) {
            return this.modifications.find(item => item.id === id).contentFormed.info[0].event
        },
        getSubevent(id) {
            return this.modifications.find(item => item.id === id).contentFormed.info[0].subevent
        },
		getDate(id) {
			return this.modifications.find(item => item.id === id).contentFormed.date
		},
		/**
		 * @returns {Object} перечень классов для опций
		 */
		getOptionClasses(option) {
			return {
				'select-options-item_active': this.selected === option.id
			}
		},
		/**
		 * toggle
		 */
		onClickSelect() {
			if (this.options.length === 1) {
				this.$emit('choose', this.options[0].id)
			} else {
				this.isOpen = !this.isOpen
			}
		},
		/**
		 * Закрыть select
		 */
		onClose() {
			this.isOpen = false
		},
		/**
		 * @param {Object} option - option по которому был совершен клик
		 */
		onCheck(option) {
			this.$emit('choose', option.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.select {
	display: inline-block;
	position: relative;
	// max-width: 130px;
	user-select: none;
	outline: none;
	font-size: 13px;

	&_one {
		.select-preview img {
			display: none;
		}
	}

	&_active {
		.select-preview {
			color: #6BCC9C;
		}
	}

	&_canceled {
		.select-preview span {
			color: #BEC0C7 !important;
			text-decoration: line-through;
		}
	}

	&-preview {
		padding: 2px 0;
		border: 1px solid #F3F3F3;
		border-radius: 6px;
		padding-left: 10px;
		padding-right: 10px;
		color: #F84967;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		transition: .2s;

		// span {}

		span{
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		img {
			transform: rotate(-90deg);
			width: 7px;
			margin-left: 10px;
			flex-shrink: 0;
		}
	}

	&-options {
		position: absolute;
		display: inline-block;
		left: 0px;
		top: 100%;
		transform: translateY(8px);
		background: #fff;
		padding: 4px;
		border-radius: 4px;
		z-index: 2;
		box-shadow: 0 0 12px 0px rgba(170, 170, 170, 0.651);
		color: #000;
		max-height: 250px;
		max-width: 500px;
		overflow: auto;

		&-item {
			padding: 13px 9px;
			cursor: pointer;
			border-radius: inherit;
			transition: .1s;
			margin: 2px 0px;

			&__top {
				display: flex;
				justify-content: space-between;
			}

			&__value {
				width: 200px;
			}

			&__mail {
				margin-left: 20px;
			}

			&__bottom {
				margin-top: 8px;
				display: flex;
				justify-content: flex-end;
			}

			&_active,
			&:hover {
				background: #2274ff;
				color: #fff;
			}
		}
	}
}
</style>
