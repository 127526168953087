<template>
	<div
		class="d-wrap"
		v-click-outside="onClose"
		ref="content"
	>
		<div class="d-wrap__content-wrapper" @click="onToggle">
			<slot name="content"/>
		</div>

		<div
			v-if="isVisible"
			class="dropdown"
			:style="getDropdownStyles"
		>
			<div class="dropdown__triangle" :style="getDropdownTriangleStyles" />

			<div
				v-if="isExsistHeader"
				class="dropdown-header"
			>
				<slot name="header" />
			</div>

			<div
				v-if="items.length > 0"
				class="dropdown-main"
			>
				<div
					v-for="(item, index) of items"
					:key="index"
					class="dropdown-main__item"
					@click="onCheck(item)"
				>
					<div class="text">{{item.value}}</div>
				</div>
			</div>

			<div
				v-if="isExsistFooter"
				class="dropdown-footer"
			>
				<slot name="footer" />
			</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
	name: "BaseDropdownItems",
	/**
	 * @property {Array} items - массив объектов { value: '', icon: '' }
	 */
	props: {
		items: {
			type: Array,
			default: () => []
		},
		position: {
			type: String,
			default: 'right',
			validator: (value) => ['right', 'left'].includes(value)
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isVisible: false
		}
	},
	computed: {
		getDropdownTriangleStyles() {
			return {
				[this.position]: '10px'
			}
		},
		getDropdownStyles() {
			return {
				[this.position]: 0,
				top: this.$refs.content.offsetHeight + 16 + 'px'
			}
		},
		/**
		 * Вернет true, если передал слот header
		 * @returns {Boolean}
		 */
		isExsistHeader() {
			return !!this.$slots.header
		},
		/**
		 * Вернет true, если передал слот footer
		 * @returns {Boolean}
		 */
		isExsistFooter() {
			return !!this.$slots.footer
		},
	},
	methods: {
		/**
		 * выбрал пункт из dropdown
		 */
		onCheck(value) {
			this.$emit('check', value)
			this.onClose()
		},
		/**
		 * Закрыть dropdown
		 */
		onClose() {
			this.isVisible = false
		},
		/**
		 * Открыть dropdown
		 */
		onOpen() {
			if (this.disabled) { return }

			this.isVisible = true
		},
		onToggle() {
			if (this.disabled) { return }

			this.isVisible = !this.isVisible
		}
	},
	directives: {
		ClickOutside
	}
}
</script>

<style lang="scss" scoped>
.d-wrap {
	position: relative;
}

.dropdown {
	cursor: initial;
	position: absolute;
	background: #fff;
	box-shadow: 0 4px 28px rgba(59, 74, 116, .15), 0 4px 10px rgba(59, 74, 116, .15);
	min-width: 250px;
    border-radius: 4px;
    z-index: 100;

	&__triangle {
		content: "";
		position: absolute;
		display: block;
		border: 9px solid transparent;
		border-bottom-color: #fff;
		top: -16px;
		z-index: 999;
	}

	&-header {
		padding: 25px;
		border-bottom: 1px solid #ececec;
	}

	&-main {
		padding: 10px;

		&__item {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 30px 0 15px;
			height: 36px;
			border-radius: 36px;
			width: 100%;
			white-space: nowrap;
			line-height: 1;
			color: #222;
			transition: all 150ms;
			cursor: pointer;
			font-size: 12px;
			margin: 4px 0;

			&:hover {
				background: #f3f3f3;
				color: #222;
			}

			img {
				max-width: 14px;
				max-height: 14px;
				min-height: 10px;
			}

			.text {
				margin-left: 6px;
			}
		}
	}

	&-footer {
		padding: 25px;
		border-top: 1px solid #ececec;
	}
}
</style>
