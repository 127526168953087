<template>
    <div class="page-bayuerdesk">
        <transition name="page-bayuerdesk__loading">
            <div v-if="isLoadingFilter" class="page-bayuerdesk__loading">
                <base-preloader-gif :size="40" />
            </div>
        </transition>
        <kit-panel
            :kits="kitPanelItems"
            :kitCustomIdSelected="kitCustomIdSelected"
            :kitsTableActive="kitsFolderIdsActive"
            :kitsMarkersActive="kitsMarkersActive"
            :isMarginLeft="true"
            @onSelectKit="onSelectKit"
            @onCreateKit="onCreateKit"
            @onRenameKit="onRenameKit"
            @onToggleTableKit="onToggleTableKit"
            @onToggleMarkersKit="onToggleMarkersKit"
        />
        <div class="page-bayuerdesk-map">
            <div class="page-bayuerdesk-map__wrapper">
                <map-view
                    ref="mapView"
                    :markers-statuses="markersStatuses"
                    @onSelectMarker="onSelectMarker"
                    @onSelectMarkerRight="onSelectMarkerRight"
                    @onMassSelectMarkers="onMassSelectMarkers"
                    @activeLegendPanel="onChangeMarkers"
                    @onToggleGroupPoi="toggleGroupPoi"
                    @onTogglePoiAll="togglePoiAll"
                    @onToggleOnePoi="toggleOnePoi"
                />
                <card-free
                    v-if="isCardVisible"
                    :sides="cardData"
                    :selectedData="cardMarkerSelected"
                    class="page-bayuerdesk-map__construction-card"
                    @close="onCloseCard"
                    @onCheckPrice="onCheckPrice"
                    @onPanoramaGoogle="onPanoramaGoogle"
                />
            </div>
        </div>
        <folder
            :id="kitFound.id"
            :title="kitFound.title"
            :isMinimize="false"
            :width="calculateFolderWidth('found')"
            :height="calculateFolderHeight('found')"
            :top="getPositionFolderKit(kitFound.id, 'top')"
            :left="getPositionFolderKit(kitFound.id, 'left')"
            :color="getColorKit(kitFound.id)"
            :isRename="false"
            @click.native="onSelectKit(kitFound.id, 'found')"
        >
            <template v-slot:content>
                <kit-tab
                    :canCheck="true"
                    :columns="tableColumns"
                    :modifieredCols="tableColumnsModifered"
                    :datesCols="tableColumnsDates"
                    :tableData="tableRows"
                    :folderId="kitFound.id"
                    :rowsSelected="tableFoundRowsSelected"
                    :rowsSelectedPart="tableFoundRowsSelectedPart"
                    :rowsCheckboxes="tableCustomPricesSelected"
                    :style="{'height': '100%'}"
                    :isProgressBarLoading="isProgressBarLoading"
                    @lazyload="onLazyloadTableDataFound"
                    @filter="onFilterTableDataFound"
                    @sort="onSortTableDataFound"
                    @onCheckSubCheckbox="onCheckSubCheckboxFound"
                    @onCheckRow="onCheckRowFound"
                    @onCheckRowsAll="onCheckRowsAllFound"
                    @onClickCell="onClickCell"
                />
            </template>
        </folder>
        <layout-folder
            v-for="(kit, kitIndex) in kitCustoms"
            ref="layoutFolder"
            :key="kitIndex"
            :id="kit.id"
            :title="kit.title"
            :folderType="kit.type"
            :left="getPositionFolderKit(kit.id, 'left')"
			:top="getPositionFolderKit(kit.id, 'top')"
            :color="getColorKit(kit.id)"
            :isRename="true"
            :isCurrent="String(kitCustomIdSelected) === String(kit.id)"
            @onRenameFolder="onRenameKit"
            @onClickFolder="onSelectKit"
            @onCheckSubCheckbox="onCheckSubCheckboxFound($event, 'allPrices')"
            @afterGetMarkers="afterGetMarkersCustom"
            @drawMarkers="drawMarkersFound"
            @removeFolder="onRemoveFolder"
            @exportFolder="onExportFolder"
            @moveToBudget="onMoveToBudget"
            @onClickCell="onClickCell"
        >
        </layout-folder>
    </div>
</template>

<script>
import {mapGetters, mapState, mapMutations } from 'vuex';
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
import MapView from '@/components/MapV2/MapView';
import KitPanel from '@/components/KitPanelV2/KitPanel';
import LayoutFolder from '@/layouts/LayoutsPageBayuerdesk/LayoutFolder';
import Folder from '@/components/Folder/Folder';
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';
import MixinKitFolder from '@/mixins/MixinsPageBayuerdesk/MixinKitFolder';
import MixinKitPanel from '@/mixins/MixinsPageBayuerdesk/MixinKitPanel';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinMapFound from '@/mixins/MixinsPageBayuerdesk/MixinMapFound';
import KitTab from '@/components/HandbookComponents/KitTab';
import CardFree from '@/components/ConstructionCardControllers/CardFree'
export default {
    name: 'PageBayuerdeskV2',
    components: {
        BasePreloaderGif,
        MapView,
        KitPanel,
        LayoutFolder,
        Folder,
        KitTab,
        CardFree
    },
    mixins: [
        MixinApi,
        MixinKitFolder,
        MixinKitPanel,
        MixinTableFound,
        MixinMapFound
    ],
    /**
     * Данные компонента
     * @property {String} isProgressBarLoading - Показать/скрыть preloader страницы при загрузке
     * @property {String} isLoadingFilter - статус инициализации фильтра
     * @property {Array} markersStatuses - список статусов маркеров
     * @property {Object} activeLegendPanelMarkersStatuses - список выбранных статусов маркеров в базовой легенде
     */
    data: () => ({
        isProgressBarLoading: false,
        isLoadingFilter: false,
        markersStatuses: [],
        markersPoi: [],
    }),
    computed: {
        /** Проксируем состояния из стора */
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData,
            tableCustomPricesSelected: state => state.kit.tableCustomPricesSelected,
            tableCustomRows: state => state.kit.tableCustomRows,
            withoutPricesStepCount: state => state.filter.withoutPricesStepCount,
            withoutPricesSideCount: state => state.filter.withoutPricesSideCount,
            isFilterLoading: state => state.filter.isFilterLoading,
            activeLegendPanelMarkersStatuses: state => state.pageBayuerdesk.activeLegendPanelMarkersStatuses,
            allActiveLegendPanelMarkersStatuses: state => state.pageBayuerdesk.allActiveLegendPanelMarkersStatuses,
            markersStatusesListDefault: state => state.pageBayuerdesk.markersStatusesListDefault,
        }),
        ...mapGetters('pageBayuerdesk', ['onlyWithoutPrice']),
        /** Проксируем геттеры из стора */
        // ...mapGetters('kit', ['tableFoundRowsSelected']),
        /**
         * Выбранные строки текущего набора
         * @returns {Array}
         */
        tableFoundRowsSelected() {
            let tableFoundRowsSelected = [];
            Object.keys(this.tableCustomPricesSelected).forEach(rowId => {
                const datesPrices = this.tableCustomPricesSelected[rowId] || [];
                const {date: datesFilter = []} = this.filterSelectedData;
                const datesPricesFiltered = datesPrices.filter(date => datesFilter.includes(date));
                if (datesPricesFiltered.length !== 0)
                    tableFoundRowsSelected.push(rowId);
            });
            return tableFoundRowsSelected;
        },
        /**
         * Частично выделенные строки
         * @returns {Array}
         */
        tableFoundRowsSelectedPart() {
            const tableFoundRowsSelectedPart = this.tableFoundRowsSelected.filter(id => {
                let isFilter = false;
                const row = this.tableRows.find(row => String(row.id) === String(id));
                if (typeof row !== 'undefined') {
                    const {prices = []} = row;
                    const isEvery = prices.every(price => this.tableCustomPricesSelected[id].includes(price.date));
                    isFilter = !isEvery;
                }
                return isFilter;
            });
            return tableFoundRowsSelectedPart;
        },
        /**
         * Выделеные маркеры найдено
         * @returns {Array}
         */
        mapFoundMarkersSelectedIds() {
            const tableCustomRowsFiltered = this.tableCustomRows.filter(row => {
                const {id = -1} = row;
                return this.tableFoundRowsSelected.includes(String(id));
            });
            const mapFoundMarkersSelectedIds = tableCustomRowsFiltered.map(row => {
                const {marker_id = '-1'} = row;
                return marker_id;
            });
            return mapFoundMarkersSelectedIds;
        },
    },
    watch: {
        /** Следим за изменением фильтра и подтягиваем найдено */
        filterSelectedData() {
            this.postTableDataFound(this.tableFoundRequestBody, (count, rows) => {
                this.setDescriptionKitFound(this.withoutPricesSideCount);
                this.afterPostTableData(rows, 'prices', true);
            });

            const postData = {filter_params: this.filterSelectedData};
            this.postMarkersDataFound(postData, (data) => {
                const { markers, statuses } = data;
                const {id = '-1'} = this.kitFound;
                const color = this.getColorKit(id);
                this.markers = this.adapterMarkers(markers, color, id)
                    .map((el) => {
                        if (el.extraData.kitId === '0') {
                            el.fill_color = el.color_legend;
                            return el;
                        }
                        return el;
                    });
                this.$refs.mapView.redrawMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {kitId = '-1'} = extraData;
                    if (String(kitId) === String(this.kitFoundId))
                        return true;
                    else
                        return false;
                }, this.markers, this.kitsMarkersActive.includes(this.kitFoundId));
                this.setMarkersSelectFound();
                this.$refs.mapView.fitBounds();
                this.setMarkersStatuses(statuses);
            });
            this.eraseWithoutPrices();
            if(this.filterSelectedData.without_prices){
                this.redrawWithoutPricesMarkers();
            }
            this.isLoadingFilter = false;
        },
        isFilterLoading(val) {
            if (val)
                this.isLoadingFilter = true;
            else
                this.isLoadingFilter = false;
        },
        /** Следим за изменениями выделенных строк найдено и обновляем маркеры */
        tableFoundRowsSelected() {
            this.setMarkersSelectFound();
            const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
            this.$refs.layoutFolder[index].updateMarkers();
            this.$refs.layoutFolder[index].postSummaryData();
        }
    },
    beforeMount() {
        this.isLoadingFilter = true;
    },
    mounted() {
        /** При монтировании получаем список наборов */
        this.postKitsList((kits) => {
            this.afterPostKitsList(kits);
            this.onToggleTableKit(this.kitFoundId, 'found');
            this.onToggleMarkersKit(this.kitFoundId);
            this.$nextTick(() => {
                this.onToggleMarkersKit(this.kitCustomIdSelected, 'custom');
                this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
            });
        });
        this.setCurrentComponent('PageBuyerdesk');
    },
    methods: {
        ...mapMutations(['setCurrentComponent']),
        ...mapMutations('pageBayuerdesk', [
            'setAllActiveMarkerStatuses',
            'setActiveMarkerStatuses',
            'setMarkersStatusesList',
            'setMarkersStatusesListDefault',
        ]),
        /**
         * Ключить панораму гугл
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        onPanoramaGoogle(lat = null, lng = null) {
            this.$refs.mapView.setStreetView(lat, lng);
        },
        /**
         * Нарисовать маркеры конструкций без цен
         */
        redrawWithoutPricesMarkers() {
            const postData = {filter_params: this.filterSelectedData};
            for (let i = 0; i < this.withoutPricesStepCount;i++){
                postData.step = i;
                this.postMarkersDataWithoutPrices(postData, (markers) => {
                    const id = '-2';
                    const color = '#848484';
                    this.markersWithoutPrices = this.adapterMarkers(markers, color, id)
                        .map((marker) => {
                            marker.status = 'without_price';
                            marker.extraData.status = marker.status;
                            marker.fill_color = color;
                            return marker;
                        });
                    this.$refs.mapView.redrawMarkers(() => {
                        return false;
                    }, this.markersWithoutPrices, 1);
                });
            }
        },
        eraseWithoutPrices() {
            // debugger;
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId = '-2'} = extraData;
                if (String(kitId) === String('-2'))
                    return true;
                else
                    return false;
            }, [], 1);
        },
        /**
         * Событие выделения набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onSelectKit(id = '-1', type = '') {
            if (id !== this.kitFoundId && id !== this.kitCustomIdSelected) {
                if (this.kitsMarkersActive.includes(String(this.kitCustomIdSelected)))
                    this.onToggleMarkersKit(this.kitCustomIdSelected, 'custom');
                if (this.kitsFolderIdsActive.includes(this.kitCustomIdSelected))
                    this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
                this.selectKit(id, type);
                this.onToggleMarkersKit(id, type);
                this.onToggleTableKit(id, type);
                this.bringToFront(id);
            }
        },
        /**
         * Событие создания набора
         */
        onCreateKit() {
            console.log('createKit');
            const newKit = this.createKit();
            this.postCreateKit(newKit, (kit) => {
                this.afterPostCreateKit(kit);
            });
        },
        /**
         * Событие переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое название
         */
        onRenameKit(id = '-1', newName = '') {
            const postData = {set_id: id, name: newName};
            this.postRenameKit(postData, (id, newName) => {
                this.afterPostRenameKit(String(id), String(newName));
            });
        },
        /**
         * Событие переключение показа таблицы набора
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        onToggleTableKit(id = '-1', type = '') {
            if (id !== this.kitFoundId && id !== this.kitCustomIdSelected) {
                if (this.kitsFolderIdsActive.includes(this.kitCustomIdSelected))
                    this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
            }
            this.selectKit(id, type);
            this.toggleFolder(String(id), type);
        },
        /**
         * Событие переключения показа маркеров набора
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        onToggleMarkersKit(id = '-1', type = '') {
            this.selectKit(id, type);

            if (!this.kitsMarkersActive.includes(String(id))) {
                this.kitsMarkersActive.push(String(id));

                if (String(id) === this.kitFoundId) {
                    this.markersStatuses = this.markersStatusesListDefault;
                    this.drawMarkersFound(this.kitFoundId);
                } else {
                    const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(id));
                    this.$refs.layoutFolder[index].getMarkers();
                }
            }
            else {
                this.kitsMarkersActive.splice(this.kitsMarkersActive.findIndex(kitId => String(kitId) === String(id)), 1);

                if (String(id) === this.kitFoundId) {
                    if (this.activeLegendPanelMarkersStatuses.includes('without_price')) {
                        this.markersStatuses = this.onlyWithoutPrice;
                        this.setActiveMarkerStatuses(['without_price']);
                    }
                    if (!this.activeLegendPanelMarkersStatuses.includes('without_price')) {
                        this.markersStatuses = this.onlyWithoutPrice;
                        this.setActiveMarkerStatuses([]);
                    }

                    this.clearMarkersFound(this.kitFoundId);
                    this.onChangeMarkers();
                } else {
                    this.clearMarkersFound(id);
                }

            }
        },
        /**
         * Событие ленивой загрузки найдено
         */
        onLazyloadTableDataFound() {
            this.isProgressBarLoading = true;
            this.tableFoundPageNumber += 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterLazyloadTableDataFound(rows);
                this.isProgressBarLoading = false;
            });
        },
        /**
         * Событие фильтрации по столбцам найдено
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilterTableDataFound(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.tableFoundPageNumber = 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterPostTableData(rows, 'prices', true);
            });
        },
        /**
         * Событие сортировки в найдено
         * @param {Object} dataSort - данные сортировки
         */
        onSortTableDataFound(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.tableFoundPageNumber = 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterPostTableData(rows, 'prices', true);
            });
        },
        /**
         * Событие массового выбора строк найдено
         * @param {Boolean} isSelected - значение флага массового выбора
         */
        onCheckRowsAllFound(isSelected = false) {
            const rowsIds = this.tableRows.map(row => {
                const {id = ''} = row;
                return String(id);
            });
            const priceIds = this.tableRows.reduce((priceIdsAccumulator, row) => {
                const {prices = []} = row;
                const priceIdsCurrent = prices.map(price => {
                    const {id = ''} = price;
                    return String(id);
                });
                return [...priceIdsAccumulator, ...priceIdsCurrent];
            }, []);
            const action = isSelected ? 'add' : 'remove';
            const postData = {set_id: this.kitCustomIdSelected, action, ids: priceIds};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected, selected: rowsIds};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    rowsIds.forEach(rowId => {
                        this.afterPostTableDataRow(rows, action, rowId);
                    });
                    const {extraData = {}} = this.cardMarker;
                    // const {kitId = '-1', sideIds = []} = extraData;
                    const {kitId = '-1'} = extraData;
                    const sideIds = this.cardData.map(card => String(card.id));
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                    this.updateCardMarkersSelected(sideIds, tableRowsPricesIdsSelected);
                });
            });
        },
        /**
         * Событие выбора строки найдено
         * @property {Object} checkData - данные выбора
         */
        onCheckRowFound(checkData = {}) {
            const {rowIsSelected = false, row = {}} = checkData;
            const {id: rowId = -1, prices = []} = row;
            const currentPricesSelected = this.tableCustomPricesSelected[rowId] || [];
            const priceIds = prices.filter(price => {
                const {date = ''} = price;
                if (rowIsSelected)
                    return !currentPricesSelected.includes(date);
                else
                    return currentPricesSelected.includes(date);
            }).map(price => price.id);
            const action = rowIsSelected ? 'add' : 'remove';
            const postData = {set_id: this.kitCustomIdSelected, action, ids: priceIds};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected, selected: [rowId]};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    this.afterPostTableDataRow(rows, action, rowId);
                    const {extraData = {}} = this.cardMarker;
                    // const {kitId = '-1', sideIds = []} = extraData;
                    const {kitId = '-1'} = extraData;
                    const sideIds = this.cardData.map(card => String(card.id));
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                    this.updateCardMarkersSelected(sideIds, tableRowsPricesIdsSelected);
                });
            });
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         */
        onCheckSubCheckboxFound(checkData = {}, keyPrices = 'prices') {
            const {postData = {}, rowId = '-1', action = ''} = this.beforePostSyncDataCustom(checkData, this.tableCustomPricesSelected, this.kitCustomIdSelected, keyPrices);
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected, selected: [rowId]};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    this.afterPostTableDataRow(rows, action, rowId);
                    const {extraData = {}} = this.cardMarker;
                    // const {kitId = '-1', sideIds = []} = extraData;
                    const {kitId = '-1'} = extraData;
                    const sideIds = this.cardData.map(card => String(card.id));
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                    this.updateCardMarkersSelected(sideIds, tableRowsPricesIdsSelected);
                });
            });
        },
        /**
         * Событие сработающее после получения маркеров набора
         * @param {String} kitId - идентификатор набора
         * @param {Array} markers - массив маркеров
         */
        afterGetMarkersCustom(kitId = '-1', markers = []) {
            // debugger;
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId: markerKitId = '-1'} = extraData;
                if (String(markerKitId) === String(kitId))
                    return true;
                else
                    return false;
            }, markers, this.kitsMarkersActive.includes(String(kitId)), false);
        },
        /**
         * Событие, сработающее после запроса на получения данных для строки при добавлении в набор
         * @property {Array} rows - строки
         * @property {String} action - действие
         * @property {String} rowId - идентификатор строки
         */
        afterPostTableDataRow(rows = [], action = '', rowId = '-1') {
            if (rows.length !== 0)
                this.updateTableCustom(rows, action);
            else {
                const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                this.$refs.layoutFolder[index].removeRowTable(rowId);
            }
        },
        /**
         * Обновить данные таблицы набора
         * @property {Array} rows - строки
         * @property {String} action - действие
         */
        updateTableCustom(rows = [], action = '') {
            const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
            if (action === 'add')
                this.$refs.layoutFolder[index].addPriceTable(rows);
            else if (action === 'remove')
                this.$refs.layoutFolder[index].removePriceTable(rows);
        },
        /**
         * Обновить маркеры найдено
         */
        setMarkersSelectFound() {
            this.$refs.mapView.updateMarkers((marker) => {
                const {id = '-1', extraData = {}, state = -1} = marker;
                const {kitId = '-1'} = extraData;
                if (String(kitId) === String(this.kitFoundId) && state === 1) {
                    marker.updateState(0, this.kitsMarkersActive.includes(this.kitFoundId));
                }
                if (String(kitId) === String(this.kitFoundId) && this.mapFoundMarkersSelectedIds.includes(String(id))) {
                    marker.updateState(1, this.kitsMarkersActive.includes(this.kitFoundId));
                }
            }, false);
        },
        /**
         * Нарисовать маркеры найдено
         * @param {String} kitId - идентификатор набора
         */
        drawMarkersFound(kitId = '-1') {
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId: markerKitId = '-1', type = 'default'} = extraData;
                if (type !== 'poi' && String(markerKitId) === String(kitId)) {
                    marker.drawMarker();
                }
            });
        },
        /**
         * Очистить маркеры найдено с карты
         * @param {String} kitId - идентификатор набора
         */
        clearMarkersFound(kitId = '-1') {
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId: markerKitId = '-1', type = 'default'} = extraData;

                if (type !== 'poi' && String(markerKitId) === String(kitId)) {
                    marker.clearMarker();
                }
            });
        },
        /**
         * Событие массового выбора маркеров
         * @param {Array} markers - маркеры
         */
        onMassSelectMarkers(markers = []) {
            const markersNoSelected = markers.filter((marker) => {
                const {state = 0} = marker;
                return state === 0;
            });
            markersNoSelected.forEach(marker => this.onSelectMarker(marker));
        },
        /**
         * Событие выбора маркера
         * @param {Object} marker - маркер
         */
        onSelectMarker(marker = {}) {
            const {extraData = {}, state = 0} = marker;
            const {sideIds = [], kitId = '-1'} = extraData;
            if(kitId == '-2'){
                return false;
            }
            const postData = {selected: sideIds};
            this.postTableDataRowFound(postData, (rows) => {
                const {pricesReduced = [], rowIds = []} = this.afterPostTableDataRowFound(rows);
                const action = state === 0 ? 'add' : 'remove';
                const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, action, ids: pricesReduced};
                this.postSyncDataCustom(postData, () => {
                    const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, selected: rowIds};
                    this.postTableDataRow(postData, (rows, kitItem) => {
                        this.updateKit(kitItem);
                        if(rows.length === 0) {
                            rowIds.forEach(rowId => {
                                this.afterPostTableDataRow(rows, action, rowId);
                            });
                        }
                        else {
                            this.afterPostTableDataRow(rows, action, '-1');
                        }
                        const sideIdsGlobal = this.cardData.map(card => String(card.id));
                        let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                        const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                        this.updateCardMarkersSelected(sideIdsGlobal, tableRowsPricesIdsSelected);
                    });
                });
            });
        },
        /**
         * Событие правого клика по маркеру
         * @param {Object} marker - маркеры
         */
        onSelectMarkerRight(marker = {}) {
            const {id = '-1', extraData = {}} = marker;
            const {kitId = '-1', sideIds = []} = extraData;
            if(kitId == '-2'){
                const postData = {marker_id: id}
                this.postMarkerCatalogData(postData, (rows) => {
                    // console.log(rows);
                    this.isCardVisible = true;
                    this.cardData = rows;
                    this.cardMarker = marker;
                    this.cardMarkerSelected = {};
                });
            }else {
                let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                let tableRowPricesIdsSelected = Object.assign({}, tableRowsPricesIdsSelected);
                Object.keys(tableRowsPricesIdsSelected).forEach(rowId => {
                    if (!sideIds.includes(String(rowId)))
                        delete tableRowPricesIdsSelected[rowId];
                });
                const postData = {marker_id: id}
                this.postMarkerData(postData, (rows) => {
                    this.isCardVisible = true;
                    this.cardData = rows;
                    this.cardMarker = marker;
                    this.cardMarkerSelected = tableRowPricesIdsSelected;
                });
            }

        },
        /**
         * Выбор цены в карточке
         * @param {String} priceId - id цены
         * @param {String} sideId - id стороны
         */
        onCheckPrice(priceId = '-1', sideId = '-1') {
            const {extraData = {}} = this.cardMarker;
            // const {kitId = '-1', sideIds = []} = extraData;
            const {kitId = '-1'} = extraData;
            const sideIds = this.cardData.map(card => String(card.id));
            const {pricesReduced = [], rowIds = []} = this.afterPostTableDataRowFound(this.cardData);
            const pricesFiltered = pricesReduced.filter(id => String(id) === String(priceId));
            let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
            const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
            const tableRowPricesIdsSelected = tableRowsPricesIdsSelected[sideId] || [];
            const action = !tableRowPricesIdsSelected.includes(String(priceId)) ? 'add' : 'remove';
            const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, action, ids: pricesFiltered};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, selected: rowIds};
                this.postTableDataRow(postData, (_, kitItem) => {
                    this.updateKit(kitItem);
                });
                let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                this.$refs.layoutFolder[index].updateDataCustomKit(sideIds, (sideIds, tableRowsPricesIdsSelected) => {
                    this.updateCardMarkersSelected(sideIds, tableRowsPricesIdsSelected);
                });
            });
        },
        /**
         * Обновить выбранные цены в карточке
         * @param {Array} sideIds - стороны
         * @param {Object} tableRowsPricesIdsSelected - выбранные цены в строке
         */
        updateCardMarkersSelected(sideIds = [], tableRowsPricesIdsSelected = {}) {
            // eslint-disable-next-line no-debugger
            // debugger;
            let tableRowPricesIdsSelected = {};
            Object.keys(tableRowsPricesIdsSelected).forEach(rowId => {
                if (sideIds.includes(String(rowId)))
                    tableRowPricesIdsSelected[rowId] = tableRowsPricesIdsSelected[rowId];
            });
            this.cardMarkerSelected = tableRowPricesIdsSelected;
        },
        onRemoveFolder(id) {
            // console.log(id);
            this.postRemoveKit(id, this.afterPostRemoveKit)
        },
        onExportFolder(id) {
            this.postExportKit(id, this.afterPostExportKit)
        },
        onMoveToBudget(id){
            this.$router.push({path: '/price?setId=' + id});
        },
        /**
         * Получить от сервера статусы маркеров, изменить формат данных для передачи в LegendPanel
         * @param {Array} statuses - Статусы маркеров
         */
        setMarkersStatuses(statuses) {
            const markersStatuses = [];
            const keysStatuses = Object.keys(statuses);
            const valuesStatuses = Object.values(statuses);
            const findColor = (key) => {
                const markerItem = this.markers.find((el) => el.status === key);
                if (markerItem !== undefined) {
                    return String(markerItem.color_legend);
                }
                return '';
            }

            keysStatuses.forEach((key, index) => {
                markersStatuses.push({
                    key,
                    value: valuesStatuses[index],
                    color: findColor(key),
                });
            });
            const allKeysStatuses = keysStatuses;
            allKeysStatuses.push('without_price');
            if (this.filterSelectedData?.without_prices) {
                markersStatuses.push({
                    color:'#848484',
                    key: 'without_price',
                    value:'Без цены',
                });
            }
            this.markersStatuses = markersStatuses;
            this.setMarkersStatusesListDefault(markersStatuses);
            if (this.allActiveLegendPanelMarkersStatuses.length === 0) {
                this.setAllActiveMarkerStatuses([...allKeysStatuses]);
            }
            this.setActiveMarkerStatuses(allKeysStatuses);
        },
        /**
         * Отобразить маркеры по выбранным статусам в легенде
         */
        onChangeMarkers() {
            if (this.activeLegendPanelMarkersStatuses.length !== 0) {
                this.clearMarkersFound(this.kitFoundId);

                this.$refs.mapView.updateMarkers((marker) => {
                    const { extraData = {} } = marker;
                    const { kitId: markerKitId = '-1', status = '', type = 'default' } = extraData;

                    if (type !== 'poi') {
                        if ((String(markerKitId) === '0' || String(markerKitId) === '-2')
                            && this.activeLegendPanelMarkersStatuses.includes(status)) {
                            marker.drawMarker();
                        }
                        if (String(markerKitId) === '-1') {
                            marker.clearMarker();
                        }
                    }
                });

            } else {
                this.$refs.mapView.updateMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {kitId: markerKitId = '-1', type = 'default'} = extraData;

                    if (type !== 'poi') {
                        if (String(markerKitId) === '0' || String(markerKitId) === '-2') {
                            marker.clearMarker();
                        }
                    }
                });
            }
        },
        onClickCell(data){
            let foundMarkers = this.$refs.mapView.$refs.mapWrapper.serviceMap.markers.filter((marker) => {
                const {id = '-2', extraData = {}} = marker;
                const {kitId = "0"} = extraData;
                return String(id) === data.row.marker_id && kitId === data.kitId;
            });
            if(foundMarkers.length && data.attribute === 'address'){
                this.$refs.mapView.$refs.mapWrapper.serviceMap.setMarkerClicked(foundMarkers[0]);
                this.onSelectMarkerRight(foundMarkers[0]);
            }
            if(foundMarkers.length && data.attribute === 'code'){
                this.onFocusMarker(foundMarkers[0]);
            }
        },
        onFocusMarker(marker){
            this.$refs.mapView.$refs.mapWrapper.serviceMap.setMarkerClicked(marker, true);
        },
        /**
         * Показать/скрыть все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         * @param {string} groupId - key группы
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleGroupPoi(dataPoi = [], groupId = '', isRender = true) {
            const dublicatePoiIndex = this.markersPoi.findIndex((el) => el.key === groupId);

            if (isRender && dublicatePoiIndex === -1) {
                this.markersPoi.push(...dataPoi);
            }
            if (isRender) {
                this.$refs.mapView.redrawMarkers(() => {}, dataPoi, true);
            }
            if (!isRender) {
                this.$refs.mapView.updateMarkers((marker) => {
                    const { extraData } = marker;
                    const { type = 'default', groupKey = '' } = extraData;
                    if (type === 'poi' && groupKey === groupId) {
                        marker.clearMarker();
                    }
                });
            }

        },
        /**
         * Показать/скрыть все POI на карте
         * @param {Array} dataPoi - Список POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        togglePoiAll(dataPoi = [], isRender = true) {
            if (isRender) {
                this.markersPoi = dataPoi;
                this.$refs.mapView.redrawMarkers(() => {}, dataPoi, true);
            } else {
                this.$refs.mapView.updateMarkers((marker) => {
                    const { extraData } = marker;
                    const { type = 'default' } = extraData;
                    if (type === 'poi') {
                        marker.clearMarker();
                    }
                });
            }
        },
        /**
         * Показать/скрыть одну метку POI на карте
         * @param {String} poiId - id POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleOnePoi(poiId, isRender) {
            this.$refs.mapView.updateMarkers((marker) => {
                const { extraData } = marker;
                const { type = 'default' } = extraData;
                if (type === 'poi' && isRender && marker.id === poiId) {
                    marker.drawMarker();
                }
                if (type === 'poi' && !isRender && marker.id === poiId) {
                    marker.clearMarker();
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .page-bayuerdesk {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        //padding: 5px;
        &__loading {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99999;
            background: #fff;
            padding: 0px 40px 40px 40px;
            &-enter-active, &-leave-active {
                transition: opacity 300ms;
            }
            &-enter, &-leave-to {
                opacity: 0;
            }
        }
        &-map {
            flex-grow: 1;
            width: 100%;
            height: 100px;
            //margin-top: 10px;
            position: relative;
            //border-radius: 10px;

            &__wrapper {
                overflow: hidden;
                border-radius: inherit;
                height: 100%;
                width: 100%;
            }

            &__construction-card {
                position: absolute;
                right: 10px;
                top: 10px;
                max-height: calc(100% - 20px);
                // max-height: 500px;
                z-index: 40;
            }
        }
    }
</style>
