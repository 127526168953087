<template>
	<DraggbleWindow
		v-show="isOpenFolder"
		class="folder"
		:style="getRootStyles"
		:hasPanel="true"
		:title="title"
        :isMinimize="isMinimize"
        :isRename="isRename"
        :color="color"
		@mousedown.native="bringToFront(id)"
		@onMinimized="collapseFolder(id)"
		@onClose="closeFolder(id)"
        @onRenameWindow="onRenameFolder"
	>
        <template v-slot:panel>
            <slot name="panel" />
        </template>
		<template v-slot:summary>
            <slot name="summary" />
        </template>
        <template v-slot:content>
            <div class="folder__content">
                <slot name="content" />
            </div>
        </template>
	</DraggbleWindow>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DraggbleWindow from '../DraggbleWindow'

export default {
	name: "Folder",
	components: {
		DraggbleWindow
	},
	props: {
		id: {
			type: [String, Number],
			required: true
		},
		title: {
			type: String,
			default: "Folder"
		},
		width: {
			type: Number,
			default: 500
		},
		height: {
			type: Number,
			default: 300
		},
		top: {
			type: Number,
			default: 10
		},
		left: {
			type: Number,
			default: 10
		},
        isMinimize: {
            type: Boolean,
            default: true
        },
        isRename: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        }
	},
	computed: {
		...mapGetters('folders', ['expandedFolders', 'folderQueue']),
		getRootStyles() {
			return {
				width: this.width + 'px',
				height: this.height + 'px',
				top: this.top + 'px',
				left: this.left + 'px',
				zIndex: 2 + this.folderQueue.indexOf(this.id),
			}
		},
		/**
		 * @returns {Boolean} является ли папка открытой
		 */
		isOpenFolder() {
			return this.expandedFolders.findIndex(item => item.id === this.id) != -1
		},
	},
	mounted() {
		this.$emit('mounted')
	},
	methods: {
		...mapMutations('folders', [
			'closeFolder',
			'bringToFront',
			'collapseFolder',
			// 'setFolderData'
		]),
        /**
         * Событие переименования папки 
         * @param {String} newName - новое имя
         */
        onRenameFolder(newName = '') {
            this.$emit('onRenameFolder', this.id, newName);
        }
	}
}
</script>

<style lang="scss" scoped>
.folder {
	box-shadow: 9px 19px 40px #0000001C;
	border-radius: 10px;
	z-index: 1000;

	&__content {
		height: 100%;
		width: 100%;
		overflow: hidden;
        background: #fff;
	}
}
</style>