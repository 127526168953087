<template>
	<div class="filter-item">
        <div v-if="isLoading" class="filter-item__preloader">
            <base-preloader-gif-v2 />
        </div>
		<div class="filter-item__wrapper">
			<div class="filter-item__title">{{ title }}</div>
			<button
				class="sign"
				@click="onTransfer"
			>
				<BaseIcon name="minus" class="sign__icon" />
			</button>

			<div class="options">
				<div
					v-for="(option, index) of options"
					:key="index"
				>
					<button
						class="option"
						:class="getOptionClasses(option)"
						@click="onCheck(option)"
					>
						{{ option.value }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseSvgIcon"
import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2"
export default {
	name: "FilterItem",
	components: {
		BaseIcon,
        BasePreloaderGifV2
	},
	props: {
		id: [Number, String],
		title: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default: () => []
		},
		selected: {
			type: Array,
			default: () => []
		},
        isLoading: {
			type: Boolean,
			default: false
		},
	},
	methods: {
		onCheck(optionData) {
			const body = {
				filterItemId: this.id,
				optionId: optionData.id
			}

			this.$emit('check', body)
		},
		/**
		 * @param {Object} optionData - объект с данными опции
		 * @returns {Object} - классы для опций
		 */
		getOptionClasses(optionData) {
			const {id, canCheck} = optionData
			return {
				'option_active': this.selected.includes(id),
				'option_disabled': !canCheck
			}
		},
		onTransfer() {
			this.$emit('transfer', this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;
$primary-color: #F0F3F8 !default;
$primary-lighten-color: #F9FAFB !default;

.filter-item {
	height: 250px;
	background: #EFF1F4;
	padding: 3px;
	border-radius: 10px;
	// box-shadow: 0 0 0px 3px #EFF1F4;
    position: relative;
    &__preloader {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: white;
        top: 0;
        left: 0;
        opacity: 0.7;
    }

	&__wrapper {
		height: 100%;
		position: relative;
		width: 100%;
		background: #fff;
		padding: 15px;
		border-radius: inherit;
		border: 1px solid #DCE1E8;
		overflow: auto;
	}

	&:hover .sign__icon {
		opacity: 1;
	}

	&__title {
		white-space: nowrap;
		cursor: default;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		flex-grow: 1;
		font-weight: 500;
		border-bottom: 1px solid #DCE1E8;
		padding-bottom: 6px;
	}

	.options {
		display: flex;
		flex-direction: column;
	}

	.option {
		display: inline-block;
		font-size: 12px;
		margin-top: 6px;
		padding: 5px 10px;
		border-radius: 300px;
		cursor: pointer;
		background: $primary-color;
		transition: .2s;
		text-align: left;

		&:hover:not(.option_active) {
			// background: #DCE1E8;
			background: $primary-lighten-color;
		}

		&_active {
			background: $active-color;
			color: #fff !important;
		}

		&_disabled {
			color: #ccc;
		}
	}

	.sign {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: $active-color;
		width: 34px;
		height: 34px;
		position: absolute;
		top: 5px;
		right: 0px;

		&__icon {
			width: 10px;
			transition: .2s;
			opacity: 0;
		}
	}
}
</style>