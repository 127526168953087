<template>
	<ConstructionCardFree
        :selectedData="selectedData"
		:selectedSideId="selectedSideId"
		:isLoad="isLoad"
		:sides="sidesAdapter"
		:prices="prices"
		:address="address"
		:city="city"
		:material="material"
		:number="number"
		:side="side"
		:view="view"
		:gid="gid"
		:type="type"
		:format="format"
		:direction="direction"
		:supplier="supplier"
		:photoSmall="photoSmall"
		:photoBig="photoBig"
		:supplierLink="supplierLink"
		:lat="lat"
		:lng="lng"
		:ots="ots"
		:grp="grp"
		:light="light"
		:lastUpdate="lastUpdate"
		:timeStudy="timeStudy"
		:videoDuration="videoDuration"
		:videoBlockClients="videoBlockClients"
		:moveDirection="moveDirection"
		@googlePanorama="onGooglePanorama"
		@close="onClose"
		@checkSide="onCheckSide"
        @onCheckPrice="onCheckPrice"
	/>
</template>

<script>
import ConstructionCardFree from '@/components/ConstructionCard/ConstructionCardFree/ConstructionCard'
import cutPrice from "@/filters/toK"

export default {
	name: "BayuerdeskCard",
	components: {
		ConstructionCardFree
	},
	props: {
		isLoad: {
			type: Boolean,
			default: false
		},
		sides: {
			type: Array,
			default: () => []
		},
        selectedData: {
            type: Object,
            default: () => ({})
        }
	},
	data() {
		return {
			selectedSideId: 0
		}
	},
	computed: {
		constructionData() {
			return this.sides.find(side => side.id === this.selectedSideId)
		},
		lastUpdate() {
			if (!this.constructionData?.updated_at) { return }
			const date = new Date(this.constructionData?.updated_at * 1000)
			const day = date.getDate()
			const month = date.getMonth()
			const year = date.getFullYear()
			const dateObject = {day, month, year}

			return dateObject
		},
		sidesAdapter() {
			let result = []
			const sidesObj = {}

			this.sides.forEach(side => {
				sidesObj[side.group_name] = {
					id: side.id,
					sideName: side.group_name
				}
			})

			for (let key in sidesObj) {
				result.push(sidesObj[key])
			}

			result.sort((a, b) => a.sideName > b.sideName ? 1 : -1);

			return result
		},
		prices() {
			if (!this.sides) { return }

			const result = []

			this.sides.forEach(side => {
				const sideId = side.id
				const sideName = side.code

				const pricesData = side.prices;

				if (typeof pricesData !== 'undefined'){
                    const filteredPricesData = pricesData.map(item => {
                        const month = Number(item.date.split('-')[1])
                        const price = cutPrice(item.price)
                        const id = item.id
                        const status = item.status

                        return { id, price, month, status }
                    })
                    result.push({
                        sideId,
                        sideName,
                        prices: filteredPricesData
                    })
                }
			})

			result.sort((a, b) => a.sideName > b.sideName ? 1 : -1);
			return result
		},
		address() {
			return this.constructionData?.address
		},
		city() {
			return this.constructionData?.city_id
		},
		material() {
			return this.constructionData?.material
		},
		number() {
			return this.constructionData?.oohdesk_id
		},
		side() {
			return this.constructionData?.code
		},
		view() {
			return this.constructionData?.kind_id
		},
		gid() {
			return this.constructionData?.gid
		},
		type() {
			return this.constructionData?.type_id
		},
		format() {
			return this.constructionData?.format_id
		},
		direction() {
			return this.constructionData?.direction
		},
		supplier() {
			return this.constructionData?.supplier_id
		},
		photoSmall() {
			return this.constructionData?.photo?.middle
		},
		photoBig() {
			return this.constructionData?.photo?.big
		},
		supplierLink() {
			return this.constructionData?.image_src
		},
		lat() {
			return this.constructionData?.lat
		},
		lng() {
			return this.constructionData?.lng
		},
		ots() {
			return this.constructionData?.ots
		},
		grp() {
			return this.constructionData?.grp
		},
		light() {
			return this.constructionData?.light
		},
		timeStudy() {
			return Number(this.constructionData?.video_time)
		},
		videoDuration() {
			return Number(this.constructionData?.video_block)
		},
		videoBlockClients() {
			return Number(this.constructionData?.video_block_clients)
		},
		moveDirection() {
			return this.constructionData?.move_direction
		}
	},
	watch: {
		sides() {
			this.selectedSideId = this.sidesAdapter[0]?.id
		}
	},
	mounted() {
		this.selectedSideId = this.sidesAdapter[0]?.id
	},
	methods: {
		onGooglePanorama(lat = null, lng = null) {
            this.$emit('onPanoramaGoogle', lat, lng);
		},
		onClose() {
			this.$emit('close')
		},
        onCheckSide(sideId) {
			this.selectedSideId = sideId
        },
        onCheckPrice(priceId = '-1', sideId = '-1') {
            this.$emit('onCheckPrice', priceId, sideId);
        }
	}
}
</script>

<style lang="scss" scoped></style>
