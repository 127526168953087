<template>
	<div
        ref="table"
        class="table"
        @scroll="onScroll"
    >
        <div
            ref="header"
            class="header"
        >
            <!-- Заголовки таблицы -->
            <div  v-if="columns.length !== 0" class="row titles">
                <div v-if="canCheck" class="cell cell-checkbox">
                    <label v-show="canCheckAll && columns.length > 0">
                        <input
                            type="checkbox"
                            v-model="checkAll"
                            @change="onCheckAll"
                        >
                        <span class="checkmark" :style="getStyleCheckboxAll()" />
                    </label>
                </div>
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    @click="onSort(column)"
                >
                    <div class="cell__content">
                        <span>{{column.label}}</span>
                        <svg
                            v-show="column.prop === sortedKey"
                            class="cell__arrow"
                            :class="{'cell__arrow_rotate': isReverse}"
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.918"
                            height="7.918"
                            viewBox="0 0 7.918 7.918"
                            fill="currentColor"
                        ><g id="Next" transform="translate(7.918) rotate(90)"><path id="path" d="M3.959,0,3.266.693,6.038,3.464H0v.99H6.038L3.266,7.226l.693.693L7.918,3.959Z" transform="translate(0 0)" fill-rule="evenodd"/></g></svg>
                    </div>
                </div>
            </div>
            <!-- Заголовки таблицы -->
            <!-- Строка поиска -->
            <div
                v-if="hasSearchRow && columns.length !== 0"
                class="row search"
            >
                <div v-if="canCheck" class="cell cell-checkbox"></div>
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column)"
                    class="cell cell-search"
                    :class="getCellClasses(column.prop, column.classes)"
                >
                    <!--TODO: сделал фильтрацию таблицы по событию ленивого кода, убрал событие обработки клика по энтеру-->
                    <BaseInput
                        v-if="column.canSearch"
                        v-model="searchValues[column.prop]"
                        class="cell__search-input"
                        :canClear="true"
                        @onInputLazy="updateSearchValues"
                    />
                </div>
            </div>
            <!-- Строка поиска -->
        </div>
        <ProgressBar
            :isLoading="isProgressBar"
            class="table__progressbar"
            ref="progressbar"
        />

        <div class="body">
            <div
                v-if="rows.length === 0 && !isProgressBar"
                class="body__empty"
            >
                Ничего не найдено
            </div>
            <div
                v-for="(row, posY) of rows"
                :key="posY"
                :data-rowid="posY"
                class="row"
                :class="{'row__no-margin': !isRowMargin}"
            >
                <div v-if="canCheck" class="cell cell-checkbox" :style="cellStyleRow(row)">
                    <label>
                        <!--Добавил событие выделения для конкретной строки-->
                        <input
                            type="checkbox"
                            :value="row[primaryKey]"
                            v-model="selectedCheckboxes"
                            @change="onCheckRow({row})"
                        >
                        <span class="checkmark" :style="getStyleCheckbox(row)"/>
                    </label>
                    <!-- Вынес чекбокс в компонент. Пример использования -->
                    <!-- <base-checkbox-v2
                        :value="row[primaryKey]"
                        :isPartChecked="isRowSelectedPart(row[primaryKey])"
                        v-model="selectedCheckboxes"
                        @change.native="onCheckRow({row})"
                    /> -->
                </div>

                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column, row)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    @click="onClickCell(column, row)"
                >
                    <slot
                        v-if="column.isSlot"
                        :name="column.prop"
                        :cell="row[column.prop]"
                        :row="row"
                        :column="column"
                    />
                    <div
                        v-else
                        class="cell__content"
                    >{{row[column.prop]}}</div>
                </div>
            </div>

            <!-- <div
                v-if="isLoading && isProgressBar"
                class="table__preloader"
                ref="preloader"
            >
                <BasePreloader :size="30" />
            </div> -->
        </div>
	</div>
</template>

<script>
// import BaseCheckboxV2 from "../Base/BaseCheckboxV2";
// import BasePreloader from "../Base/BasePreloader"
import BaseInput from '../Base/BaseTextInput'
import ProgressBar from '../Base/ProgressBar'

import lazyload from './lazyload'
import search from './search'
import sort from './sort'
import checkRow from './checkRow'

export default {
    name: "Table",
    props: {
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            // required: true
            default: () => []
        },
        rowsStyles: {
            type: Object,
            default: () => ({})
        },
        isProgressBar: {
            type: Boolean,
            default: false
        },
        primaryKey: {
            type: String,
            required: true
        },
        canCheck: {
            type: Boolean,
            default: false
        },
        canCheckAll: {
            type: Boolean,
            default: true
        },
        isRowMargin: {
            type: Boolean,
            default: true
        }
    },
    mixins: [lazyload, search, sort, checkRow],
    components: {
        // BaseCheckboxV2,
        // BasePreloader,
        BaseInput,
        ProgressBar
    },
    mounted() {
        /* задаю paddingTop тк header - position: absolute */
        // const headerHeight = this.$refs.header.offsetHeight + 'px'
        // this.$refs.table.style.paddingTop = headerHeight
    },
    methods: {
        getStyleCheckboxAll() {
            const lengthSelected = this.selectedCheckboxes.length;
            const lengthRows = this.rows.length;
            if (lengthSelected !== 0 && lengthRows !== 0 && lengthSelected < lengthRows && lengthSelected > 0)
                return {'background-image': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5.995' cy='5.995' r='5.995' fill='%23fff'/%3e%3c/svg%3e"`};
            else
                return {};

        },
        onScroll(event) {
            const leftIndent = this.$refs.table.scrollLeft
            const topIndent = this.$refs.table.scrollTop
            /* Двигаю прогрессбар относительно горизонтального скролла */
            if (this.$refs.progressbar.$el.tagName) {
                this.$refs.progressbar.$el.style.left = leftIndent + 'px'
                this.$refs.progressbar.$el.style.top = topIndent + this.$refs.header.offsetHeight + 'px'
            }

            /* Двигаю прелоадер */
            if (this.$refs.preloader)
                this.$refs.preloader.style.left = leftIndent + 'px'

            /* Плавующий header */
            // this.$refs.header.style.top = topIndent + 'px'
            this.$emit('onScroll');
            this.onLazyLoad(event)
        },
        onClickCell(column, row){
            if(column.isClickEmit){
                this.$emit('onClickCell', {attribute: column.prop, row});
            }
        },
        onCloseSelect() {
            this.$emit('$closeSelect');
        },
        cellStyles(culumnSettings, row = {}) {
            const rowStyle = this.cellStyleRow(row);
            if(culumnSettings.isClickEmit){
                rowStyle['cursor'] = 'pointer';
            }
            return { ...culumnSettings.columnStyles, ...rowStyle};
        },
        cellStyleRow(row = {}) {
            const rowId = String(row[this.primaryKey]);
            const rowStyle = this.rowsStyles[rowId] || {};
            return rowStyle;
        },
        getCellClasses(propName, classes) {
            const result = [
                {'cell-selected': propName === this.sortedKey},
            ]

            if (classes) {
                if (Array.isArray(classes)) {
                    result.push(...classes)
                } else {
                    result.push(classes)
                }
            }

            return result
        }
    }
}
</script>

<style lang="scss" scoped>
$rowBorderRadius: 6px;
$active-color: #4A92F6 !default;

.table {
    background: #fff;
    font-size: 12px;
    position: relative;
    max-width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &__progressbar {
        position: absolute;
        z-index: 1;
    }

    &__preloader {
        position: absolute;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        position: sticky;
        position:-webkit-sticky;
        top: 0px;
        z-index: 3;
        min-width: 100%;
        box-shadow: 0 2px 5px 0 rgba(0,0,0, .03);
        border-bottom-left-radius: $rowBorderRadius;
        border-bottom-right-radius: $rowBorderRadius;

        .cell {
            background: #F9FAFB;
            cursor: pointer;
            color: #BEC0C7;
            font-weight: 700;
            transition: .2s;

            &-selected {
                background: #FFF !important;
                color: #000;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            &__arrow {
                transition: .2s;
                &_rotate {
                    transform: rotate(180deg);
                }
            }
        }

        .cell-search {
            padding-top: 0px;

            input {
                max-width: 100%;
            }
        }
    }

	.body {
        background: #fff;
        &__empty {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }
        .row {
            border-radius: $rowBorderRadius;
            margin-bottom: 4px;
            transition: .25s;

            .cell {
                background: #F9FAFB;
                transition: .25s;
            }

            &:hover {
                .cell {
                    background: #FFF !important;
                }
            }
            &__no-margin {
                margin-bottom: 0px;
            }
        }
    }

    .row {
        display: flex;
    }

	.cell {
        position: relative;
        background: #fff;
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        min-width: 50px;
        padding: 7px 15px;
        display: flex;
        align-items: center;

        &-selected {
            background: #FFF !important;
        }

        &-checkbox {
            width: 54px;

            label {
                cursor: pointer;

                input {
                    display: none;

                    &:checked + span {
                        border-color: $active-color;
                        background-color: $active-color;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        background-size: 60%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                span {
                    width: 24px;
                    height: 24px;
                    border: 2px solid;
                    border-color: #ccc;
                    display: block;
                    border-radius: 4px;
                    transition: border-color 250ms;

                    &:hover {
                        border-color: $active-color;
                    }
                }
            }
        }
    }
}
</style>
