<template>
	<div class="mini-table">
		<div
			v-for="(item, index) of tableData"
			:key="index"
			class="row"
		>
			<div v-html="item.key" class="row__key" />
			<div v-html="item.value" class="row__value" />
		</div>
	</div>
</template>

<script>
export default {
	name: "MiniTAble",
	props: {
		tableData: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.mini-table {
	.row {
		display: flex;
		justify-content: space-between;
		font-size: 10px;
		padding-bottom: 5px;
		color: $assistant-color;

		&:not(:last-child) {
			border-bottom: 1px solid $primary-color;
			margin-bottom: 8px;
		}

		&__value {
			text-align: right;
		}
	}
}
</style>