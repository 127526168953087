import {mapGetters, mapMutations} from 'vuex';
export default {
    data: () => ({
        kitsFolderPosition: {}
    }),
    computed: {
        /** Проксируем геттеры из стора */
        ...mapGetters('folders', ['folders']),
        /**
         * Активированные папки
         * @returns {Array}
         */
        kitsFolderIdsActive() {
            const kitsFolderIdsActive = this.folders.map(folder => {
                const {id = '-1'} = folder;
                return String(id);
            });
            return kitsFolderIdsActive;
        },
    },
    methods: {
        /** Проксируем мутациииз стора */
        ...mapMutations('folders', ['openFolder', 'closeFolder', 'bringToFront']),
        /**
         * Переключить отображение папки
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        toggleFolder(id = '-1', type = '') {
            if (!this.kitsFolderIdsActive.includes(String(id))) {
                this.kitsFolderPosition = {
                    ...this.kitsFolderPosition,
                    [id]: {...this.calculatePositionFolder(type)}
                };
                this.openFolder(String(id));
            }
            else
                this.closeFolder(String(id));
        },
        /**
         * Вычислить позицию для таблицы открывающегося элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        calculatePositionFolder(type = '') {
            let position = {top: 0, left: 0};
            if (type === 'found')
                position = {top: Math.ceil(window.innerHeight * 0.2), left: 0};
            else if (type === 'custom') {
                const kitsTableActiveCustom = this.kitsFolderIdsActive.filter(kitId => String(kitId) !== String(this.kitFoundId));
                position = {top: 80 + kitsTableActiveCustom.length * 30, left: 0 + kitsTableActiveCustom.length * 30};
            }
            return position;
        },
        /**
         * Рассчитать ширину папки
         * @param {String} type - тип папки 
         * @returns {Number}
         */
        calculateFolderWidth(type = '') {
            const width = type === 'found' ? Math.ceil(window.innerWidth * 0.7) : Math.ceil(window.innerWidth * 0.5);
            return width;
        },
        /**
         * Рассчитать высоту папки
         * @param {String} type - тип папки 
         * @returns {Number} 
         */
        calculateFolderHeight(type = '') {
            const height = type === 'found' ? Math.ceil(window.innerHeight * 0.65) : 300;
            return height;
        },
        /**
         * Получить позицию для таблицы открывающегося элемента набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} position - top или left позиция
         */
        getPositionFolderKit(id = -1, position = '') {
            if(Object.keys(this.kitsFolderPosition).includes(String(id)))
                return this.kitsFolderPosition[id][position];
            else
                return 0;
        },
    }
};