<template>
    <map-wrapper
        ref="mapWrapper"
        :driver="driver"
        :renderDomElementId="renderDomElementId"
        :mapParams="mapParams"
        :markers="markers"
        :modeOptions="modeOptions"
        :modeIdSelected="modeIdSelected"
        :markers-statuses="markersStatuses"
        @onSelectMode="onSelectMode"
        @onSelectMarker="onSelectMarker"
        @onSelectMarkerRight="onSelectMarkerRight"
        @onAfterCreatePolygon="onAfterCreatePolygon"
        @activeLegendPanel="activeLegendPanel"
        @onToggleGroupPoi="onToggleGroupPoi"
        @onTogglePoiAll="onTogglePoiAll"
        @onToggleOnePoi="onToggleOnePoi"

    />
</template>

<script>
import MapWrapper from './components/MapWrapper';
export default {
    name: 'MapView',
    components: {MapWrapper},
    /**
     * Входные данные компонента
     * @property {Array} markers - маркеры
     * @property {Array} markersStatuses - список статусов маркеров для LegendPanel
     */
    props: {
        markers: {
            type: Array,
            default: () => ([])
        },
        markersStatuses: {
            type: Array,
            default: () => ([]),
        },
    },
    /**
     * Данные компонента
     * @property {String} driver - движок карты
     * @property {String} renderDomElementId - идентификатор дом элемента куда отрендерится карта
     * @property {Object} mapParams - параметры карты
     * @property {Array} modeOptions - опции для выбора режима
     * @property {String} modeIdSelected - выбранный режим
     */
    data: () => ({
        driver: 'google',
        renderDomElementId: 'map-wrapper',
        mapParams: {
            center: {lat: 55.755826, lng: 34.617300},
            zoom: 8,
        },
        modeOptions: [
            {
                id: 'selection',
                value: 'Выбор точки'
            },
            {
                id: 'massSelection',
                value: 'Массовый выбор точек'
            }
        ],
        modeIdSelected: 'selection'
    }),
    methods: {
        /**
         * Событие выбора режима
         * @param {String} id - идентификатор режима
         */
        onSelectMode(id = '') {
            this.modeIdSelected = String(id);
            if (this.modeIdSelected === 'selection')
                this.$refs.mapWrapper.destroyInitPolygon();
            else
                this.$refs.mapWrapper.initPolygon();
        },
        /**
         * Событие выбора маркера левой кнопкой мыши
         * @param {Object} marker - маркер
         */
        onSelectMarker(marker = {}) {
            this.$emit('onSelectMarker', marker);
        },
        /**
         * Событие выбора маркера правой кнопкой мыши
         * @param {Object} marker - маркер
         */
        onSelectMarkerRight(marker = {}) {
            this.$emit('onSelectMarkerRight', marker);
        },
        /**
         * Событие сработающее после создания полигона
         * @param {Object} polygon - полигон
         */
        onAfterCreatePolygon(polygon = {}) {
            this.$refs.mapWrapper.initPolygon();
            const markersContainsLocation = this.$refs.mapWrapper.containsLocation(polygon);
            this.$emit('onMassSelectMarkers', markersContainsLocation);
        },
        /**
         * Перерисовать маркеры
         * @param {Function} actionFilter - событие для фильтрации маркеров на карте
         * @param {Array} markers - маркеры
         * @param {Boolean} isRender - условие отрисовки
         */
        redrawMarkers(actionFilter = () => {}, markers = [], isRender = true, isUpdateClustering = true) {
            this.$refs.mapWrapper.redrawMarkers(actionFilter, markers, isRender, isUpdateClustering);
        },
        /**
         * Обновить маркеры
         * @param {Function} actionUpdate - функция обновления
         */
        updateMarkers(actionUpdate = () => {}, isUpdateClustering = true) {
            this.$refs.mapWrapper.updateMarkers(actionUpdate, isUpdateClustering);
        },
        /**
         * Включить панораму
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        setStreetView(lat = '', lng = '') {
            this.$refs.mapWrapper.setStreetView(lat, lng);
        },
        /**
         * Масштабировать карту
         */
        fitBounds() {
            this.$refs.mapWrapper.fitBounds();
        },
        /**
         * Передать родителю список активных статусов LegendPanel
         */
        activeLegendPanel() {
            this.$emit('activeLegendPanel');
        },
        /**
         * Показать/скрыть все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         * @param {string} groupId - key группы
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        onToggleGroupPoi(dataPoi = [], groupId = '', isRender = true) {
            this.$emit('onToggleGroupPoi', dataPoi, groupId, isRender);
        },
        /**
         * Показать/скрыть все POI на карте
         * @param {Array} dataPoi - Список POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        onTogglePoiAll(dataPoi = [], isRender = true) {
            this.$emit('onTogglePoiAll', dataPoi, isRender);
        },
        /**
         * Показать/скрыть одну метку POI на карте
         * @param {String} poiId - id POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        onToggleOnePoi(poiId, isRender) {
            this.$emit('onToggleOnePoi', poiId, isRender);
        },
    }
}
</script>
