import SchemePageBayuerdesk from '@/schemes/SchemePageBayuerdesk';
const {kitTableRowKeys, kitTableColumns, kitTableBudgetColumns} = SchemePageBayuerdesk;
export default {
    /**
     * @property {Object} tableSort - данные сортировки по столбцам
     * @property {Array} tableFilter - данные фильтрации по столбцам
     * @property {Number} tableFoundPerPage - размер страницы для пагинации
     * @property {Number} tableFoundPageNumber - номер страницы для пагинации
     * @property {Array} tableRows - строки
     * @property {Array} tableFoundRowKeys - нужные ключи из строк
     * @property {Array} tableColumns - колонки
     * @property {Array} tableColumnsBase - базовые колонки
     */
    data: () => ({
        tableSort: {column: '', type: ''},
        tableFilter: [],
        tableFoundPerPage: 20,
        tableFoundPageNumber: 1,
        tableRows: [],
        tableFoundRowKeys: kitTableRowKeys,
        tableColumns: [],
        tableColumnsBase: kitTableColumns,
    }),
    computed: {
        /**
         * Тело запроса для таблицы найдено
         * @returns {Object}
         */
        tableFoundRequestBody() {
            const tableFoundRequestBody = {
                pagination: {
                    per_page: parseInt(this.tableFoundPerPage),
                    page: parseInt(this.tableFoundPageNumber)
                },
                sort: this.tableSort,
                filter: this.tableFilter,
                filter_params: this.filterSelectedData
            };
            return tableFoundRequestBody;
        },
        /**
         * Колонки с датами
         * @returns {Array}
         */
        tableColumnsDates() {
            const tableColumnsDates = this.tableColumns.filter(column => {
                const {isDate = false} = column;
                return isDate === true;
            }).map(column => column.prop);
            return tableColumnsDates;
        },
        /**
         * Колонки модифицируемые (особенности таблицы)
         * @returns {Array}
         */
        tableColumnsModifered() {
            const tableColumnsModifered = this.tableColumns.filter(column => column.isModifiered === true).map(column => column.prop);
            return tableColumnsModifered;
		},
    },
    methods: {
        /**
         * Адаптер для строк
         * @param {Array} rows - строки
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterRows(rows = [], keyPrices = 'prices') {
            const rowsAdapted = rows.map(row => {
                const rowKeys = Object.keys(row);
                const rowKeysFiltered = rowKeys.filter(rowKey => this.tableFoundRowKeys.includes(rowKey));
                let rowAdapted = {};
                rowKeysFiltered.forEach(rowKey => {
                    if (rowKey !== keyPrices)
                        rowAdapted[rowKey] = row[rowKey];
                    else {
                        rowAdapted[rowKey] = row[rowKey];
                        const prices = row[keyPrices] || [];
                        prices.forEach(priceItem => {
                            const {date = '', price = 0, } = priceItem;
                            rowAdapted[date] = parseInt(price);
                        });
                    }
                });
                return rowAdapted;
            });
            return rowsAdapted;
        },
        /**
         * Адаптер для строк формирования бюджета
         * @param {Array} rows - строки
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterBudgetRows(rows = [], keyPrices = 'prices') {
            const rowsAdapted = rows.map(row => {
                const rowKeys = Object.keys(row);
                const rowKeysFiltered = rowKeys.filter(rowKey => this.tableFoundRowKeys.includes(rowKey));
                let rowAdapted = {};
                rowKeysFiltered.forEach(rowKey => {
                    if (rowKey !== keyPrices)
                        rowAdapted[rowKey] = row[rowKey];
                    else {
                        rowAdapted[rowKey] = row[rowKey];
                        const prices = row[keyPrices] || [];
                        prices.forEach(priceItem => {
                            const {date = '', price = 0, priceLinkSet = {}} = priceItem;
                            const {offer_price: offerPrice = 0} = priceLinkSet;
                            rowAdapted[date] = {price: parseInt(price), offer_price: parseInt(offerPrice)};
                        });
                    }
                });
                return rowAdapted;
            });
            return rowsAdapted;
        },
        /**
         * Адаптер для колонок с датами
         * @param {Array} rows
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterColumnsDates(rows = [], keyPrices = 'prices') {
            const dates = rows.map(row => {
                const prices = row[keyPrices] || [];
                const dates = prices.map(price => price.date);
                return dates;
            });
            const datesUnion = dates.reduce((datesUnion, dateCurrent) => {
                const datesDifference = dateCurrent.filter(date => !datesUnion.includes(String(date)));
                return [...datesUnion, ...datesDifference];
            }, []);
            datesUnion.sort();
            const datesAdapted = datesUnion.map(date => {
                const dateString = new Date(date).toLocaleString('ru', {year: 'numeric', month: 'long'});
                const dateStringArr = dateString.split(' ');
                const monthName = dateStringArr[0].slice(0, 1).toUpperCase() + dateStringArr[0].slice(1,3)
                const columnDate = {
                    label: `${monthName}`,
                    prop: date,
                    isDate: true,
                    isSlot: true,
                    columnStyles: {
                        width: '40px',
                        padding: '7px 2px',
                    },
                    classes: ['date-cell']
                };
                return columnDate;
            });
            return datesAdapted;
        },
        /**
         * Собрать массив для отрисовки колонок
         * @param {Array} columnsDatesAdapted - колонки после адаптера
         */
        collectColumns(columnsDatesAdapted = []) {
            const firstPart = this.tableColumnsBase.slice(0, 5);
            const secondPart = this.tableColumnsBase.slice(5);
            this.tableColumns = [...firstPart, ...columnsDatesAdapted, ...secondPart];
        },
        /**
         * Собрать массив для отрисовки колонок таблицы формирования бюджета
         * @param {Array} columnsDatesAdapted - колонки после адаптера
         */
        collectBudgetColumns(columnsDatesAdapted = []) {
            const firstPart = kitTableBudgetColumns.slice(0, 4);
            const secondPart = kitTableBudgetColumns.slice(4);
            this.tableColumns = [...firstPart, ...columnsDatesAdapted, ...secondPart];
        },
        /**
         * Событие сработающее после запроса на получение таблицы найдено
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {Array} rows - строки
         */
        afterPostTableData(rows = [], keyPrices = 'prices', isRerender = false) {
            const rowsAdapted = this.adapterRows(rows, keyPrices);
            const columnsDatesAdapted = this.adapterColumnsDates(rows, keyPrices);
            !isRerender ? this.tableRows.push(...rowsAdapted) : this.tableRows = rowsAdapted;
            this.collectColumns(columnsDatesAdapted);
        },
        /**
         * Событие сработающее после запроса на получение таблицы формирования бюджета
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {Array} rows - строки
         */
        afterPostTableBudgetData(rows = [], keyPrices = 'prices', isRerender = false) {
            const rowsAdapted = this.adapterBudgetRows(rows, keyPrices);
            const columnsDatesAdapted = this.adapterColumnsDates(rows, keyPrices);
            !isRerender ? this.tableRows.push(...rowsAdapted) : this.tableRows = rowsAdapted;
            this.collectBudgetColumns(columnsDatesAdapted);
        },
        /**
         * Событие сработающее после ленивой загруки найдено
         * @param {Array} rows - строки
         */
        afterLazyloadTableDataFound(rows = []) {
            const rowsAdapted = this.adapterRows(rows);
            this.tableRows.push(...rowsAdapted);
        },
        /**
         * Событие сработающее после запроса на получения данных для строк найдено
         * @param {Array} rows - строки
         * @returns {Object}
         */
        afterPostTableDataRowFound(rows = []) {
            const rowIds = rows.map(row => {
                const {id = -1} = row;
                return id;
            });
            const prices = rows.map(row => {
                const {prices = []} = row;
                return prices;
            });
            const pricesReduced = prices.reduce((pricesUnion, priceCurrent) => {
                const pricesDifference = priceCurrent.filter(price => !pricesUnion.includes(String(price.id)));
                return [...pricesUnion, ...pricesDifference.map(price => String(price.id))];
            }, []);
            return {rowIds, pricesReduced};
        },
        /**
         * Событие, сработающее перед фильтрацией по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        beforeFilterTable(dataFilter = {}) {
            const filter = Object.keys(dataFilter).map(columnKey => {
                const filterItem = {
                    column: columnKey,
                    values: [dataFilter[columnKey]]
                };
                return filterItem;
            });
            this.tableFilter = filter;
        },
        /**
         * Событие, сработающее перед сортировкой
         * @param {Object} dataSort - данные сортировки
         */
        beforeSortTable(dataSort = {}) {
            const {type = '', key = ''} = dataSort;
            this.tableSort = {column: key, type};
        },
        /**
         * Событие, сработающее до запроса на добавление/удаления цен
         * @param {Object} data - данные из таблицы набора
         * @param {Object} pricesSelected - выбранные цены
         * @param {String} kitId - индентификатор набора
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Object}
         */
        beforePostSyncDataCustom(data, pricesSelected, kitId, keyPrices = 'prices') {
            let {column = {}, row = {}} = data;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const prices = row[keyPrices] || [];
            const cellsChecked = pricesSelected[rowId] || [];
            const action = cellsChecked.includes(prop) ? 'remove' : 'add';
            const price = prices.find(price => price.date === prop);
            const {id: priceId = ''} = price;
            const priceIds = [priceId];
            const postData = {set_id: kitId, action, ids: priceIds};
            return {postData, rowId, action};
        }
    }
};
