<template>
    <div class="control-panel">
        <control-panel-item
            v-for="(panelItem, index) in selectControlPanelType"
            :key="index"
            :panelItem="panelItem"
            :isActive="getPanelItemActive(panelItem.id)"
            :is-active-checkbox="isActiveCheckbox"
            :isModule="isModule"
            class="control-panel__item"
            @onClickPanelItem="onClickPanelItem"
        />
    </div>
</template>

<script>
import ControlPanelItem from './ControlPanelItem.vue';
import SchemeControlPanel from '../../schemes/SchemeControlPanel';

export default {
    name: 'ControlPanel',
    components: { ControlPanelItem },
    /**
     * Входные данные компонента
     * @property {Array} controlPanelIdsActive - массив активных элементов панели контроллеров карты
     * @property {Boolean} isModule - флаг преобразует универсальный компонент для списка модулей
     */
    props: {
        isModule: {
            type: Boolean,
            default: false,
        },
        isActiveCheckbox: {
            type: Boolean,
            default: false,
        },
    },
    /**
     * Данные компоннета
     * @property {Array} controlPanel - элементы панели контроллеров карты
     * @property {Array} controlPanelIdsActiveBuff - буфферный массив активных элементов панели контроллеров карты
     */
    data: () => ({
        controlPanel: SchemeControlPanel,
        controlPanelIdsActiveBuff: [],
        controlPanelActive: '',
    }),
    watch: {
        /** Следим за изменением массив активных элементов панели контроллеров карты и обновляем буффер */
        controlPanelIdsActive: function() {
            this.controlPanelIdsActiveBuff = this.controlPanelIdsActive;
        }
    },
    computed: {
        /**
         * Следим за флагом модуля чтобы переключить данные.
         */
        selectControlPanelType() {
            if (this.isModule) {
                return this.controlPanel.controlPanelModule;
            }
            return this.controlPanel.controlPanel;
        },
    },

    methods: {
        /**
         * Получить активность элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {Boolean}
         */
        getPanelItemActive(panelItemId = '') {
            return this.controlPanelActive === panelItemId;
        },
        /**
         * Получить название эмита элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {String}
         */
        getPanelItemEmit(panelItemId = '') {
            const {emit = ''} = this.selectControlPanelType.find(panelItem => panelItem.id === panelItemId);
            return emit;
        },
        /**
         * Событие переключения активности элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         */
        onTogglePanelItemActive(panelItemId) {
            const active = this.getPanelItemActive(panelItemId);
            if (!active) {
                this.controlPanelActive = panelItemId;
            }
        },
        /**
         * Событие клик по элементу панели
         * @param {String} panelItemId - идентификатор элемента панели
         */
        onClickPanelItem(panelItemId = '') {
            if (this.controlPanelActive === panelItemId) {
                this.onTogglePanelItemActive('');
            } else {
                this.onTogglePanelItemActive(panelItemId);
            }
            const active = this.getPanelItemActive(panelItemId);
            const emit = this.getPanelItemEmit(panelItemId);
            this.$emit(emit, active);
        },

    }
}
</script>

<style lang="scss" scoped>
    .control-panel {
        display: flex;
        padding: 6px;
        border-radius: 10px;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 2px 3px 20px #3B4A741A;

        &--vertical {
            flex-direction: column;
        }
    }
</style>
