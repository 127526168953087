<template>
	<div class="header">
		<div class="sides">
			<button
				v-for="side of sides"
				:key="side.id"
				class="sides__item"
				:class="getSideClasses(side)"
				@click="onChangeSide(side)"
			>{{ side.sideName }}</button>
		</div>
		<div class="panorama">
			<div class="panorama__title">Панорамы:</div>
			<a :href="yandexLink" target="_blank" class="panorama__button">Яндекс</a>
			<button class="panorama__button" @click="onGoogle">Google</button>
		</div>

		<button
			class="header__close"
			@click="onClose"
		>
			<BaseIcon icon="icon-close" class="icon" />
		</button>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon"

export default {
	name: "Header",
	components: {
		BaseIcon
	},
	props: {
		sides: {
			type: Array,
			default: () => []
		},
		selectedSideId: {},
		lat: {},
		lng: {},
		direction: {},
	},
	computed: {
		yandexLink() {
			return `https://yandex.ru/maps/?panorama[point]=${this.lng},${this.lat}&panorama[direction]=${this.direction * 22.5}&panorama[span]=130.000000,71.919192`
		}
	},
	methods: {
		onGoogle() {
			this.$emit('google')
		},
		onClose() {
			this.$emit('close')
		},
		onChangeSide(sideData) {
			const {id} = sideData
			this.$emit('changeSide', id);
		},
		getSideClasses(sideData) {
			const {id} = sideData
			return {
				'sides__item_active': this.selectedSideId === id
			}
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.header {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-bottom: 15px;
	border-bottom: 1px solid $primary-color;

	&__close {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 10px;
		right: 10px;
		transition: .2s;

		&:hover .icon {
			color: red;
		}

		.icon {
			font-size: 12px;
			color: $assistant-color;
			font-weight: 300;
		}
	}

	.sides {
		display: flex;
		overflow-x: auto;

		&__item {
			flex-shrink: 0;
			width: 34px;
			height: 34px;
			cursor: pointer;
			background: $primary-color;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:not(:first-child) {
				margin-left: 5px;
			}

			&_active {
				color: #fff;
				background: $active-color;
			}
		}
	}

	.panorama {
		display: flex;
		margin-left: 60px;

		&__title {
			color: $assistant-color;
		}
		&__button {
			margin-left: 16px;
			color: $active-color;
		}
	}
}
</style>