<template>
    <div class="layout">
        <div class="layout__main">
            <AppCap
                class="layout__cap"
                :isHumburgerActive="isMenuOpen"
                :title="$route.meta.title || 'Oohdesk'"
                :hasLogo="true"
                view="transparent"
                @onHumburger="onHumburger"
                @onFilter="onFilter"
            >
                <FilterPanel
                    :chips="chips"
                    :cities="citiesList"
                    :selectedCities="selectedCities"
                    :accessDates="accessDates"
                    :checkedDates="checkedDates"
                    :isFilterOpen="isFilterOpen"
                    @toggle="onFilter"
                    @removeChips="onRemoveChips"
                    @checkCity="onCheckCity"
                    @approveDatepicker="onApproveDatepicker"
                />
            </AppCap>
            <div
                class="layout__content"
                :style="layoutContentStyle"
            >
                <AppMenu
                    v-show="isMenuOpen"
                    class="layout__menu"
                />
                <AppFilter
                    v-if="isFilterOpen"
                    :params="filterDataParams"
                    :selected="filterDataSelected"
                    :isLoading="isFilterLoading"
                    :panelData="filterPanelData"
                    :sidesNumber="filterSidesNumber"
                    class="layout__filter"
                    @check="onCheckFilter"
                    @transfer="onTransfer"
                    @close="onFilter"
                    @clear="onResetFilter"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import AppFilter from '@/components/Filter/Filter'
import AppCap from './components/Cap'
import AppMenu from './components/Menu'
import FilterPanel from './components/FilterPanel'
import ServiceApi from '../services/ServiceApi';
export default {
    name: 'DefaultLayout',
    components: {
        AppCap,
        AppMenu,
        AppFilter,
        FilterPanel
    },
    data() {
        return {
            isMenuOpen: false,
            isFilterOpen: false,
            citiesList: [],

            filterDataParams: [],
            filterDataSelected: {},
            filterDataAccessDates: [],
            isFilterLoading: false,
            filterSidesNumber: 0,
            withoutPricesStepCountData: 0,
            withoutPricesSideCountData: 0,
            filterPanelData: [
				{ value: 'through', title: 'Сквозное размещение' },
				{ value: 'without_prices', title: 'Стороны без цен' },
			],
        }
    },
    computed: {
        ...mapState(['currentComponent']),
        layoutContentStyle() {
            const layoutContentStyle = this.currentComponent === 'PageBuyerdesk' ? {'padding': '0px'} : {};
            return layoutContentStyle;
        },
        /**
         * Возвращает выделенные элементы
         * @returns {Array}
         */
        chips() {
            const result = []
            const selectedData = this.filterDataSelected
            const params = this.filterDataParams ?? []

            /* записывает в result, если есть выделенное значение */
            for (let propName in selectedData) {
                if (
                    Array.isArray(selectedData[propName]) &&
                    selectedData[propName].length > 0
                ) {
                    // TODO
                    if (propName == 'date') { continue }

                    const selectedValues = selectedData[propName]

                    selectedValues.forEach(id => {
                        const filterItem = params.find(item => item.id === propName)

                        if (filterItem) {
                            const optionItem = filterItem?.items.find(i => i.id === id)
                            const { value } = optionItem
                            result.push({ propName, value, id })
                        }
                    })
                }
            }

            return result
        },
        selectedCities() {
            let result = this.filterDataSelected['city_id'] || []
            result = result.map(i => String(i))
            return result
        },
        withoutPricesSideCount: {
            get() {
                return this.withoutPricesSideCountData
            },
            set(value) {
                this.withoutPricesSideCountData = value
            }
        },
        withoutPricesStepCount: {
            get() {
                return this.withoutPricesStepCountData
            },
            set(value) {
                this.withoutPricesStepCountData = value
            }
        },
        accessDates() {
            const result = []
            const dates = this.filterDataAccessDates || []

            dates.forEach(item => {
                const YY_MM_DD = item.split('-')
                const year = Number(YY_MM_DD[0])
                const month = Number(YY_MM_DD[1])

                result.push({ year, month })
            })

            return result
        },
        checkedDates() {
            const result = []
            const dates = this.filterDataSelected?.date || []

            dates.forEach(item => {
                const YY_MM_DD = item.split('-')
                const year = Number(YY_MM_DD[0])
                const month = Number(YY_MM_DD[1])

                result.push({ year, month })
            })

            return result
        }
    },
    watch: {
        filterDataSelected: function (filterDataSelected) {
            this.setFilterSelectedData(filterDataSelected);
        },
        withoutPricesStepCount: function (withoutPricesStepCount) {
            this.setWithoutPricesStepCount(withoutPricesStepCount);
        },
        withoutPricesSideCount: function (withoutPricesSideCount) {
            this.setWithoutPricesSideCount(withoutPricesSideCount);
        }
    },
    async created() {
        await this.fetchCities()
        await this.fetchFilterData()
    },
    methods: {
        ...mapMutations('filter', ['setFilterSelectedData', 'setWithoutPricesStepCount', 'setIsFilterLoading', 'setWithoutPricesSideCount']),
        async fetchFilterData(body = {}) {
            try {
                this.isFilterLoading = true
                if(!this.isFilterOpen){
                    this.setIsFilterLoading(true);
                }
                // let response = await this.$axios.post('https://free.oohdesk.ru/filter/filter/filter-base', {filter_params: body})
                let response = await ServiceApi.post('free', '/filter', {filter_params: body});
                response = response.data.data
                const params = response?.data?.params ?? []
                const selected = response?.selected_data ?? {}
                const accessDates = response?.data?.date ?? []

                for (let param of params) {
                    if (param.src) {
                        const response = await this.fetchFilterItemData(param.src)
                        const items = response.data.data
                        param.items = items
                    }
                }
                this.withoutPricesStepCount = response.without_prices_step_count;
                this.withoutPricesSideCount = Number(response.side_count)
                this.filterSidesNumber = Number(response.side_count)
                this.filterDataParams = params
                this.filterDataSelected = selected
                this.filterDataAccessDates = accessDates
            } catch(err) {
                console.log(err);
            } finally {
                this.isFilterLoading = false
                if(!this.isFilterOpen){
                    this.setIsFilterLoading(false);
                }
            }
        },
        async fetchFilterItemData(url) {
            try {
                // return await this.$axios.get('https://free.oohdesk.ru' + url)
                return await ServiceApi.get('free', String(url));
            } catch (err) {
                console.log(err);
            }
        },
        async fetchCities() {
            try {
                // let response = await this.$axios.get('https://free.oohdesk.ru/filter/filter/cities')
                let response = await ServiceApi.get('free', '/filter/cities');
                response = response.data.data

                const citiesList = response.map(item => {
                    const {id, name} = item
                    return {id, value: name}
                })

                this.citiesList = citiesList
            } catch(err) {
                console.log(err);
            }
        },
        onHumburger() {
            this.isMenuOpen = !this.isMenuOpen
            this.isFilterOpen = false
        },
        onFilter() {
            this.isFilterOpen = !this.isFilterOpen
            this.isMenuOpen = false
        },
        onCheckFilter(event) {
            const { filterItemId, value } = event
            this.filterDataSelected[filterItemId] = value

            this.fetchFilterData(this.filterDataSelected)
        },
        onTransfer(filterItemId) {
            const filterItem = this.filterDataParams.find(item => item.id === filterItemId)
            filterItem.hidden = !filterItem.hidden
            this.filterDataParams = this.filterDataParams.slice()
        },
        onResetFilter() {
            this.fetchFilterData({reset: "Y"})
        },
        /**
         * Удаляю тег из шапки
         * @param {Object} - данные об удаляемом тэге
         */
        onRemoveChips(chipsData) {
            const {propName, id} = chipsData
            const index = this.filterDataSelected[propName].findIndex(i => i === id)
            this.filterDataSelected[propName].splice(index, 1)

            this.fetchFilterData(this.filterDataSelected)
        },
        onCheckCity(event) {
            this.onCheckFilter(event)
        },
        onApproveDatepicker(event) {
            const result = event.map(period => {
                let month = String(period.month)
                month = month.length == 1 ? `0${month}` : month
                const year = period.year

                return `${year}-${month}-01`
            })

            const body = {
                filterItemId: 'date',
                value: result
            }

            this.onCheckFilter(body)
        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__cap {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        // z-index: 200;
    }

    &__content {
        position: relative;
        // z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff;
        // overflow: hidden;
        padding: 10px 40px 40px 40px;
    }

    &__menu {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 9999;
    }

    &__filter {
        padding: 40px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 9999;
        border-top: 1px solid #eee;
    }
}
</style>
