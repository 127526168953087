/* eslint-disable no-extra-semi */
import ServiceGoogleInfoWindow from '../../services/ServicesGoogle/ServiceGoogleInfoWindow';
import SchemeInfoWindow from '../../schemes/SchemeInfoWindow';
const {contents} = SchemeInfoWindow;
/** Фабрика для создания информационных окон через гугл мап апи */
export default class FactoryGoogleInfoWindow {
    /**
     * Создать информационное окно
     * @param {Object} infoWindowParams - параметры для информационного окна
     * @param {String} infoWindowKey - ключ для получения контента информационного окна
     * @returns {Object}
     */
    static createInfoWindow(infoWindowParams = {}, infoWindowKey = 'default') {
        const content = contents[infoWindowKey] || '';
        return new ServiceGoogleInfoWindow({...infoWindowParams, content});
    };
};