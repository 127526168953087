<template>
	<div class="map-image">
		<img
			:src="imgPath"
			class="map-image__min-image"
            :style="{cursor: isEditable ? 'pointer' : 'default'}"
            @click="onOpen"
		>

        <AppPopup
            v-if="isPopupOpen"
            @close="onClose"
        >
            <div class="map-image-container">
                <p class="map-image-container__title">Укажите направление стрелки от 0 до 15, где 0 - это север, 4 - запад, 8 - юг, 12 - восток.</p>
                <div class="map-image-body">
                    <div class="map-image-body__direction">
                        <BaseButton
                            @click="onDown"
                        >-</BaseButton>
                        <p class="map-image-body__value">{{this.value}}</p>
                        <BaseButton
                            :view="'secondary'"
                            @click="onUp"
                        >+</BaseButton>
                    </div>
                </div>
                <div class="map-image-buttons">
                    <BaseButton
                        @click="onClose"
                    >Отмена</BaseButton>
                    <BaseButton
                        :view="'secondary'"
                        @click="onChange"
                    >Сохранить</BaseButton>
                </div>
            </div>
        </AppPopup>
	</div>
</template>

<script>
import AppPopup from '@/components/Popup'
import BaseButton from "@/components/Base/BaseButton";

export default {
	name: "MapDirection",
	props: {
		item: {
			type: Object,
			required: true,
		},
        isEditable: {type: Boolean,
            required: true
        }
	},
	components: {
		AppPopup,
        BaseButton
	},
	data() {
		return {
			isPopupOpen: false,
            value: 0
        }
	},
	computed: {
		imgPath () {
            return `https://map.oohdesk.ru/map/draw/marker-direction?sides=a&colorA=3600ff&colorB=0f579e&direction=${this.item.direction}`
        },
	},
	methods: {
		onOpen() {this.value = Number(this.item.direction)
            this.isPopupOpen = true
		},
		onClose() {
			this.isPopupOpen = false
		},
        onUp() {
            this.value = this.value >= 15 ? 0 : (this.value + 1)
        },
        onDown() {
            this.value = this.value <= 0 ? 15 : (this.value - 1)
        },
        onChange() {
            this.$emit('change', { id:String(this.value) , content:String(this.value) });
            this.isPopupOpen = false
        }
    }
}
</script>

<style lang="scss" scoped>
.map-image {
	width: 30px;
	height: 30px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;

	&__min-image {
		width: 230px;
		display: block;
	}
}

.map-image-container{
    padding: 30px;
    background-color: white;

    &__title{
        max-width: 200px;
        padding-bottom: 15px;
    }
}

.map-image-body{
    padding-bottom: 10px;

    &__direction{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__value{
        font-size: 18px;
        padding: 8px 10px 0;
    }
}

.map-image-buttons{
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


</style>
