const SchemePageBayuerdesk = {
    kitPanelItems: [
        {
            id: '0',
            type: 'found',
            title: 'Найдено',
            description: '0',
            color: '#4A92F6'
        }
    ],
    kitColors: [
        '#000000',
        '#0000FF',
        '#FF00FF',
        '#808080',
        '#008000',
        '#00FF00',
        '#ffa500',
        '#000080',
        '#808000',
        '#800080',
        '#FF0000',
        '#C0C0C0',
        '#008080',
        '#FFFF00',
    ],
    kitTableColumns: [
        {
            prop: 'image_src',
            label: 'Фото',
            isSlot: true,
            columnStyles: {
                width: '80px'
            }
        },
        {
            prop: 'supplier_id',
            label: 'Оператор',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'gid',
            label: 'GID',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '80px'
            },
            classes: ['gid-cell']
        },
        {
            prop: 'address',
            label: 'Адрес',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isClickEmit: true,
            isModifiered: true,
            columnStyles: {
                width: '300px',
            }
        },
        {
            prop: 'code',
            label: 'Ст.',
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                textAlign: 'center',
                width: '50px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '110px'
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'format_id',
            label: 'Фор.',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px'
            }
        },
        {
            prop: 'ots',
            label: 'OTS',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px',
            }
        },
        {
            prop: 'grp',
            label: 'GRP',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px',
            }
        },
        {
            label: 'Направление',
            prop: 'move_direction',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['direction-cell']
        },
        {
            prop: 'light',
            label: 'Свет',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            columnStyles: {
                width: '65px'
            }
        },
        {
            label: 'Материал',
            prop: 'material_id',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true
        }
    ],
    kitTableBudgetColumns: [
        {
            prop: 'image_src',
            label: 'Фото',
            isSlot: true,
            columnStyles: {
                width: '80px'
            }
        },
        {
            prop: 'supplier_id',
            label: 'Оператор',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'address',
            label: 'Адрес',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '600px',
            }
        },
        {
            prop: 'code',
            label: 'Ст.',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                textAlign: 'center',
                width: '50px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '110px'
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            canSearch: true,
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'format_id',
            label: 'Фор.',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px'
            }
        },
        {
            prop: 'ots',
            label: 'OTS',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px',
            }
        },
        {
            prop: 'grp',
            label: 'GRP',
            canSorted: true,
            isSlot: true,
            isModifiered: true,
            columnStyles: {
                width: '60px',
            }
        }
    ],
    kitTableRowKeys: [
        'prices',
        'photo',
        'supplier_id',
        'gid',
        'address',
        'code',
        'type_id',
        'kind_id',
        'format_id',
        'ots',
        'grp',
        'material_id',
        'light',
        'move_direction',
        'allPrices',
        'id',
        'marker_id'
    ]
};
export default SchemePageBayuerdesk;
