<template>
    <div class="history">
        <BaseDropdown
            class="history-dropdown"
            :padding="0"
            @changeVisible="onHistoryDropdownChangeVisible"
        >
            <template #preview>
                <BaseButton
                    class="history__button"
                    view="border"
                    :active="isActiveHistory"
                >
                    <BaseIcon icon="icon-folder" />
                </BaseButton>
            </template>

            <template #content v-if="isActiveHistory">
                <div class="history__container">
                    <HistoryItem
                        v-for="(item, index) of historyItems"
                        :key="index"
                        :itemData="item"
                        @closeDropdown="onHistoryDropdownChangeVisible"
                    />
                </div>
                <div v-if="!historyItems.length" class="history__empty">
                    История изменений пуста
                </div>
            </template>
        </BaseDropdown>
    </div>
</template>

<script>
import HistoryItem from './HistoryItem'
import BaseButton from "@/components/Base/BaseButton";
import BaseIcon from "@/components/Base/BaseIcon";
import BaseDropdown from '@/components/Base/BaseDropdown'
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixiHistory from "@/mixins/MixiHistory";

export default {
    name: 'History',
    components: {
        BaseButton,
        BaseIcon,
        HistoryItem,
        BaseDropdown
    },
    mixins: [
        MixinApi,
        MixiHistory
    ],
    data() {
        return {
            isActiveHistory: false,
        }
    },
    methods:{
        onHistoryDropdownChangeVisible(boolean) {
            this.isActiveHistory = boolean
            if(boolean){
                this.postHistory({}, this.afterPostHistory);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.history {
    &__button{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 5px;
        transition: .2s;

        &.btn-border {
            border: none;
        }

        &.btn-border.btn_active {
            border-color: #6EC87A;
        }
    }

    &__container {
        max-height: 300px;
        overflow-y: auto;
        width: 320px;
    }

    &__empty{
        padding: 30px 73px;
        width: 300px;
    }
}
</style>
