export default {
    /**
     * Данные компонента
     * @property {Array} markers - маркеры
     * @property {Boolean} isCardVisible - условие рендера карточки
     * @property {Array} cardData - данные карточки
     * @property {Object} cardMarker - данные маркера карточки
     * @property {Object} cardMarkerSelected - выбранные цены маркера карточки
     */
    data: () => ({
        markers: [],
        markersWithoutPrices: [],
        isCardVisible: false,
        cardData: [],
        cardMarker: {},
        cardMarkerSelected: {},
        markersStatuses: [],
    }),
    methods: {
        /**
         * Адаптер для маркеров
         * @param {Array} markers - маркеры
         * @param {String} color - цвет
         * @param {String} kitId - идентификатор набора
         * @param {Boolean} selected - условие выбранности маркера
         * @returns {Array}
         */
        adapterMarkers(markers = [], color = '', kitId = '-1', selected = false,) {
            const markersAdapted = markers.map(marker => {
                // debugger;
                const {side_ids = [], status = '', fill_color = '',} = marker;
                const markerAdapted = {
                    ...marker,
                    extraData: {
                        kitId,
                        sideIds: side_ids,
                        status,
                    },
                    fill_color: color,
                    status,
                    color_legend: fill_color,
                    selected
                };
                return markerAdapted;
            });
            return markersAdapted;
        },
        onCloseCard() {
            this.isCardVisible = false;
            this.cardMarker = {};
            this.cardData = [];
            this.cardMarkerSelected = {};
        },
    }
}
