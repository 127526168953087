<template>
    <div class="layout-module">
        <control-panel
            class="control-panel--vertical"
            :is-module="true"
            :is-active-checkbox="isActiveLabel"
            @onTogglePoi="toggleModulePoi"
        />

        <container-poi
            :is-show-second-panel="isShowSecondPanel"
            @setActiveLabel="setActiveLabel"
            @onToggleGroupPoi="toggleGroupPoi"
            @onToggleOnePoi="toggleOnePoi"
            @onTogglePoiAll="togglePoiAll"
        />
    </div>
</template>

<script>
import ControlPanel from '../ControlPanel/ControlPanel.vue';
import ContainerPoi from "../ContainerPoi/ContainerPoi";
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';

export default {
    name: "LayoutModule",
    components: {
        ControlPanel,
        ContainerPoi,
    },
    mixins: [
        MixinApi,
    ],
    /**
     * Входные данные компонента
     * @property {Boolean} isShowSecondPanel - Показать/скрыть вспомогательное окно
     */
    data: () => ({
        isShowSecondPanel: false,
        isActiveLabel: false,
    }),
    computed: {

    },
    methods: {
        /**
         * Переключить активный модуль, получить список групп
         */
         toggleModulePoi() {
            this.isShowSecondPanel = !this.isShowSecondPanel;

        },
        /**
         * Показать лейбл, если есть выбранные группы
         * @property {Boolean} isActive - Показать/скрыть лейбл выбранных групп
         */
        setActiveLabel(isActive) {
            this.isActiveLabel = isActive;
        },
        /**
         * Показать/скрыть все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         * @param {string} groupId - key группы
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleGroupPoi(dataPoi = [], groupId = '', isRender = true) {
            this.$emit('onToggleGroupPoi', dataPoi, groupId, isRender);
        },
        /**
         * Показать/скрыть все POI на карте
         * @param {Array} dataPoi - Список POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        togglePoiAll(dataPoi = [], isRender = true) {
            this.$emit('onTogglePoiAll', dataPoi, isRender);
        },
        /**
         * Показать/скрыть одну метку POI на карте
         * @param {String} poiId - id POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleOnePoi(poiId, isRender) {
            this.$emit('onToggleOnePoi', poiId, isRender);
        },
    },
}
</script>

<style lang="scss" scoped>
    .layout-module {
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
    }
</style>
