<template>
    <li class="second-panel-child">
        <base-checkbox-v2
            class="second-panel-child__checkbox base-checkbox--small"
            :checked="isActiveCheckbox"
            @change="onChangeChildrenCheckbox"
        />
        <p
            class="second-panel-child__title"
            :class="{'second-panel-child__title--active' : isActiveCheckbox}"
        >{{ description }}</p>

        <base-dropdown
            class="second-panel-child__dropdown"
            :style-list="stylesDropdownGroups"
            @changeVisible="onClickDropdownMenu"
        >
            <template #preview>
                <BaseButton
                    v-show="canEdit"
                    class="second-panel-child__menu-btn"
                    :class="{'second-panel-child__menu-btn--active' : isActiveDropdown}"
                    view="simple"
                    form="oval"
                >
                    <span class="icon-navigation-more">...</span>
                </BaseButton>
            </template>
            <template #content>
                <div class="second-panel-child__dropdown-menu">
                    <BaseButton
                        v-for="(item, index) in poiMenuItems"
                        :key="index"
                        view="simple"
                        form="oval"
                        @click="addEmitButtons(item.emit)"
                    >{{ item.name }}</BaseButton>
                </div>
            </template>
        </base-dropdown>
    </li>
</template>

<script>
import ShemeSecondPanel from "../../schemes/ShemeSecondPanel";
import BaseCheckboxV2 from "@/components/Base/BaseCheckboxV2.vue";
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButton from "@/components/Base/BaseButton";

export default {
    name: "ControlPatelChildItem",
    components: {
        BaseCheckboxV2,
        BaseDropdown,
        BaseButton,
    },
    /**
     * Входные данные компонента
     * @property {Array} checkedChildList - Список включенных точек одной группы
     * @property {String} poiId - id poi
     * @property {String} description - Описание poi
     * @property {Boolean} canEdit - флаг включения редактирования точки
     * @property {Boolean} isActiveParent - Статус чекбокса группы POI
     */
    props: {
        checkedChildList: {
            type: Array,
            default: () => [],
        },
        poiId: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        isActiveParent: {
            type: Boolean,
            default: false,
        },

    },
    /**
     * Данные компонента
     * @property {boolean} isActiveCheckbox - Статус активности чекбокса POI
     * @property {boolean} isActiveDropdown - Статус выделения кнопки с открытым меню
     */
    data: () => ({
        isActiveCheckbox: false,
        isActiveDropdown: false,
    }),
    /**
     * При монтировании компонента узнаем статус родительского чекбокса и прошлое состояние
     */
    created() {
        this.isActiveCheckbox = !!this.isDefaultActive;
    },
    /**
     * Отслеживание изменений в массиве включенных poi
     */
    watch: {
        checkedChildList() {
            this.isActiveCheckbox = !!this.isCheckedItem;
        },
    },
    computed: {
        /**
         * Найти список пунктов меню poi
         */
        poiMenuItems() {
            return ShemeSecondPanel.contents.poiMenuItems;
        },
        /**
         * Найти стили для меню группы
         */
        stylesDropdownGroups() {
            return ShemeSecondPanel.dropdownGroups.stylesDropdownGroups;
        },
        /**
         * Проверка когда в списке включенных чекбоксов есть текущий id poi
         */
        isCheckedItem() {
            return this.checkedChildList.length > 0 && this.checkedChildList.some((el) => el.id === this.poiId);
        },
        /**
         * Проверка когда отмечен чекбокс группы и в списке включенных чекбоксов есть текущий id poi
         */
        isDefaultActive() {
            return this.isActiveParent && this.checkedChildList.findIndex((el) => el.id === this.poiId) !== -1;
        }
    },
    methods: {
        /**
         * Активировать выделение на кнопке меню
         * @param {Boolean} isShow - включен/выключен чекбокс
         */
        onClickDropdownMenu(isShow) {
            this.isActiveDropdown = isShow;
        },
        /**
         * Переключить статус чекбокса по клику на чекбокс
         * @param {boolean} isChecked - Выбран/отключен чекбокс
         */
        onChangeChildrenCheckbox(isChecked) {
            this.$emit('onCheckedPoi', isChecked, this.poiId);
        },
        /**
         * Вспомогательный метод для поиска названий функций обработчиков кнопки
         * @param {string} emit - название функции
         */
        addEmitButtons(emit) {
            return this[emit]();
        },

        /**
         * Клик удалить метку
         */
        removePoi() {
            this.$emit('removePoi', this.poiId);
        }
    },
}
</script>

<style lang="scss" scoped>
    .second-panel-child {
        display: flex;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 21px;

        &:hover {
            .second-panel-child__menu-btn {
                display: block;
            }
            .second-panel-child__title {
                color: #000;
            }

            .second-panel-child:last-child {
                margin-bottom: 0;
            }
        }

        &__checkbox{
            margin-right: 5px;
        }

        &__title {
            flex-grow: 1;
            font-size: 12px;
            color: #C8CAD1;
            font-weight: 400;
            margin-left: 10px;
            margin-right: 20px;
            word-break: break-word;
            cursor: pointer;

            &--active {
                color: #000;
            }
        }

        &__dropdown-menu {
            position: relative;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 28px rgba(59, 74, 116, .15);
            overflow: hidden;
            z-index: 5;
            pointer-events: auto;
            padding: 10px;

            button {
                color: #222;
                padding: 5px 10px;
                font-weight: 500;
                transition: all .4s ease-out;
            }
        }

        &__menu-btn {
            display: none;
            padding: 0;
            margin-left: 5px;

            width: 16px;
            height: 16px;

            background: #eee;
            color: #333;

            &--active {
                display: block;
                background: #bdbdbd;
                color: black;

                &:hover {
                    background: #bdbdbd;
                }
            }

            &:hover {
                background: #F5F5F5;
            }
        }

    }
</style>
