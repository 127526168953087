<template>
    <div
        class="kit-panel-item"
        :class="{'kit-panel-item_active': kitSelected}"
        @click="onClickKit"
    >
        <div
            class="kit-panel-item__title"
        >
            <base-text-input
                v-if="renameMode"
                ref="BaseTextInputKit"
                :value="title"
                @input="onUpdateName"
                @onBlur="onBlurInputKit"
            />
            <span
                v-if="!renameMode"
                :title="title"
            >{{title}}</span>
        </div>
        <div
            class="kit-panel-item__desc"
            :title="description"
        >
            <span>{{description}}</span>
        </div>
        <div
            v-if="hasPanel"
            class="kit-panel-item__btn-container"
        >
            <button
                :class="{'kit-panel-item__btn_active': kitTableActive}"
                :style="styleKitTableActive"
                class="kit-panel-item__btn"
                @click.stop="onToggleKit('Table')"
            >
                <span class="icon-folder"></span>
            </button>
            <button
                :class="{'kit-panel-item__btn_active': kitMarkersActive}"
                :style="styleKitMarkersActive"
                class="kit-panel-item__btn"
                @click.stop="onToggleKit('Markers')"
            >
                <span class="icon-marker"></span>
            </button>
        </div>
    </div>
</template>

<script>
import BaseTextInput from '@/components/Base/BaseTextInput';
import MixinRename from '@/mixins/MixinRename';
export default {
    name: 'KitPanelItem',
    components: {BaseTextInput},
    mixins: [MixinRename],
    /**
     * Входные данные комопнента
     * @property {String} kit - элемент панели набора
     * @property {Boolean} kitSelected - выделенность набора
     * @property {Boolean} kitTableActive - активность показа таблицы набора
     * @property {Boolean} kitMarkersActive - активность показа маркеров набора
     * @property {Boolean} kitIsRename - разрешено ли переименование набора
     */
    props: {
        kit: {
            type: Object,
            default: () => ({})
        },
        kitSelected: {
            type: Boolean,
            default: false
        },
        kitTableActive: {
            type: Boolean,
            default: false
        },
        kitMarkersActive: {
            type: Boolean,
            default: false
        },
        kitIsRename: {
            type: Boolean,
            default: false
        },
        hasPanel: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        pricesReduction: ['к', 'млн', 'млрд']
    }),
    computed: {
        /**
         * Идентификатор элемента набора
         * @returns {String}
         */
        id() {
            const {id = '-1'} = this.kit;
            return String(id);
        },
        /**
         * Тип элемента набора (found|custom)
         * @returns {String}
         */
        type() {
            const {type = ''} = this.kit;
            return type;
        },
        /**
         * Заголовок элемента набора
         * @returns {String}
         */
        title() {
            const {title = ''} = this.kit;
            return title;
        },
        /**
         * Описание элемента набора
         * @returns {String}
         */
        description() {
            const {description = ''} = this.kit;
            const descriptionSplitted = String(description).split(' / ');
            const count = descriptionSplitted[0];
            const countFormatted = parseInt(count).toLocaleString('ru');
            const price = descriptionSplitted.length === 2 ? descriptionSplitted[1] : '';
            let priceFormatted = '';
            if (price !== '') {
                const priceLocale = parseInt(price).toLocaleString('ru');
                const priceLocaleArr = String(priceLocale).split(/\s/);
                const priceLocaleWhole = parseInt(priceLocaleArr[0]);
                if (priceLocaleArr.length >= 2) {
                    const priceLocaleFract = parseInt(priceLocaleArr[1].slice(0, 1));
                    priceFormatted = `${priceLocaleWhole}${priceLocaleFract === 0 ? '' : '.' + priceLocaleFract}${this.pricesReduction[priceLocaleArr.length - 2] || ''}`;
                }
                else {
                    priceFormatted = `${priceLocaleArr[0]}`;
                }
            }
            const descriptionFormatted = priceFormatted !== '' ? `${countFormatted} ст / ${priceFormatted} ₽` : `${countFormatted} ст`;
            return descriptionFormatted;
        },
        color() {
            const {color = ''} = this.kit;
            return color;
        },
        styleKitTableActive() {
            const style = {background: this.kitTableActive ? this.color : '#EFF0F3'};
            return style;
        },
        styleKitMarkersActive() {
            const style = {background: this.kitMarkersActive ? this.color : '#EFF0F3'};
            return style;
        }
    },
    methods: {
        /**
         * Событие клика по набору
         */
        onClickKit() {
            this.onSelectKit();
            if (this.kitIsRename) {
                this.refInput = 'BaseTextInputKit';
                this.onClickName();
            }
        },
        /**
         * Событие снятия фокуса с инпута
         */
        onBlurInputKit() {
            this.onBlurInput();
            this.onRenameKit();
        },
        /**
         * Событие выделения элемента набора
         */
        onSelectKit() {
            this.$emit('onSelectKit', this.id, this.type);
        },
        /**
         * Событие переименования набора
         */
        onRenameKit() {
            if (this.newName !== '' && this.newName !== this.title)
                this.$emit('onRenameKit', this.id, this.newName);
        },
        /**
         * Событие переключения активности показа таблицы или маркеров набора
         * @param {String} typeEmit - тип эмита
         */
        onToggleKit(typeEmit = '') {
            this.$emit('onToggleKit', typeEmit, this.id, this.type);
        },
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#6EC87A !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

    .kit-panel-item {
        background: #FFFFFF;
        border: 1px solid;
        border-color: #FFFFFF;
        border-radius: 10px;
        padding: 10px;
        width: 165px;
        // height: 60px;
        display: inline-block;
        cursor: pointer;
        position: relative;

        &:hover &__title {
            color: #000;
        }

        &__title {
            height: 25px;
			color: $assistant-color;
			font-size: 14px;
            user-select: none;
            display: flex;
            transition: color .25s;
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                flex-grow: 1;
            }
		}
        &__desc {
			font-size: 10px;
			color: $assistant-color;
            max-width: 80px;
            display: flex;
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                flex-grow: 1;
            }
		}
        &__btn-container {
			display: flex;
			position: absolute;
			bottom: 5px;
			right: 10px;
		}
        &__btn {
			width: 22px;
			height: 22px;
			border-radius: 50%;
            background: #EFF0F3;
			outline: none;
            margin-left: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
			&_active {
                span {
                    color: #fff !important;
                }
                background: $active-color;
			}
            span {
                color: #000;
                font-size: 10px;
            }
		}
        &_active {
            .kit-panel-item__title {
                color: #000000;
            }
            border-color: #6EC87A !important;
            //box-shadow: 0 0 0px 3px #3988ff6b;
        }
    }
</style>
