<template>
    <span class="base-color-label" :style="modifyStyle"></span>
</template>

<script>
export default {
    name: "BaseColorPicker",

    /**
     * Входные данные
     * @property {Object} color - текущий цвет
     */
    props: {
        color: {
            type: String,
            default: '',
        },
        styleObject: {
            type: Object,
            default: () => {},
        }
    },

    computed: {
        modifyStyle() {
            if (this.color) {
                return {
                    'background': this.color,
                    'borderColor': this.color,
                };
            }
            return this.styleObject;
        },
    },

}
</script>

<style lang="scss" scoped>
    .base-color-label {
        display: block;
        width: 16px;
        height: 16px;
        border-width: 1px;
        border-style: solid;
        border-color: #D7D9D9;
        border-radius: 2px;
        background: #fff;
        cursor: pointer;
    }
</style>
