<template>
    <folder
        :id="id"
        :title="title"
        :isMinimize="false"
        :width="width"
        :height="height"
        :top="top"
        :left="left"
        :color="color"
        :isRename="isRename"
        class="folder"
        @onRenameFolder="onRenameFolder"
        @click.native="onClickFolder"
    >
        <template v-slot:summary>
            <SummaryData
                :summaryData="summaryData"
                class="folder__summary"
            />
        </template>
        <template v-slot:panel>
            <FolderControlPanel
                class="folder__panel"
                :isExport="!!tableRows.length"
                @remove="onRemoveFolder(id)"
                @exportExcel="onExportFolder(id)"
                @moveToBudget="OnMoveToBudget(id)"
            />
        </template>
        <template v-slot:content>
            <kit-tab
                :columns="tableColumns"
                :modifieredCols="tableColumnsModifered"
                :datesCols="tableColumnsDates"
                :tableData="tableRows"
                :folderId="id"
                :rowsCheckboxes="tableRowsPricesSelected"
                :style="{'height': '100%'}"
                @onCheckSubCheckbox="onCheckSubCheckbox"
                @filter="onFilter"
                @sort="onSort"
                @onClickCell="onClickCell"
            />
        </template>
    </folder>
</template>

<script>
import {mapMutations} from 'vuex';
import ServiceApi from '@/services/ServiceApi.js';
import Folder from '@/components/Folder/Folder';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinMapFound from '@/mixins/MixinsPageBayuerdesk/MixinMapFound';
import KitTab from '@/components/HandbookComponents/KitTab';
import SummaryData from '@/components/Folder/SummaryData'
import FolderControlPanel from '@/components/Folder/FolderControlPanel'
import MixinSummary from "@/mixins/MixinSummary";

export default {
    name: 'LayoutFolder',
    components: {
        Folder,
        KitTab,
        SummaryData,
        FolderControlPanel
    },
    mixins: [
        MixinTableFound,
        MixinMapFound,
        MixinSummary
    ],
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {String} title - название папки
     * @property {String} folderType - тип папки
     * @property {Number} top - позиция top папки
     * @property {Number} left - позиция left папки
     * @property {String} color - цвет папки
     * @property {Boolean} isRename - возможность переименования папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        id: {
            type: String,
            default: '-1'
        },
        title: {
            type: String,
            default: ''
        },
        folderType: {
            type: String,
            default: 'found',
            validator: folderType => ['found', 'custom'].includes(folderType)
        },
        top: {
            type: Number,
            default: 0
        },
        left: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: ''
        },
        isRename: {
            type: Boolean,
            default: false
        },
        isCurrent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Ширина папки
         * @returns {Number}
         */
        width() {
            const width = this.folderType === 'found' ? Math.ceil(window.innerWidth * 0.7) : Math.ceil(window.innerWidth * 0.5);
            return width;
        },
        /**
         * Высота папки
         * @returns {Number}
         */
        height() {
            const height = this.folderType === 'found' ? Math.ceil(window.innerHeight * 0.65) : 300;
            return height;
        },
        /**
         * Тело запроса для таблицы
         * @returns {Object}
         */
        tableRequestBody() {
            const tableRequestBody = {
                set_id: this.id,
                sort: this.tableSort,
                filter: this.tableFilter,
            };
            return tableRequestBody;
        },
        /**
         * Выбранные цены набора
         * @returns {Object}
         */
        tableRowsPricesSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {date = ''} = price;
                    return date;
                })
            });
            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsPricesIdsSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {id = '-1'} = price;
                    return id;
                })
            });
            return tableRowsPricesSelected;
        }
    },
    watch: {
        /**
         * Следим является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        isCurrent() {
            if (this.isCurrent) {
                if(this.tableRows.length === 0) {
                    this.postTableDataCustomKit((rows) => {
                        this.afterPostTableData(rows, 'allPrices');
                        this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                        this.setTableCustomRows(this.tableRows);
                    });
                }
                else {
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                }
            }
        }
    },
    mounted() {
        /**
         * При монтировании проверяем является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        if (this.isCurrent) {
            if(this.tableRows.length === 0) {
                this.postTableDataCustomKit((rows) => {
                    this.afterPostTableData(rows, 'allPrices');
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                });
            }
            else {
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        }
        this.postSummaryData()
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataCustomKit(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-table', this.tableRequestBody);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = []} = dataCustom;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        async postMarkersDataCustom(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-markers', postData);
                const {data = {}} = res;
                const {data: markers = []} = data;
                actionAfter(markers);
            }
            catch(error) {
                console.log(error);
            }
        },
        async postSummaryData() {
            try {
                const response = await ServiceApi.post('free', '/map/set-summary', {set_id: this.id});
                if(typeof response !== 'undefined'){
                    const result = response.data.data
                    this.summaryData = this.AdapterSummaryData(result)
                }

            } catch(error) {
                console.log(error);
            }
        },
        /**
         * Обновить данные
         * @param {Array} sideIds - стороны
         * @param {Function} actionAfter - событие сработающее после обновления
         */
        updateDataCustomKit(sideIds = [], actionAfter = () => {}) {
            this.postTableDataCustomKit((rows) => {
                this.afterPostTableData(rows, 'allPrices', true);
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
                this.$nextTick(() => {
                    actionAfter(sideIds, this.tableRowsPricesIdsSelected);
                });
            });
        },
        /**
         * Получить маркеры
         */
        getMarkers() {
            if (this.markers.length === 0) {
                this.updateMarkers();
            }
            else
                this.$emit('drawMarkers', this.id);
        },
        /**
         * Обновить маркеры
         */
        updateMarkers() {
            const postData = {set_id: this.id};
            this.postMarkersDataCustom(postData, (markers) => {
                this.markers = this.adapterMarkers(markers, this.color, this.id, true);
                this.$emit('afterGetMarkers', this.id, this.markers);
            });
        },
        /**
         * Событие переименования папки
         * @param {String} id - идентификатор папки
         * @param {String} newName - новое имя папки
         */
        onRenameFolder(id = '-1', newName = '') {
            this.$emit('onRenameFolder', String(id), newName);
        },
        onRemoveFolder(id = '-1') {
            this.tableRows = [];
            this.$emit('removeFolder', String(id));
        },
        onExportFolder(id = '-1') {
            this.$emit('exportFolder', String(id));
        },
        OnMoveToBudget(id = '-1') {
            this.$emit('moveToBudget', String(id));
        },
        /**
         * Событие клика по папке
         */
        onClickFolder() {
            this.$emit('onClickFolder', this.id, this.folderType);
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         */
        onCheckSubCheckbox(checkData = {}) {
            this.$emit('onCheckSubCheckbox', checkData);
        },
        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilter(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.postTableDataCustomKit((rows) => {
                this.afterPostTableData(rows, 'allPrices', true);
            });
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        onSort(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.postTableDataCustomKit((rows) => {
                this.afterPostTableData(rows, 'allPrices', true);
            });
        },
        /**
         * Добавить цены в таблицу
         * @property {Array} rows - строки
         */
        addPriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const currentRowsIds = this.tableRows.map(row => String(row.id));
                    const {id = '-1'} = row;
                    if (!currentRowsIds.includes(id)) {
                        this.afterPostTableData(rows, 'allPrices')
                    }
                    else {
                        const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                        const rowsAdapted = this.adapterRows([row], 'allPrices');
                        if (index !== -1) {
                            this.tableRows.splice(index, 1, rowsAdapted[0]);
                            const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
                            this.collectColumns(columnsDatesAdapted);
                        }
                    }
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                });
            }
        },
        /**
         * Удалить цены из таблицу
         * @property {Array} rows - строки
         */
        removePriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const {id = '-1'} = row;
                    const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                    const rowsAdapted = this.adapterRows([row], 'allPrices');
                    if (index !== -1) {
                        this.tableRows.splice(index, 1, rowsAdapted[0]);
                        const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
                        this.collectColumns(columnsDatesAdapted);
                    }
                });
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Удалить строку из таблицы
         * @property {String} rowId - идентификатор набора
         */
        removeRowTable(rowId = '-1') {
            if (this.isCurrent) {
                const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(rowId));
                if (index !== -1) {
                    this.tableRows.splice(index, 1);
                    const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
                    this.collectColumns(columnsDatesAdapted);
                }
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        onClickCell(data){
            this.$emit('onClickCell', data);
        },
    }
}
</script>

<style lang="scss" scoped>
.folder {
    &__panel {
        display: flex;
        justify-content: flex-end;
    }

    // &__summary {
    //     margin-top: 12px;
    //     margin-bottom: 15px;
    // }
}
</style>
