<template>
	<div class="all">
		<AppTable
			primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="true"
			:columns="columns"
			:rows="tableData"
			:isProgressBar="isProgressBarLoading"
            :rowsSelected="rowsSelected"
            :rowsSelectedPart="rowsSelectedPart"
			class="all-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
            @onClickCell="onClickCell"
		>
			<template
				v-for="(col,x) of [...modifieredCols, ...datesCols]"
				v-slot:[col]="scopeData"
			>
				<BaseCell
                    v-if="modifieredCols.includes(col)"
					:key="x"
					:content="scopeData.row[col]"
				/>
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                >
                    <label class="checkbox-label">
						<div class="cell-checkbox">
							<base-checkbox-v2
								:key="x"
								:checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
								@change.native="onCheckSubCheckbox({row: scopeData.row, column: scopeData.column})"
							/>
						</div>
						<div
							class="cell-checkbox2"
							:class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB'
                            }"
						>
							<span>
                                {{scopeData.row[col] | cutNumber}}
                            </span>

						</div>
					</label>
                </div>
			</template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
				/>
			</template>

			<template v-slot:light="scopeData">
				<AppLight :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.image_link" />
			</template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>
		</AppTable>
	</div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';

export default {
	name: "KitTab",
    mixins: [MixinHandbookTab],
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BaseCheckboxV2,
	},
	props: {
        datesCols: {
            type: Array,
			default: () => ([])
        },
        rowsSelected: {
            type: Array,
			default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
		folderId: {
			type: [Number, String],
			required: true
		},
        canCheck: {
            type: Boolean,
            default: false
        }
	},
	// computed: {},
    methods: {
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        onClickCell(data){
            data.kitId = this.folderId;
            this.$emit('onClickCell', data);
        },
        onCheckSubCheckbox(checkData = {}) {
            // eslint-disable-next-line no-debugger
            // debugger;
            this.$emit('onCheckSubCheckbox', checkData);
        },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxes[rowId] || [];
            return cellsChecked.includes(prop);
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null && !isNaN(price);
        }
    },
	filters: {
		cutNumber(value = '') {
            const price = (value !== '' && value !== null && !isNaN(value)) ? value : 0;
            if (price !== '') {
                const priceLocale = parseInt(price).toLocaleString('ru');
                const priceLocaleArr = String(priceLocale).split(/\s/);
                const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                return priceFormatted;
            }
		}
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;

.all {
	display: flex;

	&-table {
		margin-top: 4px;
	}

	.checkbox-label {
		.cell-checkbox { display: none !important; }

		.cell-checkbox2 {
			width: 36px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid;
			border-color: #F4F6F9;
			border-radius: 4px;
			cursor: pointer;

			&_active {
				background: $active-color;
				border-color: $active-color;
				color: #fff;
			}
		}
	}
}
</style>

<style lang="scss">
.all .table .header .date-cell .cell__content span {
	display: block;
	text-align: center;
	width: 100%;
}

.all .table .body .gid-cell .default-cell,
.all .table .body .supplier-cell .default-cell,
.all .table .body .direction-cell .default-cell {
	max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.page-bayuerdesk .all-table {
    width: 100%;
	// padding-top: 61px !important;
}
</style>
