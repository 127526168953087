const ShemeSecondPanel = {
    contents: {
        actions: [
            {
                name: 'Выбрать все',
                emit: 'selectedAll',

            },
            {
                name: 'Убрать все',
                emit: 'unselectedAll',

            },
        ],


        menuItems: [
            {
                name: 'Добавить группу',
                emit: 'addNewGroupPoi',
            },
        ],
        groupMenuItems: [
            {
                name: 'Удалить группу',
                emit: 'removeGroupPoi',
            },
        ],
        poiMenuItems: [
            {
                name: 'Удалить метку',
                emit: 'removePoi',
            },
        ],
        noGroupsText: 'POI отсутствуют',
        noGroupsButton: {
            name: 'Добавить группу',
            emit: 'addNewGroupPoi',
        },
    },
    dropdownGroups:{
        stylesDropdownGroups: {
            'minWidth': 'auto',
            'height': 'auto',
            'justifyContent': 'flex-start',
            'alignItems': 'flex-start',
            'flexWrap': 'nowrap',
            'position': 'absolute',
            'top': '23px',
            'right': '-8px',
            'padding': '0',
        },
        emit: 'onClickDropdownMenu',
    },
    stylesDropdownAddGroups: {
        'maxWidth': 'none',
        'width': '100%',
        'right': '0',
        'top': '40px',
        'zIndex': '2',
    },

};

export default ShemeSecondPanel;
