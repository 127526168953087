<template>
	<div
        class="handbook-info-box"
    >

        <base-button
            class="handbook-info-box__button"
            :view="'secondary'"
            @click="$emit('onCloseInfo')"
        >
            Закрыть
        </base-button>

        <app-table
            v-if="currentEntity === 'marker' && sidesInfo.length"
            :columns="infoColumns[currentEntity]"
            :rows="sidesInfo"
            primaryKey="id"
            class="handbook-info-box__table"
        >
            <template v-slot:direction="scopeData">
                <MapDirection :item="scopeData.row" />
            </template>
            <template v-slot:image_src="scopeData">
                <AppPhoto
                    :small="scopeData.row.photo.small"
                    :big="scopeData.row.photo.big"
                />
            </template>

            <template v-slot:materials="scopeData">
                <div style="display: block">
                    <div
                        v-for="(value,index) of scopeData.row.materials"
                        :key="index"
                    >
                        {{value}}
                    </div>
                </div>
            </template>
        </app-table>
        <VerticalTable
            v-if="currentEntity === 'side' && Object.keys(rowInfo).length"
            :columns="infoColumns[currentEntity]"
            :row="rowInfo"
        >
            <template v-slot:direction="scopeData">
                <MapDirection :item="scopeData.row" />
            </template>
            <template v-slot:image_map="scopeData">
                <MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
            </template>
        </VerticalTable>
	</div>
</template>

<script>

import AppTable from "@/components/Table2/Table";
import VerticalTable from "@/components/Table2/VerticalTable";
import AppPhoto from "@/components/CellComponents/Photo";
import BaseButton from "@/components/Base/BaseButton";
import MapImage from "@/components/CellComponents/MapImage";
import MapDirection from "@/components/CellComponents/MapDirection";

export default {
    name: "HanbookInfoBox",
    components: {
        AppTable,
        AppPhoto,
        BaseButton,
        VerticalTable,
        MapImage,
        MapDirection
    },
    props: {
        currentEntity: {
            type: String,
            default: ''
        },
        infoBoxPreloader: {
            type: Boolean,
            default: false
        },
        sidesInfo: {
            type: Array,
            default: () => ([])
        },
        infoColumns: {
            type: Object,
            default: () => ({})
        },
        rowInfo: {
            type: Object,
            default: () => ({})
        },
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>

.handbook-info-box {
    padding: 20px;

    &__button{
        padding: 10px;
    }

    &__table{
        padding-top: 35px!important;
    }
}
</style>
