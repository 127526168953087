<template>
    <section class="second-panel">
        <div class="second-panel__header">
            <div class="second-panel__header-top">
                <h3 class="second-panel__title">{{ title }}</h3>
                <div class="second-panel__menu">

                    <base-dropdown
                        class="second-panel__dropdown"
                        :style-list="shemeDropdown.stylesDropdownGroups"
                        @changeVisible="onClickDropdownMenu"
                    >
                        <template #preview>
                            <BaseButton
                                class="second-panel__menu-btn"
                                :class="{'second-panel__menu-btn--active' : isActiveDropdown}"
                                view="simple"
                                form="oval"
                            >
                                <span class="icon-navigation-more">...</span>
                            </BaseButton>
                        </template>
                        <template #content>
                            <div class="second-panel__dropdown-menu">
                                <BaseButton
                                    v-for="(item, index) in shemeContents.menuItems"
                                    :key="index"
                                    view="simple"
                                    form="oval"
                                    @click="addEmitButtons(item.emit)"
                                >{{ item.name }}</BaseButton>
                            </div>
                        </template>
                    </base-dropdown>
                </div>
            </div>

            <div class="second-panel__header-actions" v-if="!isEmptyGroups">
                <button
                    v-for="(item, index) in shemeContents.actions"
                    :key="index"
                    @click="addEmitButtons(item.emit)"
                    class="second-panel__header-button"
                >{{ item.name }}</button>
            </div>
        </div>
        <div class="second-panel__items">
            <div v-if="$slots.preloader" class="second-panel__preloader">
                <slot name="preloader"/>
            </div>
            <ul class="second-panel__parent-list" v-if="!isEmptyGroups">
                <second-panel-item
                    v-for="item in dataGroup"
                    :key="item.key"
                    :data-poi="poiByGroupIdLength(item.key)"
                    :checked-parent-list="checkedParentList"
                    :checked-child-list="checkedChildList"
                    :group-id="item.key"
                    :item-name="item.name"
                    :item-color="item.color"
                    :can-edit="item.canEdit"
                    :is-parent-checked="checkedParent(item.key)"
                    @onCheckedGroup="onCheckedGroup"
                    @onCheckedPoi="onCheckedPoi"
                    @onToggleShowPoiList="toggleShowPoiList"
                    @removePoi="removePoi"
                    @removeGroupPoi="removeGroupPoi"
                />
            </ul>
            <div class="second-panel__empty-content" v-if="isEmptyGroups">
                <p class="second-panel__empty-content-text">{{ shemeContents.noGroupsText }}</p>
                <button
                    class="second-panel__empty-content-button"
                    @click="addNewGroupPoi"
                >{{ shemeContents.noGroupsButton.name }}</button>
            </div>
        </div>

        <app-modal
            v-show="isModalOpened"
            @close="onCloseModal"
        >
            <template v-slot:modalBody>
                <div class="second-panel__modal-body">
                    <div class="second-panel__modal-body-header">
                        <p>Выберите одну или несколько групп</p>
                    </div>

                    <div class="second-panel__modal-body-preloader" v-if="isLoadingGroupsForAdd">
                        <base-preloader :size="80"/>
                    </div>

                    <template v-else>
                        <base-select-multiple
                            :options="options"
                            :value="[]"
                            :is-full-width="true"
                            :style-list-dropdown="shemeAddGropsDropdown"
                            @check="newGroupPoi"
                        >
                            <template v-slot:header="props">
                                <div class="select-dropdown__slot-header">
                                    <p class="select-dropdown__slot-header-btn" @click="props.selectAll">Выделить все</p>
                                    <p class="select-dropdown__slot-header-btn" @click="props.clear">Сбросить</p>
                                </div>
                            </template>
                        </base-select-multiple>
                    </template>
                </div>
            </template>
        </app-modal>
    </section>
</template>

<script>
import SecondPanelItem from "./SecondPanelItem";
import ShemeSecondPanel from "../../schemes/ShemeSecondPanel";
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButton from "@/components/Base/BaseButton";
import BaseSelectMultiple from "@/components/Base/BaseSelectMultiple";
import AppModal from '@/components/Base/Modal';
import BasePreloader from "@/components/Base/BasePreloader";

export default {
    name: "SecondPanel",
    components: {
        SecondPanelItem,
        BaseDropdown,
        BaseButton,
        BaseSelectMultiple,
        AppModal,
        BasePreloader,
    },
    /**
     * Входные данные компонента
     * @property {Array} dataGroup - Список групп с бекенда
     * @property {Array} dataPoi - Список точек poi с бекенда
     * @property {Array} checkedParentList - Список включенных групп
     * @property {Array} checkedChildList - Список включенных точек poi
     * @property {String} title - Название открытого окна модуля
     */
    props: {
        dataGroup: {
            type: Array,
            default: () => [],
        },
        dataPoi: {
            type: Array,
            default: () => [],
        },
        checkedParentList: {
            type: Array,
            default: () => [],
        },
        checkedChildList: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        groupsForAdd: {
            type: Array,
            default: () => [],
        },
    },
    /**
     * Данные компонента
     * @property {Boolean} isActiveDropdown - Подсветить кнопку с открытым меню
     * @property {Boolean} isModalOpened - Показать/скрыть модальное окно
     * @property {Array} options - Список доступных групп для добавления
     */
    data: () => ({
        isActiveDropdown: false,
        isModalOpened: false,
        options: [],
    }),

    watch: {
        groupsForAdd() {
            if (!this.isLoadingGroupsForAdd) {
                this.options = this.groupsForAdd;
            }
        },
    },
    computed: {
        /**
         * Прелоадер для списка групп для добавления
         */
        isLoadingGroupsForAdd() {
            return this.groupsForAdd.length === 0;
        },
        /**
         * Проверить пришел ли список групп с бекенда
         */
        isEmptyGroups() {
          return this.dataGroup.length === 0;
        },
        /**
         * Найти статические данные из ShemeSecondPanel
         */
        shemeContents() {
            return ShemeSecondPanel.contents;
        },
        /**
         * Найти статические данные из ShemeSecondPanel
         */
        shemeDropdown() {
            return ShemeSecondPanel.dropdownGroups;
        },
        /**
         * Найти стили для списка доступных групп для добавления из ShemeSecondPanel
         */
        shemeAddGropsDropdown() {
            return ShemeSecondPanel.stylesDropdownAddGroups;
        },

    },
    methods: {
        /**
         * Активировать выделение на кнопке меню
         * @param {Boolean} isShow - включен/выключен чекбокс
         */
        onClickDropdownMenu(isShow) {
            this.isActiveDropdown = isShow;
        },
        /**
         * Клик по чекбоксу группы
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} groupId - key группы
         */
        onCheckedGroup(isChecked, groupId) {
            this.$emit('onCheckedGroup', isChecked, groupId);
        },
        /**
         * Клик по чекбоксу точки
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} poiId - id точки poi
         * @param {String} groupId - key группы
         */
        onCheckedPoi(isChecked, poiId, groupId) {
            this.$emit('onCheckedPoi', isChecked, poiId, groupId);
        },
        /**
         * Событие показа/скрытия всех точек группы
         * @param {String} groupId - key группы
         */
        toggleShowPoiList(groupId) {
            this.$emit('onToggleShowPoiList', groupId);
        },
        /**
         * Вспомогательный метод для поиска названий функций обработчиков кнопки
         * @param {string} emit - название функции
         */
        addEmitButtons(emit) {
            return this[emit]();
        },
        /**
         * Клик на кнопку выбрать все чекбоксы
         */
        selectedAll() {
            this.$emit('selectedAllCheckbox');
        },
        /**
         * Клик на кнопку убрать все чекбоксы
         */
        unselectedAll() {
            this.$emit('unselectedAllCheckbox');
        },
        /**
         * Клик на кнопку добавить новую группу
         */
        addNewGroupPoi() {
            this.isModalOpened = true;
            this.$emit('addNewGroupPoi');
        },
        /**
         * Клик на кнопку применения списка выбранных групп
         * @param {Array} newGroupList - Список новых групп
         */
        newGroupPoi(newGroupList) {
            this.$emit('newGroupPoi', newGroupList);
        },
        /**
         * Клик удалить метку
         * @param {string} poiId - key группы
         */
        removePoi(poiId) {
            this.$emit('removePoi', poiId);
        },
        /**
         * Клик удалить группу
         * @param {string} groupId - key группы
         */
        removeGroupPoi(groupId) {
            this.$emit('removeGroupPoi', groupId);
        },
        /**
         * Вспомогательная функция поиска нужного key группы в списке отмеченных групп
         * * @param {string} groupId - key группы
         */
        checkedParent(groupId) {
            const checkedParent = this.checkedParentList.find((el) => el === groupId);
            if (checkedParent !== undefined) {
                return true;
            }
            return false;
        },
        /**
         * Вспомогательная функция передать точки данной группы
         * * @param {string} groupId - key группы
         */
        poiByGroupIdLength(groupId) {
            return this.dataPoi.filter((el) => el.key === groupId);
        },
        /**
         * Закрыть модальное окно
         */
        onCloseModal() {
            this.isModalOpened = false;
        },
    },
}
</script>

<style lang="scss" scoped>
    .second-panel {
        margin-left: 5px;
        width: 210px;
        max-height: 80vh;
        background: #fff;
        border-radius: 10px;
        padding: 20px 15px;
        box-shadow: 2px 3px 20px #3B4A741A;
        box-sizing: border-box;
        overflow-y: auto;


        &__header {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }

        &__title {
            font-size: 14px;
            font-weight: 400;
            color: #000;
        }

        &__header-top {
            display: flex;
            justify-content: space-between;
            padding-bottom: 5px;
            border-bottom: 1px solid #eee;
        }

        &__header-actions {
            margin-top: 10px;
        }

        &__header-button {
            display: inline-block;
            color: #C8CAD1;
            margin-right: 14px;
            font-size: 12px;
            font-weight: 400;
            transition: color .4s ease-out;

            &:hover {
                color: #444547;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__dropdown-menu {
            position: relative;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 28px rgba(59, 74, 116, .15);
            overflow: hidden;
            z-index: 5;
            pointer-events: auto;
            padding: 10px;

            button {
                color: #222;
                padding: 5px 10px;
                font-weight: 500;
                transition: all .4s ease-out;
            }
        }

        &__menu-btn {
            padding: 0;
            margin-left: 5px;

            width: 16px;
            height: 16px;

            background: #eee;
            color: #333;

            &--active {
                display: block;
                background: #bdbdbd;
                color: black;

                &:hover {
                    background: #bdbdbd;
                }
            }

            &:hover {
                background: #F5F5F5;
            }
        }

        &__menu-icon {
            position: relative;
            padding: 0 5px;
            cursor: pointer;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                display: none;
                width: 20px;
                height: 20px;
                background: #125293;
                opacity: .1;
                border-radius: 50%;
                transition: all 1s ease-out;
            }

            &:hover {
                &:before {
                    display: block;
                }
            }
        }

        &__preloader {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        &__empty-content {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__empty-content-text {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        &__empty-content-button {
            font-size: 16px;
            font-weight: 400;
            color: #C8CAD1;
            transition: color .4s ease-out;

            &:hover {
                color: #444547;
            }
        }

        &__items {
            position: relative;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__parent-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__modal-body {
            width: 700px;
            height: 550px;
            padding: 30px;
            background: #fff;

            @media (max-width: 1000px) {
                width: 500px;
                padding: 20px;
            }

            @media (max-width: 576px) {
                width: 300px;
                padding: 10px;
            }
        }

        &__modal-body-header {
            text-align: center;
            margin-bottom: 30px;
            font-size: 20px;
            color: #000;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        &__modal-body-preloader {
            display: flex;
            justify-content: center;
        }
    }

    .select-dropdown {
        &__slot-header {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #eee;
        }

        &__slot-header-btn {
            margin-right: 15px;
            font-size: 14px;
            color: #aaa;
            cursor: pointer;
            transition: color .3s ease-out ;

            &:hover {
                color: #000;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }


</style>
