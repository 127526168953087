import ServiceApi from '@/services/ServiceApi.js';
export default {
    methods: {
        /**
         * Запрос для удаления набора
         * @param {Number} kitId - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRemoveKit(kitId = -1, actionAfter = () => {}) {
            const postData = {set_id: kitId}
            try {
                await ServiceApi.post('free', '/map/set-remove', postData);
                actionAfter(kitId);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для экспорта набора
         * @param {Number} kitId - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postExportKit(kitId = -1, actionAfter = () => {}) {
            const postData = {set_id: kitId}
            try {
                const res = await ServiceApi.post('free', '/export/set', postData);
                const {data = {}} = res;
                const {data: obj = {}} = data;
                actionAfter(obj.url);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для получения списка кастомных наборов
         * @async
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postKitsList(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-list');
                const {data = {}} = res;
                const {data: kits = []} = data;
                actionAfter(kits);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для создания набора
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postCreateKit(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-create', postData);
                const {data = {}} = res;
                const {data: kit = {}} = data;
                actionAfter(kit);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для переименования набора
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRenameKit(postData, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-edit', postData);
                const {data = {}} = res;
                const {data: kit = {}} = data;
                const {id = '-1', name = ''} = kit;
                actionAfter(id, name);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения таблицы найдено
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataFound(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/table', postData);
                const {data = {}} = res;
                const {data: dataFound = {}} = data;
                const {count = 0, rows = []} = dataFound;
                actionAfter(count, rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        // /**
        //  * Запрос на получения маркеров найдено
        //  * @async
        //  * @param {Object} postData - тело запрос
        //  * @param {Function} actionAfter - событие, сработающее после отработки запроса
        //  */
        // async postMarkersDataFound(postData = {}, actionAfter = () => {}) {
        //     try {
        //         const res = await ServiceApi.post('free', '/map/markers', postData);
        //         const {data = {}} = res;
        //         const {data: markers = []} = data;
        //         actionAfter(markers);
        //     }
        //     catch(error) {
        //         console.log(error);
        //     }
        // },
        /**
         * Запрос на получения маркеров найдено (новое АПИ)
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkersDataFound(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/markers-found', postData);
                const {data = {}} = res;
                actionAfter(data.data);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения маркеров без цен
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkersDataWithoutPrices(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/without-prices', postData);
                const {data = {}} = res;
                const {data: markers = []} = data;
                actionAfter(markers);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных карточки конструкции
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkerData(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/marker-info', postData);
                const {data = {}} = res;
                const {data: markerData = {}} = data;
                const {rows = []} = markerData;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных карточки конструкции маркера из справочника
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkerCatalogData(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('catalog', '/catalog/list', postData);
                const {data = {}} = res;
                // console.log(data);
                const {data: markerData = {}} = data;
                // const {rows = []} = markerData;
                actionAfter(markerData);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на добавление/удаления цен
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSyncDataCustom(postData = {}, actionAfter = () => {}) {
            try {
                await ServiceApi.post('free', '/map/set-sync', postData);
                actionAfter();
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных для строки при добавлении в набор
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataRow(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-table', postData);
                const {data = {}} = res;
                const {data: dataRows = {}} = data;
                const {rows = [], info: kitItem = {}} = dataRows;
                actionAfter(rows, kitItem);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных для строк найдено
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataRowFound(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/table', postData);
                const {data = {}} = res;
                const {data: dataRows = {}} = data;
                const {rows = []} = dataRows;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения истории действий
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postHistory(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/history/list', postData);
                const {data = {}} = res;
                const {data: history = {}} = data;
                actionAfter(history);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение списка групп
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postGroups(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/get-groups', postData);
                const {data = {}} = res;
                const {data: groups = {}} = data;
                const {tree = [], images = []} = groups;

                tree.map((group, index) => {
                    group.key = String(group.key);
                    group.name = String(group.name);
                    group.color = String(group.color);
                    group.canEdit = Boolean(Number(group.can_edit));
                    group.isActive = Boolean(group.is_active);
                    group.src = Boolean(group.src);
                    images[index]?.src ? group.image = String(images[index]?.src) : group.image = '';

                    return group;
                });

                actionAfter(tree);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение списка POI
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postPoints(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/get-points', postData);
                const {data = {}} = res;
                const {data: dataBody} = data;

                dataBody.map((poi) => {
                    poi.id = String(poi.id);
                    poi.key = String(poi.key);
                    poi.lat = String(poi.lat);
                    poi.lng = String(poi.lng);
                    poi.address = poi.address ? String(poi.address) : '';
                    poi.description = poi.description ? String(poi.description) : 'Нет описания';
                    poi.canEdit = Boolean(Number(poi.can_edit));
                    poi.src = this.dataGroup.find((el) => el.key === poi.key).src;

                    poi.iconType = 'poi';
                    poi.fill_color = this.dataGroup.find(el => el.key === poi.key).color;
                    poi.extraData = {
                        groupKey: poi.key,
                        type: 'poi',
                    };
                    return poi;
                });

                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         * Запрос на получение списка доступных групп POI
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postAddGroupList(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/group-list', postData);
                const {data = {}} = res;
                const {data: dataBody} = data;

                dataBody.map((item) => {
                    item.id = Number(item.key);
                    item.value = String(item.name);
                    delete item.key;
                    delete item.name;

                    return item;
                });

                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на добавление групп POI из списка доступных групп
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postAddGroup(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/inactive-groups', postData);
                const {data = {}} = res;
                const {data: dataBody} = data;

                // dataBody.map((item) => {
                //     item.id = Number(item.key);
                //     item.value = String(item.name);
                //     delete item.key;
                //     delete item.name;
                //
                //     return item;
                // });

                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на удаление группы POI
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRemoveGroups(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/remove-poi', postData);
                const {data = {}} = res;
                const {data: dataBody} = data;

                dataBody.map((item) => {
                    item.id = Number(item.key);
                    item.name = String(item.name);

                    return item;
                });

                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на удаление точки POI
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRemovePoi(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('demo', '/v2/poi/delete', postData);
                const {data = {}} = res;
                const {data: dataBody} = data;

                dataBody.map((item) => {
                    item.id = String(item.id);
                    item.key = String(item.key);

                    return item;
                });

                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
    }
}
