<template>
	<div class="table">
		<div class="table__header months">
			<div
				v-for="month of months"
				:key="month"
				class="months__item"
			>
				{{month}}
			</div>
		</div>

		<div class="sides">
			<div
				v-for="(priceData, index) of prices"
				:key="index"
				class="sides__item"
			>
				{{ priceData.sideName }}
			</div>
		</div>

		<div class="table__values values">
			<div
				v-for="(priceData, index) of prices"
				:key="index"
				class="values__row"
			>
				<div
					v-for="(month, monthIndex) of months"
					:key="monthIndex"
					class="value"
					:class="getClasses(monthIndex, priceData.prices, priceData)"
                    @click="onCheckPrice(monthIndex, priceData)"
				>
					{{ getPrice(monthIndex, priceData.prices) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {months} from "../../Scheme"

export default {
	name: "Table",
	props: {
		prices: {
			type: Array,
			default: () => []
		},
        selectedData: {
            type: Object,
            default: () => ({})
        },
	},
	data() {
		return {
			months: months
		}
	},
	methods: {
        isSelected(monthIndex, priceData) {
            const {prices = [], sideId = '-1'} = priceData;
            const pricesSelected = this.selectedData[sideId] || [];
            const monthNumber = monthIndex + 1;
            const price = prices.find(item => item.month === monthNumber) || {};
            const {id = '-1'} = price;
            return pricesSelected.includes(String(id));
        },
		getPrice(monthIndex, prices) {
			const monthNumber = monthIndex + 1
			return prices.find(item => item.month === monthNumber)?.price
		},
		getStatus(monthIndex, prices) {
			const monthNumber = monthIndex + 1
			return prices.find(item => item.month === monthNumber)?.status
		},
		getClasses(monthIndex, prices, priceData = {}) {
			const isEmpty = this.getStatus(monthIndex, prices) === undefined
			const isFree = this.getStatus(monthIndex, prices) === "free"
			const isReserved = this.getStatus(monthIndex, prices) === "reserved"
            const isSelected = this.isSelected(monthIndex, priceData);
			// const isBooked = this.getStatus(monthIndex, prices) === "busy"
			const isBooked = this.getStatus(monthIndex, prices) === "booked"

			return {
				value_empty: isEmpty,
				value_free: isFree,
				value__booked: isBooked,
				value__reserved: isReserved,
				selected: isSelected,
			}
		},
        onCheckPrice(monthIndex = -1, priceData = {}) {
            const monthNumber = monthIndex + 1;
            const{prices = [], sideId = '-1'} = priceData;
            const price = prices.find(item => item.month === monthNumber);
            const {id = '-1'} = price;
            this.$emit('onCheckPrice', id, sideId);
        }
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#6EC87A !default;
$reserved-color:			#FFB300 !default;

.table {
	$size: 38px;
	$indent: 11px;
	$cellIndent: 5px;

	display: inline-grid;
	grid-template-columns: $size 1fr;
	grid-template-rows: $size auto;
	grid-gap: $indent;

	&__header {
		grid-column: 1/3;
		background: $primary-color;
		border-radius: 4px;
		padding-left: $size + $indent;
	}
	.months {
		display: grid;
		grid-template-columns: repeat(12, 1fr);

		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: $assistant-color;
		}
	}

	.sides {
		background: $primary-color;
		border-radius: 4px;

		&__item {
			width: $size;
			height: $size;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $assistant-color;

			&:not(:first-child) {
				margin-top: $cellIndent;
			}
		}
	}

	// &__values {}
	.values {
		&__row {
			display: flex;
			justify-content: space-between;

			&:not(:first-child) {
				margin-top: $cellIndent;
			}
		}

		.value {
            cursor: pointer;
			width: $size;
			height: $size;
			border: 1px solid;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $assistant-color;
			transition: .2s;

			&:hover {
				color: #000;
			}

			&:not(:last-child) {
				margin-right: $cellIndent;
			}

			&_empty {
				background: $primary-lighten-color;
				border-color: $primary-lighten-color;
			}
			&_free {
				font-weight: 500;
				border-color: $success-lighten-color;

				&.selected {
					background: $success-lighten-color;
					color: #000;
				}
			}
			&__booked {
				font-weight: 500;
				border-color: $danger-color;

				&.selected {
					background: $danger-color;
					color: #000;
				}
			}
			&__reserved {
				font-weight: 500;
				border-color: $reserved-color;

				&.selected {
					background: $reserved-color;
					color: #000;
				}
			}
		}
	}
}
</style>
