<template>
	<div class="all">
		<AppTable
			primaryKey="id"
			:columns="columns"
			:rows="tableData"
			class="all-table"
			:isProgressBar="isProgressBarLoading"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
		>
			<template
				v-for="(col,x) of modifieredCols"
				v-slot:[col]="scopeData"
			>
				<BaseCell
					:key="x"
					:content="scopeData.row[col]"
				/>
			</template>

            <template v-slot:entity_info="scopeData">
                <BaseButton
                    :view="'secondary'"
                    @click="getEntityInfo(scopeData)"
                >Инфо</BaseButton>
            </template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
				/>
			</template>

			<template v-slot:light="scopeData">
				<AppLight :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.row.image_link" />
			</template>

            <template v-slot:direction="scopeData">
                <MapDirection :item="scopeData.row" :isEditable="false" />
            </template>
			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>

            <template v-slot:materials="scopeData">
                <div style="display: block">
                    <div
                        v-for="(value,index) of scopeData.row.materials"
                        :key="index"
                    >
                        {{value}}
                    </div>
                </div>
            </template>
		</AppTable>
	</div>
</template>

<script>
import AppTable from "@/components/Table2/Table"
import AppPhoto from '@/components/CellComponents/Photo.vue'
import CountryFlag from '@/components/CellComponents/CountryFlag.vue'
import AppLight from '@/components/CellComponents/Light.vue'
import AppIdentifier from '@/components/CellComponents/Identifier.vue'
import AppLink from '@/components/CellComponents/Link.vue'
import BaseCell from '@/components/CellComponents/BaseCell.vue'
import MapImage from '@/components/CellComponents/MapImage.vue'
import MapDirection from '@/components/CellComponents/MapDirection.vue'
import BaseButton from "@/components/Base/BaseButton";

export default {
	name: "AllTab",
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BaseButton,
        MapDirection
	},
	props: {
		columns: {
			type: Array,
			required: true
		},
		tableData: {
			type: Array,
			required: true
		},
		isProgressBarLoading: {
			type: Boolean,
			default: false
		},
		modifieredCols: {
			type: Array,
			requried: true
		}
	},
	created() {
		this.$emit('load', {
            urlName: 'sideList'
			// url: 'https://catalog.oohdesk.ru/catalog/list'
		})
	},
	methods: {
		/**
		 * @param {Object} $event - название колонки по которой был произведен клик в шапке таблицы
		 */
		onSort($event) {
			this.$emit('sort', $event)
		},
		/**
		 * @param {Object} $event - объект содержащий значения для фильрации
		 */
		onFilter($event) {
			this.$emit('filter', $event)
		},
		onLazyload() {
			this.$emit('lazyload')
		},
        getEntityInfo(scopeData){
            this.$emit('entityInfo', scopeData.row.id);
        }
	},
}
</script>

<style lang="scss" scoped>
// .all {}
</style>
