<template>
	<div class="panel">
		<BaseButton
			class="panel__cancel-btn"
			view="cancel"
			@click="onReset"
		>Отмена</BaseButton>
		<BaseButton
			class="panel__save-btn"
			view="success"
			@click="onSave"
		>Сохранить</BaseButton>
	</div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"

export default {
	name: "ActionPanel",
	components: {
		BaseButton
	},
	methods: {
		onSave() {
			this.$emit('save')
		},
		onReset() {
			const response = confirm("Вы уверены?")

			if (response) {
				this.$emit('reset')
			}
		},
		// onResetSome() {
		// 	const response = confirm("Вы уверены?")

		// 	if (response) {
		// 		this.$emit('resetSome')
		// 	}
		// },
		// onSaveSome() {
		// 	this.$emit('saveSome')
		// },
	}
}
</script>

<style lang="scss" scoped>
.panel {
	display: inline-block;
	background: transparent;
	padding: 20px 0;
	border-top: 1px solid #E7E7EB;

	button {
		display: inline-block;
		padding: 16px 30px;
		margin-right: 4px;
	}

	&__save-btn {
		margin-left: 10px;
	}
}
</style>