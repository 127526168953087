<template>
	<div class="filter-item">
		<div class="filter-item__title">
			{{ title }}
		</div>

		<button
			class="sign"
			@click="onTransfer"
		>
			<BaseIcon name="plus" class="sign__icon" />
		</button>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseSvgIcon"

export default {
	name: "FilterItemInactive",
	components: {
		BaseIcon
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		id: [Number, String]
	},
	methods: {
		onTransfer() {
			this.$emit('transfer', this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-item {
	position: relative;
	width: 100%;
    background: #fff;
    padding: 18px 14px;
    border-radius: 10px;
    color: #b9bbc2;

	&__title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 500;
		max-width: 80%;
	}

	.sign {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 34px;
		height: 34px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		color: inherit;

		&__icon {
			width: 10px;
		}
	}
}
</style>