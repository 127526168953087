import Vue from 'vue'
import Vuex from 'vuex'

import filter from './modules/filter'
import handbook from './modules/handbook'
import folders from './modules/folders'
import kit from './modules/kit';
import reconciliation from './modules/reconciliation';
import pageBayuerdesk from './modules/pageBayuerdesk';
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		filter,
		folders,
		handbook,
        kit,
        reconciliation,
        pageBayuerdesk,
	},
    state: {
        currentComponent: ''
    },
    mutations: {
        setCurrentComponent(state, currentComponent = '') {
            state.currentComponent = currentComponent;
        }
    }
})
