<template>
    <div class="control-panel-block">
        <div
            class="control-panel-block__header"
            @click="onToggleShow"
        >
            <div class="control-panel-block__title">
                {{panelBlockTitle}}
            </div>
<!--            <div class="control-panel-block__arrow">-->
<!--                <img src="@/assets/images/icons/arrow.svg">-->
<!--            </div>-->
        </div>
        <div
            v-show="isShow"
            class="control-panel-block__items"
        >
            <control-panel-item
                v-for="(panelItem, index) in panelBlockItems"
                :key="index"
                :panelItem="panelItem"
                :isActive="getPanelItemActive(panelItem.id)"
                @onClickPanelItem="onClickPanelItem"
            />
        </div>
    </div>
</template>

<script>
import ControlPanelItem from "./ControlPanelItem";
export default {
    name: "ControlPanelBlock",
    components: {ControlPanelItem},
    /**
     * Входные данные
     * @property {Object} panelBlock - блок элементов панели
     * @property {Array} panelIdsActive - активные элементы панели
     * @property {boolean} isShow - условие отображения
     */
    props: {
        panelBlock: {
            type: Object,
            default: () => ({})
        },
        panelIdsActive: {
            type: Array,
            default: () => ([])
        },
        isShow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Заголовок блока
         * @returns {string}
         */
        panelBlockTitle() {
            const {title = ''} = this.panelBlock;
            return String(title);
        },
        /**
         * Элементы блока
         * @returns {Array}
         */
        panelBlockItems() {
            const {items = []} = this.panelBlock;
            return items;
        },
        /**
         * Ключ блока
         * @returns {string}
         */
        panelBlockKey() {
            const {key = ''} = this.panelBlock;
            return String(key);
        },
    },
    methods: {
        /**
         * Получить активность элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {Boolean}
         */
        getPanelItemActive(panelItemId = '') {
            return this.panelIdsActive.includes(String(panelItemId));
        },
        /**
         * Событие клика по элементу панели
         * @param {string} panelItemId - идентификатор элемента панели
         */
        onClickPanelItem(panelItemId = '') {
            this.$emit('onClickPanelItem', panelItemId);
        },
        /**
         * Переключить видимость блока
         */
        onToggleShow() {
            this.$emit('onToggleShowPanelBlock', this.panelBlockKey);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel-block {
        width: 92px;
        margin-bottom: 8px;
        &__header {
            display: flex;
            justify-content: space-between;
            background: #F5F5F5;
            padding: 5px;
            border-radius: 4px;
            color: #C8CAD1;
            cursor: pointer;
            margin-right: 2px;
        }
        &__arrow {
            transform: rotate(-90deg);
            width: 7px;
            margin-left: 10px;
            flex-shrink: 0;
        }
        &__title {
            font-size: 10px;
        }
        &__items {
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
