<template>
    <li class="second-panel-item">
        <div class="second-panel-item__wrapper">
            <base-checkbox-v2
                class="second-panel-item__checkbox base-checkbox--small"
                :class="{'base-checkbox--parent-select' : isPartActiveCheckbox}"
                :checked="isActiveCheckbox"
                @change="onChangeCheckbox"
            />
            <base-color-label class="second-panel-item__color" :color="itemColor"/>
            <div
                class="second-panel-item__title"
                @click.prevent="toggleShowPoiList"
            >{{ itemName }}</div>
            <span class="second-panel-item__status">{{ dataPoi.length }}</span>

            <base-dropdown
                class="second-panel-item__dropdown"
                :style-list="stylesDropdownGroups"
                @changeVisible="onClickDropdownMenu"
            >
                <template #preview>
                    <BaseButton
                        class="second-panel-item__menu-btn"
                        :class="{'second-panel-item__menu-btn--active' : isActiveDropdown}"
                        view="simple"
                        form="oval"
                    >
                        <span class="icon-navigation-more">...</span>
                    </BaseButton>
                </template>
                <template #content>
                    <div class="second-panel-item__dropdown-menu">
                        <BaseButton
                            v-for="(item, index) in groupMenu"
                            :key="index"
                            view="simple"
                            form="oval"
                            @click="addEmitButtons(item.emit)"
                        >{{ item.name }}</BaseButton>
                    </div>
                </template>
            </base-dropdown>
        </div>

        <ul class="second-panel-item__children-list" v-if="isShowPoiList">
            <second-panel-child-item
                v-for="item in dataPoi"
                :key="item.id"
                :checked-child-list="filteredChildList"
                :poi-id="item.id"
                :description="item.description"
                :can-edit="item.canEdit"
                :is-active-parent="isParentChecked"
                @onCheckedPoi="onCheckedPoi"
                @removePoi="removePoi"
            />
        </ul>
    </li>
</template>

<script>
import ShemeSecondPanel from "../../schemes/ShemeSecondPanel";
import SecondPanelChildItem from './SecondPanelChildItem';
import BaseCheckboxV2 from "@/components/Base/BaseCheckboxV2";
import BaseColorLabel from "@/components/Base/BaseColorLabel";
import BaseButton from "@/components/Base/BaseButton";
import BaseDropdown from "@/components/Base/BaseDropdown";

export default {
    name: "SecondPanelItem",
    components: {
        BaseCheckboxV2,
        BaseColorLabel,
        SecondPanelChildItem,
        BaseDropdown,
        BaseButton,
    },
    /**
     * Входные данные компонента
     * @property {String} groupId - key группы poi
     * @property {String} isParentChecked - Флаг активности группы
     * @property {String} itemName - Название группы poi
     * @property {String} itemColor - Цвет группы poi
     * @property {Boolean} canEdit - Флаг включения редактирования точки
     * @property {Array} dataPoi - Список всех загруженных с бека точек
     * @property {Array} checkedChildList - Список включенных точек
     */
    props: {
        groupId: {
            type: String,
            required: true,
        },
        isParentChecked: {
            type: Boolean,
            default: true,
        },
        itemName: {
            type: String,
            default: '',
        },
        itemColor: {
            type: String,
            default: '',
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        dataPoi: {
            type: Array,
            default: () => [],
        },
        checkedChildList: {
            type: Array,
            default: () => [],
        },
    },
    /**
     * Данные компонента
     * @property {Boolean} isActiveCheckbox - Статус чекбокса группы
     * @property {Boolean} isPartActiveCheckbox - Статус частичного выбора группы
     * @property {Boolean} isActiveDropdown - Статус отображения меню группы
     * @property {Boolean} isShowPoiList - Статус отображения точек POI по клику на заголовок
     */
    data: () => ({
        isActiveCheckbox: false,
        isPartActiveCheckbox: false,
        isActiveDropdown: false,
        isShowPoiList: false,
    }),

    watch: {
        /**
         * Отслеживание изменений в массиве включенных чекбоксов группы
         */
        isParentChecked() {
            this.isActiveCheckbox = this.isParentChecked;
        },
        /**
         * Отслеживание изменений в массиве включенных poi
         */
        checkedChildList() {
            this.isPartActiveCheckbox = this.partActive;
        },
    },
    computed: {
        /**
         * Найти список элементов меню для группы
         */
        groupMenu() {
            return ShemeSecondPanel.contents.groupMenuItems;
        },
        /**
         * Найти стили для меню группы
         */
        stylesDropdownGroups() {
            return ShemeSecondPanel.dropdownGroups.stylesDropdownGroups;
        },
        /**
         * Найти в списке включенных poi только точки текущей группы
         */
        filteredChildList() {
            return this.checkedChildList.filter((el) => el.key === this.groupId);
        },
        /**
         * Проверка состояния флага частичного выбора группы
         */
        partActive() {
            return this.dataPoi.length > 1
                && this.filteredChildList.length > 0
                && this.filteredChildList.length !== this.dataPoi.length;
        },
    },
    methods: {
        /**
         * Клик по чекбоксу группы
         * @param {Boolean} isChecked - включен/выключен чекбокс
         */
        onChangeCheckbox(isChecked) {
            this.$emit('onCheckedGroup', isChecked, this.groupId);
        },
        /**
         * Клик по чекбоксу точки poi
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} poiId - id poi
         */
        onCheckedPoi(isChecked, poiId) {
            this.$emit('onCheckedPoi', isChecked, poiId, this.groupId);
        },
        /**
         * Активировать выделение на кнопке меню
         * @param {Boolean} isShow - включен/выключен чекбокс
         */
        onClickDropdownMenu(isShow) {
            this.isActiveDropdown = isShow;
        },
        /**
         * Показать/скрыть точки группы
         */
        toggleShowPoiList() {
            if (this.dataPoi.length === 0) {
                this.$emit('onToggleShowPoiList', this.groupId);
            }
            this.isShowPoiList = !this.isShowPoiList;
        },
        /**
         * Вспомогательный метод для поиска названий функций обработчиков кнопки
         * @param {string} emit - название функции
         */
        addEmitButtons(emit) {
            return this[emit]();
        },
        /**
         * Клик удалить метку
         * @param {string} poiId - id метки poi
         */
        removePoi(poiId) {
            this.$emit('removePoi', poiId);
        },
        /**
         * Клик удалить группу
         */
        removeGroupPoi() {
            this.$emit('removeGroupPoi', this.groupId);
        },
    },
}
</script>


<style lang="scss" scoped>
    .second-panel-item {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &__parent-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__wrapper {
            display: flex;

            &:hover {
                .second-panel-item__status {
                    display: none;
                }

                .second-panel-item__menu-btn {
                    display: block;
                }
            }
        }

        &__color {
            flex-shrink: 0;
        }

        &__checkbox{
            margin-right: 5px;
        }

        &__title {
            flex-grow: 1;
            font-size: 12px;
            color: #000;
            font-weight: 400;
            margin-left: 10px;
            cursor: pointer;
            transition: color .3s ease-out;

            &:hover {
                color: #6EC87A;
            }
        }

        &__status {
            width: 16px;
            height: 16px;
            background: #F5F5F5;
            color: #C8CAD1;
            font-size: 10px;
            font-weight: 400;
            padding: 3px;
            margin-left: 5px;
        }

        &__dropdown-menu {
            position: relative;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 28px rgba(59, 74, 116, .15);
            overflow: hidden;
            z-index: 5;
            pointer-events: auto;
            padding: 10px;

            button {
                color: #222;
                padding: 5px 10px;
                font-weight: 500;
                transition: all .4s ease-out;
            }
        }

        &__menu-btn {
            display: none;
            padding: 0;
            margin-left: 5px;

            width: 16px;
            height: 16px;

            background: #eee;
            color: #333;

            &--active {
                display: block;
                background: #bdbdbd;
                color: black;

                &:hover {
                    background: #bdbdbd;
                }
            }

            &:hover {
                background: #F5F5F5;
            }
        }
    }
</style>
