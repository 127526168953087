<template>
    <div
        id="modal__backdrop"
        class="modal__backdrop"
        @click.self="close"
    >
        <div
            class="modal__container"
            :class="{'modal__container--animation' : isAnimation}"
        >
            <slot name="modalBody"></slot>
            <button class="modal__btn-close" @click="close">
                <span>Закрыть</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    /**
     * Входные данные компонента
     * @property {Boolean} isAnimation - Включить/выключить анимацию при открытии окна
     * @property {Boolean} isCloseButton - Показать/скрыть кнопку закрытия окна
     */
    props: {
        isAnimation: {
            type: Boolean,
            default: false,
        },
        isCloseButton: {
            type: Boolean,
            default: false,
        },
    },
    /**
     * данные компонента
     * @property {string} mousedownElementId - id элемент dom дерева над которым свершилось событие mousedown
     * @property {string} mouseupElementId - id элемент dom дерева над которым свершилось событие mouseup
     */
    data: () => ({
        // mousedownElementId: "",
        // mouseupElementId: ""
    }),
    methods: {
        /**
         * событие закрытия модельного окна
         */
        close() {
            // if (this.mousedownElementId === "modal__backdrop" && this.mouseupElementId === "modal__backdrop") {
                this.$emit("close");
            // }
            // this.mousedownElementId = "";
            // this.mouseupElementId = "";
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal__backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(34, 34, 34, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        z-index: 2999999999;
    };
    .modal__container {
        background-color: white;
        position: relative;
        max-width: 80%;
        max-height: 80%;
    };

    .modal__container--animation {
        animation-duration: .2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 2;
        animation-name: modalOpenAnimation;
    }

    .modal__btn-close {
        position: absolute;
        top: -25px;
        right: -25px;
        margin: 0;
        padding: 0;
        font-size: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        z-index: 2;

        span {
            position: relative;

            &:before {
                position: absolute;
                top: -7px;
                left: -1px;
                display: block;
                content: '';
                width: 2px;
                height: 14px;
                background: #333;
                transform: rotate(-45deg);
            }
            &:after {
                position: absolute;
                top: -7px;
                left: -1px;
                display: block;
                content: '';
                width: 2px;
                height: 14px;
                background: #333;
                transform: rotate(45deg);
            }
        }

    }

    @keyframes modalOpenAnimation {
        from {
            transform: translateX(-40px);
        }

        50% {
            transform: translateX(40px);
        }

        to {
            transform: translateY(0);
        }
    }
</style>
